import { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Icon } from '@/shared/ui';
import { scrollToElementByID } from '@/shared/lib';

import { scrollTop2DReportRightColunm } from '@/entities/reports';

import styles from './RenderViewSlider.module.scss';

type RenderViewSliderProps = {
  className?: string;
  itemIDs: string[];
  activeItemID: string;
  updateActiveItem: (newItemID: string) => void;
};

const MAIN_VIEW_INDEX = 0;

export const RenderViewSlider: FC<RenderViewSliderProps> = (props) => {
  const { className, itemIDs, activeItemID, updateActiveItem } = props;

  const pages = ['', ...itemIDs]; // first elem as default view

  const clickMainViewHandle = () => {
    updateActiveItem('');
    scrollTop2DReportRightColunm();
  };

  const changeActivePageHandle = (newPage: number) => {
    const newItemID = pages[newPage];

    updateActiveItem(newItemID);
    scrollToElementByID(newItemID);
  };

  const activePage = activeItemID
    ? pages.findIndex((itemID) => itemID === activeItemID)
    : MAIN_VIEW_INDEX;

  const pagesCount = pages?.length - 1;
  const isFirstPage = activePage === MAIN_VIEW_INDEX;
  const isLastPage = activePage === pagesCount;

  return (
    <div className={cn(styles.container, className)}>
      <button
        type="button"
        className={cn(styles.allImagesButton, 'p2')}
        onClick={clickMainViewHandle}
        disabled={isFirstPage}
      >
        <FormattedMessage
          id="renderViewSlider.allImagesButton"
          defaultMessage="All images"
        />
      </button>

      <button
        type="button"
        className={cn(styles.iconButton, styles.prevIcon)}
        onClick={() => changeActivePageHandle(activePage - 1)}
        disabled={isFirstPage}
      >
        <Icon name="arrowDown" size={20} />
      </button>

      <div className={cn(styles.counter, 'p2')}>
        <FormattedMessage
          id="global.imageCountOfAmount"
          defaultMessage="Image {activePage} of {count}"
          values={{
            activePage: activePage + 1,
            count: pagesCount + 1,
          }}
        />
      </div>

      <button
        type="button"
        className={cn(styles.iconButton, styles.nextIcon)}
        onClick={() => changeActivePageHandle(activePage + 1)}
        disabled={isLastPage}
      >
        <Icon name="arrowDown" size={20} />
      </button>
    </div>
  );
};
