import { groupBy } from 'lodash';

import { Asset } from '@/shared/api/protocol_gen/model/dto_asset';
import { MedicalImageInterface } from '@/shared/config';
import { Localization } from '@/shared/api/protocol_gen/model/dto_report_localization';
import { PolygonMask } from '@/shared/graphics/RenderComponents/RCPolygonMask/RCPolygonMask';
import {
  ClosedPolygon,
  FloatPoint2D,
} from '@/shared/api/protocol_gen/model/dto_common_geometry';
import { vec2 } from '@/shared/graphics/RenderComponents/vec2';

import {
  GroupConfigsType,
  PathologyGroup,
} from '../config/medicalImages.types';

import { getFileSrc } from './getFileSrc';

const maskPointsToVec2 = (points: FloatPoint2D): vec2 => ({
  x: points.X,
  y: points.Y,
});

const PATHOLOGIES = '#FF4C00'; // mandarin color

const localizationToMask = (localization: Localization): PolygonMask => ({
  polygon: (localization.Mask as ClosedPolygon)?.Points.map(maskPointsToVec2),
  color: PATHOLOGIES,
  stroke: PATHOLOGIES,
  opacity: 0.2,
});

export const makePathologyGroup = (
  groupConfigs: GroupConfigsType,
  medicalImagesList: Asset[],
  masks?: Localization[],
): PathologyGroup => {
  const { groupName, groupCode } = groupConfigs;

  const groupedByAssetIDMasks = groupBy(masks, 'TargetAssetID');

  const medicalImages = medicalImagesList.reduce((acc, item) => {
    if (
      item?.GeneratedReport?.CBCTToothPathologySlice?.Pathology === groupCode
    ) {
      acc.push({
        id: item?.GeneratedReport?.CBCTToothPathologySlice?.Slice?.ID || '',
        src: getFileSrc(
          item?.GeneratedReport?.CBCTToothPathologySlice?.Slice?.ID || '',
        ),
        path: item?.GeneratedReport?.CBCTToothPathologySlice?.Slice?.Path,
        assetID: item.ID,
        masks: groupedByAssetIDMasks[item.ID]?.map(localizationToMask),
        groupName,
      });
    }

    return acc;
  }, [] as MedicalImageInterface[]);

  return {
    type: groupName,
    medicalImages,
  };
};
