import { createSelector } from '@reduxjs/toolkit';
import { filter } from 'ramda';

import { ConditionCode } from '@/shared/api/protocol_gen/model/dto_report_condition_codes';
import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';
import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';
import { Decision } from '@/shared/api/protocol_gen/model/dto_report_common';

import { MASK_CONDITIONS_TO_SHOW } from '@/entities/maskFilters';

import { RootState } from '@/app/model/store';

import {
  filterConditionsByToothID,
  filterConditionsByPatientID,
  filterConditionsByReportID,
  filterConditionsByToothIDAndGroupCode,
  findConditionByToothIDAndCode,
  shouldConditionItemBeShown,
} from '../lib';
import { ConditionGroups } from '../config/const';

import { conditionAdapter } from './conditionSlice';

export const conditionSelectors = conditionAdapter.getSelectors(
  (state: RootState) => state.condition,
);

export const conditionLoading = (state: RootState) => state.condition.loading;

export const { selectAll, selectEntities, selectById } = conditionSelectors;

export const selectByPatientID = createSelector(
  conditionSelectors.selectAll,
  (_: RootState, patientID: string) => patientID,
  filterConditionsByPatientID,
);

export const selectByReportID = createSelector(
  conditionSelectors.selectAll,
  (_: RootState, reportID: string) => reportID,
  filterConditionsByReportID,
);

// Use this as a refference how to write selectors to avoid rerender
export const selectByToothID = (toothID: string) =>
  createSelector(conditionSelectors.selectAll, (conditions) =>
    filterConditionsByToothID(conditions, toothID),
  );

export const selectByToothIDAndCode = createSelector(
  conditionSelectors.selectAll,
  (_: RootState, toothID: string) => toothID,
  (_: RootState, __: string, conditionCode: ConditionCode) => conditionCode,
  findConditionByToothIDAndCode,
);

export const selectByToothIDAndGroupCode = createSelector(
  conditionSelectors.selectAll,
  (_: RootState, toothID: string) => toothID,
  (_: RootState, __: string, conditionGroupCode: ConditionGroups) =>
    conditionGroupCode,
  filterConditionsByToothIDAndGroupCode,
);

export const selectByROIIDs = (ROI: Tooth[]) =>
  createSelector(conditionSelectors.selectAll, (conditions) =>
    ROI.reduce(
      (acc, tooth) => [
        ...acc,
        ...filterConditionsByToothID(conditions, tooth.ID),
      ],
      [] as Condition[],
    ),
  );

export const selectMaxFaxConditionsByReportID = (reportID: string) =>
  createSelector(conditionSelectors.selectAll, (conditions) =>
    conditions.filter(
      (condition) => condition.ReportID === reportID && condition.MaxFax,
    ),
  );

export const selectByTeethIDs = (teethIDs: string[]) =>
  createSelector(conditionSelectors.selectAll, (conditions) =>
    conditions.filter((condition) =>
      teethIDs.includes(condition?.Tooth?.ToothID ?? ''),
    ),
  );

export const selectConditionsToShow = createSelector(selectAll, (conditions) =>
  filter(shouldConditionItemBeShown(true), conditions),
);

export const selectConditionsWithMasks = createSelector(
  selectAll,
  (conditions) => {
    const filteredConditions = conditions.filter(
      (condition) =>
        condition.Localizations.length &&
        MASK_CONDITIONS_TO_SHOW.includes(condition.Code) &&
        condition.Certainty?.EngineDecision === Decision.PositiveDecision,
    );

    return filteredConditions;
  },
);

export const selectConditionCodesWithMasks = createSelector(
  selectConditionsWithMasks,
  (conditions) => conditions.map((condition) => condition.Code),
);
