import { FC, RefObject, useEffect, useState } from 'react';
import cn from 'classnames';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { IOXRayRender } from '@/shared/graphics/viewer2D/Viewer2D';

import { FMXNavigationMode, reportsModel } from '@/entities/reports';
import {
  IOXRayImagesInterfaceModel,
  IOXrayImageInterface,
  getMatrixMaxHeight,
  getMatrixMaxWidth,
  groupIOXRayImagesByPartition,
} from '@/entities/IOXRayImagesMatrix';

import styles from './IOXRayMatrixView.module.scss';

type IOXRayMatrixViewProps = {
  className?: string;
  mainViewRef: RefObject<HTMLDivElement>;
  previewMode?: boolean;
};

const IOXRAY_MATRIX_PADDINGS = 48;

export const IOXRayMatrixView: FC<IOXRayMatrixViewProps> = (props) => {
  const { className, mainViewRef, previewMode } = props;

  const dispatch = useAppDispatch();

  const currentReport = useAppSelector(
    reportsModel.selectors.selectCurrentReport,
  );

  const reportID = currentReport?.ID;

  const focusedImageMetaID = useAppSelector(
    reportsModel.selectors.selectFocusedImageMetaID,
  );

  const activeControl = useAppSelector(
    reportsModel.selectors.selectToolbarActiveControl,
  );

  const navigationMode = useAppSelector(
    reportsModel.selectors.selectNavigationMode,
  );

  const IOXRayImagesInterface = useAppSelector(
    IOXRayImagesInterfaceModel.selectors.selectIOXRayImagesInterfaceByReportID(
      reportID || '',
    ),
  );

  const groupedIOXRayImages = groupIOXRayImagesByPartition(
    IOXRayImagesInterface as IOXrayImageInterface[],
  );

  const singleImage = IOXRayImagesInterface.length === 1;

  const isImagesClickable =
    !previewMode &&
    activeControl === 'view' &&
    navigationMode === FMXNavigationMode.MatrixView &&
    !focusedImageMetaID &&
    !singleImage;

  const onImageClickHandle = (assetID: string) => {
    if (isImagesClickable) {
      dispatch(reportsModel.actions.setFocusedMetaImageID(assetID));
    }
  };

  const [{ contWidth, contHeight }, setContSize] = useState({
    contWidth: 50,
    contHeight: 30,
  });

  const calculateScale = () => {
    const wrapperWidth = contWidth;
    const wrapperHeight = contHeight;

    if (!wrapperWidth && !wrapperHeight) return 0;

    const IOXRaysOriginalWidth = getMatrixMaxWidth(
      groupedIOXRayImages,
      'original',
    );
    const IOXRaysOriginalHeight = getMatrixMaxHeight(
      groupedIOXRayImages,
      'original',
    );

    const scaleByWidth = wrapperWidth / IOXRaysOriginalWidth;
    const scaleByHeight = wrapperHeight / IOXRaysOriginalHeight;

    const IOXRaysOriginalAspect = IOXRaysOriginalHeight / IOXRaysOriginalWidth;

    const wrapperAspect = wrapperHeight / wrapperWidth;

    if (IOXRaysOriginalAspect >= wrapperAspect) {
      return scaleByHeight;
    }

    return scaleByWidth;
  };

  const scale = calculateScale();

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { width, height } = entry.contentRect;

        setContSize({ contWidth: width, contHeight: height });
      }
    });

    if (mainViewRef.current) {
      resizeObserver.observe(mainViewRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [mainViewRef]);

  return (
    <div
      className={cn(
        styles.matrixContainer,
        focusedImageMetaID && !previewMode ? styles.singleView : '',
        className,
      )}
    >
      {focusedImageMetaID && !!mainViewRef.current && !previewMode ? (
        <div
          key={focusedImageMetaID}
          className={cn(styles.MiddleMiddle)}
          ref={IOXRayRender.getViewportRef(focusedImageMetaID, 'main')}
          style={{
            width:
              mainViewRef.current!.clientWidth - IOXRAY_MATRIX_PADDINGS * 2,
            height:
              mainViewRef.current!.clientHeight - IOXRAY_MATRIX_PADDINGS * 2,
          }}
        />
      ) : (
        Object.entries(groupedIOXRayImages).map(
          ([group, IOXrayImagesInterfaceList]: [
            string,
            IOXrayImageInterface[],
          ]) => (
            <div className={styles[group]} key={group}>
              {IOXrayImagesInterfaceList?.map((IOXRayImageInterface) => {
                const imageMetaID = IOXRayImageInterface.imageMeta.ID;

                const { width, height } =
                  IOXRayImageInterface.imageMeta.OrientationAngle === 90 ||
                  IOXRayImageInterface.imageMeta.OrientationAngle === 270
                    ? {
                        width: IOXRayImageInterface.originalSize.height * scale,
                        height: IOXRayImageInterface.originalSize.width * scale,
                      }
                    : {
                        width: IOXRayImageInterface.originalSize.width * scale,
                        height:
                          IOXRayImageInterface.originalSize.height * scale,
                      };

                return (
                  <div
                    onClick={() => {
                      onImageClickHandle(imageMetaID);
                    }}
                    key={imageMetaID}
                    ref={IOXRayRender.getViewportRef(imageMetaID, 'main')}
                    className={cn(
                      styles.IOXRayImage,
                      isImagesClickable ? styles.imageClickable : '',
                    )}
                    style={{
                      width,
                      height,
                    }}
                  />
                );
              })}
            </div>
          ),
        )
      )}
    </div>
  );
};
