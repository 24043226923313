import React, { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import { Portal } from '@/shared/ui/Portal/Portal';
import { useAppSelector, useMouseCoords } from '@/shared/hooks';
import {
  IOXRayRender,
  PanoBitewingsRender,
  PanoRender,
} from '@/shared/graphics/viewer2D/Viewer2D';
import { ReportType } from '@/shared/api/protocol_gen/model/dto_report';
import { ConditionCode } from '@/shared/api/protocol_gen/model/dto_report_condition_codes';

import { conditionText } from '@/entities/condition/config/i18n';
import {
  CONDITIONS_BY_MASK_GROUP,
  MaskFiltersType,
} from '@/entities/maskFilters';
import { reportsModel } from '@/entities/reports';

import { maskFiltersModel } from '@/features/maskFilters';

import {
  MASK_TOOLTIP_X_SHIFT,
  MASK_TOOLTIP_Y_SHIFT,
} from '../../config/constants';

import styles from './ConditionMaskTooltip.module.scss';

type ConditionMaskTooltipProps = {
  className?: string;
};

const getReportRender = (reportType?: ReportType) => {
  switch (reportType) {
    case ReportType.ReportType_IOXRay_GP:
      return IOXRayRender;
    case ReportType.ReportType_Pano_GP:
      return PanoRender;
    case ReportType.ReportType_Pano_Bitewings:
      return PanoBitewingsRender;
    default:
      return IOXRayRender;
  }
};

export const ConditionMaskTooltip: FC<ConditionMaskTooltipProps> = (props) => {
  const { className } = props;

  const { x, y } = useMouseCoords();

  const [hoveredMaskID, setHoveredMaskID] = useState<string | undefined>(
    undefined,
  );

  const report = useAppSelector(reportsModel.selectors.selectCurrentReport);

  const masks2DRenderData = useAppSelector(
    maskFiltersModel.selectors.select2DMasksRenderData,
  );

  const { formatMessage } = useIntl();

  const Render = getReportRender(report?.Type);

  const isRenderRunning = Render.isRunning();

  useEffect(() => {
    const updateHoveredMaskID = ({
      maskId,
    }: {
      maskId?: string | undefined;
    }) => {
      setHoveredMaskID(maskId);
    };

    if (isRenderRunning) {
      // @ts-expect-error  WARN: Signature of listeners incompatible, but for the 'hoverMask' event everything works fine
      Render.addEventListener('hoverMask', updateHoveredMaskID);
    }

    return () => {
      // @ts-expect-error  WARN: Signature of listeners incompatible, but for the 'hoverMask' event everything works fine
      Render.removeEventListener('hoverMask', updateHoveredMaskID);
    };
  }, [isRenderRunning, Render]);

  if (!hoveredMaskID || !masks2DRenderData) {
    return null;
  }

  const maskConditionCode = masks2DRenderData
    .flatMap((maskRenderData) => maskRenderData.config)
    .find((maskConfig) => maskConfig.maskID === hoveredMaskID)?.conditionCode;

  const conditionMaskGroup = Object.entries(CONDITIONS_BY_MASK_GROUP).reduce(
    (acc, [group, codes]) => {
      if (codes.includes(maskConditionCode as ConditionCode)) {
        return group as MaskFiltersType;
      }

      return acc;
    },
    'restorative' as MaskFiltersType,
  );

  const buttonColors = {
    perio: styles.maskFilterPerio,
    restorative: styles.maskFilterRestorative,
    endo: styles.maskFilterEndo,
    anatomy: styles.maskFilterAnatomy,
  };

  const text =
    maskConditionCode && conditionText[maskConditionCode]
      ? formatMessage(conditionText[maskConditionCode])
      : '';

  const stylePosition: React.CSSProperties = {
    top: y + MASK_TOOLTIP_Y_SHIFT,
    left: x + MASK_TOOLTIP_X_SHIFT,
  };

  return (
    <Portal>
      <div className={styles.wrapper}>
        <div
          style={stylePosition}
          className={cn(
            styles.container,
            className,
            buttonColors[conditionMaskGroup],
          )}
        >
          {text}
        </div>
      </div>
    </Portal>
  );
};
