import { FC, ReactNode, createRef, useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { CORS_POLICY } from '@/shared/config';
import { useAppSelector } from '@/shared/hooks';
import {
  IOXRayRender,
  ImageProps,
  PBLProps,
} from '@/shared/graphics/viewer2D/Viewer2D';
import { FeatureFlag } from '@/shared/api/protocol_gen/model/dto_organization';

import { getImageSrc } from '@/entities/assets';
import { FMXNavigationMode, reportsModel } from '@/entities/reports';
import { toothModel } from '@/entities/tooth';
import { IOXRayImagesInterfaceModel } from '@/entities/IOXRayImagesMatrix';
import { maskFiltersModel as maskFilterTypesModel } from '@/entities/maskFilters'; // TODO:[4|m] Unite two mask filtel models
import { organizationModel } from '@/entities/organization';

import { maskFiltersModel } from '@/features/maskFilters';
import { Landmark, useTeethLandmarks } from '@/features/toothLandmark';
import { CroppedImagesView } from '@/features/croppedImagesView';
import { hoveredConditionBBoxesModel } from '@/features/hoveredConditionBBoxes';

import { IOXRayMatrixView } from '../IOXRayMatrixView/IOXRayMatrixView';

import styles from './IOXRayReportRender.module.scss';

type IOXRayReportRenderProps = {
  className?: string;
  children?: ReactNode;
  previewMode?: boolean;
};

export const IOXRayReportRender: FC<IOXRayReportRenderProps> = (props) => {
  const { className, children, previewMode } = props;

  const [viewportRefsIsReady, setViewportRefsIsReady] = useState(false);

  const reportID = useAppSelector(reportsModel.selectors.selectCurrentReportID);
  const reportViewOptions = useAppSelector(
    reportsModel.selectors.selectCurrentReportViewOptions,
  );
  const navigationMode = useAppSelector(
    reportsModel.selectors.selectNavigationMode,
  );
  const showPBL = useAppSelector(
    maskFilterTypesModel.selectors.selectIsLandmarksShown,
  );
  const hidePBLFeatureFlag = useAppSelector(
    organizationModel.selectors.selectIsFeatureActiveByName(
      FeatureFlag.FeatureFlag_Hide_PBLRulesAndMeasurements,
    ),
  );
  const reportReadyForRender = useAppSelector(
    reportsModel.selectors.selectReportReadyForRender,
  );
  const reportPBLReadyForRender = useAppSelector(
    reportsModel.selectors.selectReportPBLReadyForRender,
  );

  const mainViewRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const IOXRayImagesInterface = useAppSelector(
    IOXRayImagesInterfaceModel.selectors.selectIOXRayImagesInterfaceByReportID(
      reportID || '',
    ),
  );

  const teethIDs = useAppSelector(
    toothModel.selectors.selectReportROITeethIDs(reportID as string),
  );

  const { getPBLsForImage } = useTeethLandmarks(teethIDs);

  // Hovered condition BBoxes
  const hoveredBBoxes = useAppSelector(
    hoveredConditionBBoxesModel.selectors.selectToothChartItems,
  );

  const activeToothID = useAppSelector(
    reportsModel.selectors.selectActiveToothID,
  );

  const masks2DRenderData = useAppSelector(
    maskFiltersModel.selectors.select2DMasksRenderData,
  );

  const hideMasks = useAppSelector(
    organizationModel.selectors.selectIsFeatureActiveByName(
      FeatureFlag.FeatureFlag_Hide_ConditionsMasks,
    ),
  );

  const showMatrix =
    navigationMode === FMXNavigationMode.MatrixView || !activeToothID;
  const showCroppedImages =
    navigationMode === FMXNavigationMode.ToothChart && activeToothID;

  // Set refs for ioxray images
  // NOTE: This should be happen before IOXRayRender.run(), set masks, PBL, etc
  useEffect(() => {
    let allRefsExist = false;

    IOXRayImagesInterface.forEach((imageItem) => {
      const isExistingRef = IOXRayRender.getViewportRef(
        imageItem.imageMeta.ID,
        'main',
      );

      allRefsExist = !!isExistingRef;

      if (!isExistingRef) {
        IOXRayRender.setViewportRef(
          createRef(),
          imageItem.imageMeta.ID,
          'main',
        );
      }
    });

    if (!viewportRefsIsReady && allRefsExist) {
      setViewportRefsIsReady(true);
    }
  }, [IOXRayImagesInterface]);

  useEffect(() => {
    IOXRayRender.setViewRef(mainViewRef, 'main');
    IOXRayRender.setCanvasRef(canvasRef);
  }, []);

  // render initialization
  useEffect(() => {
    if (
      reportReadyForRender &&
      viewportRefsIsReady &&
      !!IOXRayImagesInterface.length
    ) {
      const isRenderStartCorrect = IOXRayRender.run(reportID as string);

      if (!isRenderStartCorrect) {
        return;
      }

      performance.mark('InitializeIOXRayRender:start');

      IOXRayRender.setCredentials(CORS_POLICY);

      const imagesData = IOXRayImagesInterface.map(
        ({ asset, imageMeta, originalSize }) => {
          return {
            id: imageMeta.ID,
            url: getImageSrc(asset?.ID, 'original'),
            previewURL: getImageSrc(asset?.ID, 'thumbnail'),
            kind: 'raster',
            width: originalSize.width,
            height: originalSize.height,
            scale: imageMeta?.Scale?.X,
            sharpness: imageMeta.MedicalImageFeatures?.ViewOptions?.Sharpness,
            brightness: imageMeta.MedicalImageFeatures?.ViewOptions?.Brightness,
            contrast: imageMeta.MedicalImageFeatures?.ViewOptions?.Contrast,
            angle: imageMeta.OrientationAngle * (Math.PI / 180),
          } as ImageProps;
        },
      );

      IOXRayRender.setImages({
        props: imagesData,
        screenProps: {
          sharpness: reportViewOptions?.Sharpness || 0,
          brightness: reportViewOptions?.Brightness || 0,
          contrast: reportViewOptions?.Contrast || 0,
        },
      });

      performance.mark('InitializeIOXRayRender:end');
      performance.measure(
        'InitializeIOXRayRender',
        'InitializeIOXRayRender:start',
        'InitializeIOXRayRender:end',
      );
    }
  }, [
    reportReadyForRender,
    IOXRayImagesInterface,
    hidePBLFeatureFlag,
    viewportRefsIsReady,
  ]);

  // NOTE: PBL should be set up just one time after render is done.
  useEffect(() => {
    if (
      reportPBLReadyForRender &&
      viewportRefsIsReady &&
      IOXRayRender.isRunning()
    ) {
      performance.mark('setPBLs:start');
      if (!previewMode) {
        const PBLsData = IOXRayImagesInterface.map((data) => {
          const initialPBLList = getPBLsForImage(data.asset.ID);

          if (initialPBLList.length === 0) {
            return undefined;
          }

          const getPBL = (landmark: Landmark) => ({
            start: {
              x: landmark.lowPoint?.ModelPosition?.X || 0,
              y: landmark.lowPoint?.ModelPosition?.Y || 0,
            },
            end: {
              x: landmark.upPoint?.ModelPosition?.X || 0,
              y: landmark.upPoint?.ModelPosition?.Y || 0,
            },
            color: landmark.color,
            textProps: {
              color: landmark.color === '#D4D4D4' ? 0 : 0xffffff,
            },
          });

          return {
            imageID: data.imageMeta.ID,
            PBLs: initialPBLList.map((pbl) => {
              return getPBL(pbl);
            }),
            scale: data.imageMeta.Scale?.X || 1,
          };
        }).filter((data) => data) as PBLProps[];

        if (PBLsData) {
          IOXRayRender.setPBLs(PBLsData);
          performance.mark('setPBLs:end');
          performance.measure('setPBLs', 'setPBLs:start', 'setPBLs:end');
        }
      }
    }
  }, [reportPBLReadyForRender, viewportRefsIsReady]);

  // Toggle PBL
  // TODO: Is it possible to move it on perio toggle function to avoid useEffect?
  useEffect(() => {
    if (reportReadyForRender && IOXRayRender.isRunning()) {
      if (!hideMasks && !hidePBLFeatureFlag && showPBL) {
        IOXRayRender.showPBLs();
      } else {
        IOXRayRender.hidePBLs();
      }
    }
  }, [reportReadyForRender, showPBL, hideMasks, hidePBLFeatureFlag]);

  // Render masks
  useEffect(() => {
    if (
      IOXRayRender.isRunning() &&
      !previewMode &&
      reportReadyForRender &&
      viewportRefsIsReady
    ) {
      IOXRayRender.deleteMasks();
      if (!hideMasks && masks2DRenderData.length > 0) {
        IOXRayRender.addMasks(masks2DRenderData);
      }
    }
  }, [
    masks2DRenderData,
    previewMode,
    reportReadyForRender,
    hideMasks,
    viewportRefsIsReady,
  ]);

  // BBoxes render
  useEffect(() => {
    if (IOXRayRender.isRunning()) {
      if (hoveredBBoxes) {
        IOXRayRender.deleteConditionBoxes();

        IOXRayRender.addConditionBoxes(hoveredBBoxes);
      } else {
        IOXRayRender.deleteConditionBoxes();
      }
    }
  }, [hoveredBBoxes]);

  // Stop render on unmount
  // WARN: It's necessary to check if the render is running and only then suspend it
  useEffect(
    () => () => {
      if (IOXRayRender.isRunning()) {
        IOXRayRender.suspend();
      }
    },
    [],
  );

  return (
    <div className={cn(styles.container, className)} ref={mainViewRef}>
      <canvas ref={canvasRef} className={styles.canvas} />
      {children}
      {showMatrix && (
        <IOXRayMatrixView previewMode={previewMode} mainViewRef={mainViewRef} />
      )}
      {showCroppedImages && <CroppedImagesView mainViewRef={mainViewRef} />}
    </div>
  );
};

export const IOXRayReportRenderPreview = (
  props: Pick<IOXRayReportRenderProps, 'className'>,
) => {
  const { className } = props;
  const [viewportRefsIsReady, setViewportRefsIsReady] = useState(false);

  const reportID = useAppSelector(reportsModel.selectors.selectCurrentReportID);
  const reportReadyForRender = useAppSelector(
    reportsModel.selectors.selectReportReadyForRender,
  );
  const reportViewOptions = useAppSelector(
    reportsModel.selectors.selectCurrentReportViewOptions,
  );
  const IOXRayImagesInterface = useAppSelector(
    IOXRayImagesInterfaceModel.selectors.selectIOXRayImagesInterfaceByReportID(
      reportID || '',
    ),
  );

  const mainViewRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  // Set refs for ioxray images
  // NOTE: This should be happen before IOXRayRender.run(), set masks, PBL, etc
  useEffect(() => {
    IOXRayImagesInterface.forEach((imageItem) => {
      const isExistingRef = IOXRayRender.getViewportRef(
        imageItem.imageMeta.ID,
        'main',
      );

      if (!isExistingRef) {
        IOXRayRender.setViewportRef(
          createRef(),
          imageItem.imageMeta.ID,
          'main',
        );
      }
    });

    if (!viewportRefsIsReady) {
      setViewportRefsIsReady(true);
    }
  }, [IOXRayImagesInterface]);

  useEffect(() => {
    // IOXRayRender.addView('main'); Now main view exist by default
    IOXRayRender.setViewRef(mainViewRef, 'main');
    IOXRayRender.setCanvasRef(canvasRef);
  }, []);

  // render initialization
  useEffect(() => {
    if (
      reportReadyForRender &&
      viewportRefsIsReady &&
      !!IOXRayImagesInterface.length
    ) {
      const isRenderStartCorrect = IOXRayRender.run(reportID as string, true);

      if (!isRenderStartCorrect) {
        return;
      }

      performance.mark('InitializeIOXRayRender:start');

      IOXRayRender.setCredentials(CORS_POLICY);

      const imagesData = IOXRayImagesInterface.map(
        ({ asset, imageMeta, originalSize }) => {
          return {
            id: imageMeta.ID,
            url: getImageSrc(asset?.ID, 'original'),
            previewURL: getImageSrc(asset?.ID, 'thumbnail'),
            kind: 'raster',
            width: originalSize.width,
            height: originalSize.height,
            scale: imageMeta?.Scale?.X,
            sharpness: imageMeta.MedicalImageFeatures?.ViewOptions?.Sharpness,
            brightness: imageMeta.MedicalImageFeatures?.ViewOptions?.Brightness,
            contrast: imageMeta.MedicalImageFeatures?.ViewOptions?.Contrast,
            angle: imageMeta.OrientationAngle * (Math.PI / 180),
          } as ImageProps;
        },
      );

      IOXRayRender.setImages({
        props: imagesData,
        screenProps: {
          sharpness: reportViewOptions?.Sharpness || 0,
          brightness: reportViewOptions?.Brightness || 0,
          contrast: reportViewOptions?.Contrast || 0,
        },
      });

      performance.mark('InitializeIOXRayRender:end');
      performance.measure(
        'InitializeIOXRayRender',
        'InitializeIOXRayRender:start',
        'InitializeIOXRayRender:end',
      );
    }
  }, [reportReadyForRender, IOXRayImagesInterface, viewportRefsIsReady]);
  // Stop render on unmount
  // WARN: It's necessary to check if the render is running and only then suspend it
  useEffect(
    () => () => {
      if (IOXRayRender.isRunning()) {
        IOXRayRender.suspend();
      }
    },
    [],
  );

  return (
    <div className={cn(styles.container, className)} ref={mainViewRef}>
      <canvas ref={canvasRef} className={styles.canvas} />
      <IOXRayMatrixView previewMode mainViewRef={mainViewRef} />
    </div>
  );
};
