import { Color } from 'three';

import { ConditionCode } from '@/shared/api/protocol_gen/model/dto_report_condition_codes';

import { MaskFiltersType } from './maskFilters.type';

export const MASK_FILTERS_PERIO_CODES = [
  ConditionCode.PeriodontalBoneLoss,
  ConditionCode.Child_PeriodontalBoneLoss_Severity_Mild,
  ConditionCode.Child_PeriodontalBoneLoss_Severity_Moderate,
  ConditionCode.Child_PeriodontalBoneLoss_Severity_Severe,
  ConditionCode.Child_PeriodontalBoneLoss_Type_Horizontal,
  ConditionCode.Child_PeriodontalBoneLoss_Type_Vertical,
  ConditionCode.Child_PeriodontalBoneLoss_Type_Mixed,
  ConditionCode.FurcationLesion,
  ConditionCode.DentalCalculus,
];

export const MASK_FILTERS_RESTORATIVE_CODES = [
  ConditionCode.VoidsInTheFilling,
  ConditionCode.Overhang,
  ConditionCode.OpenMargin,
  ConditionCode.LackOfInterproximalContact,
  ConditionCode.ToothStump,
  ConditionCode.CariesSigns,
  ConditionCode.SecondaryCaries,
  ConditionCode.Child_DefectDepth_Enamel,
  ConditionCode.Child_DefectDepth_Dentin,
  ConditionCode.Child_DefectDepth_WithPulpExposure,
  ConditionCode.Child_DefectDepth_Initial,
  ConditionCode.Child_DefectDepth_NotDefined,
];

export const MASK_FILTERS_ENDO_CODES = [
  ConditionCode.Pulpotomy,
  ConditionCode.EndoTreated,
  ConditionCode.Child_EndoTreated_Obturation_ShortFilling,
  ConditionCode.Child_EndoTreated_Obturation_Overfilling,
  ConditionCode.Child_EndoTreated_Obturation_MissedCanal,
  ConditionCode.Child_EndoTreated_Quality_VoidsPresentInTheRootFilling,
  ConditionCode.PeriapicalRadiolucency,
];

// TODO: need to approve new anatomy codes add new filter
// TODO: temporary config for conditions with masks should be removed when anatomy will be ready
export const MASK_CONDITIONS_TO_SHOW = MASK_FILTERS_PERIO_CODES.concat(
  MASK_FILTERS_RESTORATIVE_CODES,
  MASK_FILTERS_ENDO_CODES,
);

export const CONDITIONS_BY_MASK_GROUP: Record<
  MaskFiltersType,
  ConditionCode[]
> = {
  perio: MASK_FILTERS_PERIO_CODES,
  restorative: MASK_FILTERS_RESTORATIVE_CODES,
  endo: MASK_FILTERS_ENDO_CODES,
};

export const MASK_COLORS: Record<
  MaskFiltersType,
  { color: number; opacity: number }
> = {
  perio: {
    color: new Color(1, 0.145, 0.306).getHex(),
    opacity: 0.3,
  },
  restorative: {
    color: new Color(1, 0, 0.839).getHex(),
    opacity: 0.3,
  },
  endo: {
    color: new Color(1, 0.298, 0).getHex(),
    opacity: 0.3,
  },
};
