import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { PATHS } from '@/shared/config';

import { ReportLayout } from '@/features/reportStream';

import { ErrorPage } from '@/pages/ErrorPage/ErrorPage';
import { Patients } from '@/pages/Patients/Patients';
import { GpReport, GPReportLayout } from '@/pages/GpReport';
import { EndodonticReport } from '@/pages/EndodonticReport/EndodonticReport';
import { PreviewReport } from '@/pages/PreviewReport/PreviewReport';
import { STLViewer } from '@/pages/STLViewer/STLViewer';
import { ImplantStudio } from '@/pages/ImplantStudio/ImplantStudio';
import { ResetPassword } from '@/pages/ResetPassword/ResetPassword';
import { NewPassword } from '@/pages/NewPassword/NewPassword';
import { Disclaimer } from '@/pages/Disclaimer/Disclaimer';
import { SignUp } from '@/pages/SignUp/SignUp';
import { ConfirmEmail } from '@/pages/ConfirmEmail/ConfirmEmail';
import { ConfirmSharing } from '@/pages/ConfirmSharing/ConfirmSharing';
import { ImplantReport } from '@/pages/ImplantReport/ImplantReport';
import { MolarReport } from '@/pages/MolarReport/MolarReport';
import { SegmentationReport } from '@/pages/SegmentationReport/SegmentationReport';
import { SignIn } from '@/pages/SignIn/SignIn';
import { CompanySettings } from '@/pages/CompanySettings/CompanySettings';
import { AdditionalInformation } from '@/pages/AdditionalInformation/AdditionalInformation';
import { PersonalSettings } from '@/pages/PersonalSettings/PersonalSettings';
import { FinishRegistration } from '@/pages/FinishRegistration';
import { InviteSignUp } from '@/pages/InviteSignUp/InviteSignUp';
import { OrganizationInvite } from '@/pages/OrganizationInvite/OrganizationInvite';
import { TestReportStreamPerformance } from '@/pages/TestReportStreamPerformance/TestReportStreamPerformance';
import { Tooth } from '@/pages/Tooth/Tooth';
import { PanoReport } from '@/pages/PanoReport';
import { IOXRayReport } from '@/pages/IOXRayReport';
import { PanowingsReport } from '@/pages/PanowingsReport';
import { PatientLayout, PatientProfile } from '@/pages/PatientProfile';

import { ProtectedRoutes } from '../providers/ProtectedRoutes';
import { Report2DLayout } from '../providers/Report2DLayout';
import { HubspotRoutes } from '../providers/HubspotRoutes';

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<HubspotRoutes />}>
        <Route path={PATHS.signUp} element={<SignUp />} />
        <Route path={PATHS.signIn} element={<SignIn />} />
        <Route path={PATHS.confirmEmail} element={<ConfirmEmail />} />
        <Route path={PATHS.resetPassword} element={<ResetPassword />} />
        <Route path={PATHS.newPassword} element={<NewPassword />} />
        <Route
          path={PATHS.finishRegistration}
          element={<FinishRegistration />}
        />
        <Route path={PATHS.invite} element={<InviteSignUp />} />

        <Route element={<ProtectedRoutes />}>
          <Route path={PATHS.main} element={<Patients />} />
          <Route path={PATHS.patients} element={<Patients />} />
          <Route
            path={PATHS.patientProfile_DEPRECATED}
            element={<PatientProfile />}
          />
          <Route path={PATHS.personalSettings} element={<PersonalSettings />} />
          <Route path={PATHS.companySettings} element={<CompanySettings />} />

          <Route path={PATHS.patient} element={<PatientLayout />}>
            <Route index element={<PatientProfile />} />
            <Route
              path={PATHS.report}
              element={<ReportLayout key="main-report-layout" />}
            >
              <Route path={PATHS.gp} element={<GPReportLayout />}>
                <Route index element={<GpReport />} />
                <Route path={PATHS.gpReportAllSlices} element={<Tooth />} />
              </Route>
              <Route element={<Report2DLayout />}>
                <Route path={PATHS.ioxRay} element={<IOXRayReport />} />
                <Route path={PATHS.pano} element={<PanoReport />} />
                <Route path={PATHS.bitewings} element={<PanowingsReport />} />
              </Route>
              <Route path={PATHS.preview} element={<PreviewReport />} />
            </Route>
            <Route
              path={PATHS.preview2D}
              element={<ReportLayout key="preview2d" />}
            >
              <Route index element={<PreviewReport />} />
            </Route>
          </Route>

          <Route path={PATHS.endodonticReport} element={<EndodonticReport />} />
          <Route path={PATHS.implantReport} element={<ImplantReport />} />
          <Route path={PATHS.molarReport} element={<MolarReport />} />
          <Route
            path={PATHS.segmentationReport}
            element={<SegmentationReport />}
          />
          <Route path={PATHS.STLViewer} element={<STLViewer />} />
          <Route path={PATHS.implantStudio} element={<ImplantStudio />} />
          <Route path={PATHS.disclaimer} element={<Disclaimer />} />
          <Route path={PATHS.confirmSharing} element={<ConfirmSharing />} />
          <Route
            path={PATHS.additionalInfo}
            element={<AdditionalInformation />}
          />
          <Route
            path={PATHS.organization_invite}
            element={<OrganizationInvite />}
          />

          <Route path={PATHS.previewReport} element={<PreviewReport />} />
          <Route
            path="test-report-stream/:reportID"
            element={<TestReportStreamPerformance />}
          />
        </Route>

        <Route path={PATHS.error} element={<ErrorPage />} />
      </Route>
    </>,
  ),
);
