import { useParams } from 'react-router';
import { useIntl } from 'react-intl';

import { ReportType } from '@/shared/api/protocol_gen/model/dto_report';

import { ReportLayout, i18n } from '@/entities/reports';

import { MaskFilters } from '@/features/maskFilters';
import { ConditionMaskTooltip } from '@/features/conditionMaskTooltip';

import { Conclusion } from '@/widgets/Conclusion';
import { PanowingsReportRender } from '@/widgets/PanowingsReportRender';
import { ReportActions } from '@/widgets/ReportActions';
import { Report2DToothChart } from '@/widgets/Report2DToothChart';

import { PanowingsToothCardList } from '../PanowingsToothCardList/PanowingsToothCardList';
import { Toolbar } from '../Toolbar/Toolbar';
import { PanowingImageSlider } from '../PanowingImageSlider/PanowingImageSlider';
import { PanowingChangeTeethNumberModal } from '../PanowingChangeTeethNumberModal/PanowingChangeTeethNumberModal';

import styles from './PanowignsReport.module.scss';

export const PanowingsReport = () => {
  const { patientID, reportID } = useParams();
  const { formatMessage } = useIntl();

  return (
    <>
      <ReportLayout.LeftColumn>
        <h1>{formatMessage(i18n[ReportType.ReportType_Pano_Bitewings])}</h1>
        <PanowingsReportRender>
          <MaskFilters className={styles.maskFilters} />
          <ConditionMaskTooltip />
          <Toolbar
            className={styles.toolbar}
            handleScrollToothCardsToTop={() => {}}
          />
          <PanowingImageSlider />
        </PanowingsReportRender>
      </ReportLayout.LeftColumn>
      <ReportLayout.RightColumn>
        <Report2DToothChart />
        <PanowingsToothCardList
          reportID={reportID as string}
          patientID={patientID as string}
        />

        <Conclusion />

        <ReportActions
          patientID={patientID as string}
          reportID={reportID as string}
        />
      </ReportLayout.RightColumn>
      {/* Modals */}
      <PanowingChangeTeethNumberModal />
    </>
  );
};
