import { FC, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import { omit } from 'ramda';

import { Icon, IconNames, Tooltip } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { MedicalImageViewOptions } from '@/shared/api/protocol_gen/model/dto_common_image_view_options';
import { UltimateMedicalImageMode } from '@/shared/graphics/RenderComponents/Presets/UltimateMedicalImage';

import { reportsModel, transformViewOptions } from '@/entities/reports';
import { ModalID, modalModel } from '@/entities/modal';

import { toolsMessages } from '@/features/imageToolbar';

import { PanoRender } from '../../../../shared/graphics/viewer2D/Viewer2D';

import styles from './Toolbar.module.scss';

type ToolNames = 'brightness' | 'sharpening' | 'reset';

export const CONTROL_TOOLS: Record<ToolNames, string> = {
  brightness: 'brightness',
  sharpening: 'shaprness',
  reset: 'back',
};

type ToolbarProps = {
  className?: string;
  handleScrollToothCardsToTop: () => void;
};

const ToolsAndModes = {
  brightness: 'windowing',
  sharpening: 'sharpening',
  reset: 'view',
};

const controls: ToolNames[] = ['sharpening', 'brightness', 'reset'];

export const Toolbar: FC<ToolbarProps> = (props) => {
  // TODO will be removed after ImageToolbar refactoring
  const { className } = props;

  const { formatMessage } = useIntl();

  const dispatch = useAppDispatch();

  const activeControl = useAppSelector(
    reportsModel.selectors.selectToolbarActiveControl,
  );

  const reportId = useAppSelector(reportsModel.selectors.selectCurrentReportID);

  const handleOpenEditModal = useCallback(() => {
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.TeethNumberingModal,
        data: {},
      }),
    );
  }, [dispatch]);

  const handleSetActiveControl = (control: ToolNames) => {
    switch (control) {
      case activeControl:
        PanoRender.activateMode('mainMode');
        break;
      case 'brightness':
        PanoRender.activateMode('contrastBrightnessMode');
        break;
      case 'sharpening':
        PanoRender.activateMode('sharpnessMode');
        break;
      case 'reset':
        PanoRender.resetUndoStack();
        PanoRender.activateMode('mainMode');
        break;
      default:
        break;
    }

    if (control === activeControl || control === 'reset') {
      dispatch(reportsModel.actions.setToolbarActiveControl('view'));
      dispatch(reportsModel.actions.setToolsMode('view'));
      return;
    }

    dispatch(reportsModel.actions.setToolbarActiveControl(control));
    dispatch(
      reportsModel.actions.setToolsMode(
        ToolsAndModes[control] as UltimateMedicalImageMode, // Resolve it without type casting
      ),
    );
  };

  // Single function to handle all events
  const handleViewOptionsChange = (
    event:
      | {
          type: 'sharpnessChanged';
          id: string;
          sharpness: number;
        }
      | {
          type: 'brightnessContrastChanged';
          id: string;
          brightness: number;
          contrast: number;
        },
  ) => {
    const viewOptions = transformViewOptions(omit(['id', 'type'], event));

    dispatch(
      reportsModel.thunks.setReportMedicalImageViewOptions({
        ReportID: reportId as string,
        MedicalImageViewOptions: viewOptions as MedicalImageViewOptions,
      }),
    );
  };

  const tooltipPosition = 'right';

  const isRenderingRunnig = PanoRender.isRunning();

  useEffect(() => {
    if (isRenderingRunnig) {
      PanoRender.addEventListener('sharpnessChanged', handleViewOptionsChange);
      PanoRender.addEventListener(
        'brightnessContrastChanged',
        handleViewOptionsChange,
      );
    }

    return () => {
      PanoRender.removeEventListener(
        'sharpnessChanged',
        handleViewOptionsChange,
      );
      PanoRender.removeEventListener(
        'brightnessContrastChanged',
        handleViewOptionsChange,
      );
    };
  }, [isRenderingRunnig]);

  return (
    <div className={cn(styles.container, className)}>
      {controls.map((control) => (
        <Tooltip.Primary
          key={control}
          side={tooltipPosition}
          content={
            <span className="p3">{formatMessage(toolsMessages[control])}</span>
          }
        >
          <Icon
            name={CONTROL_TOOLS[control] as IconNames}
            className={cn(
              styles.icon,
              activeControl && activeControl === control && styles.active,
            )}
            size={32}
            onClick={() => handleSetActiveControl(control)}
          />
        </Tooltip.Primary>
      ))}

      <Tooltip.Primary
        side={tooltipPosition}
        content={
          <span className="p3">{formatMessage(toolsMessages.editNumbers)}</span>
        }
      >
        <Icon
          name="toothnum"
          className={cn(styles.icon)}
          size={32}
          onClick={handleOpenEditModal}
        />
      </Tooltip.Primary>
    </div>
  );
};
