import { FC } from 'react';
import cn from 'classnames';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { PanoRender } from '@/shared/graphics/viewer2D/Viewer2D';

import { toothModel } from '@/entities/tooth';
import { reportsModel } from '@/entities/reports';

import { RenderViewSlider } from '@/features/renderViewSlider';

import styles from './PanoImageSlider.module.scss';

type PanoImageSliderProps = {
  className?: string;
  reportID: string;
};

export const PanoImageSlider: FC<PanoImageSliderProps> = (props) => {
  const { className, reportID } = props;

  const dispatch = useAppDispatch();

  const teethIDs = useAppSelector(
    toothModel.selectors.selectReportROITeethIDs(reportID),
  );
  const activeToothID = useAppSelector(
    reportsModel.selectors.selectActiveToothID,
  );

  const updateActiveToothID = (newToothID: string) => {
    PanoRender.activateMode('mainMode');
    dispatch(reportsModel.actions.setToolbarActiveControl('view'));
    dispatch(reportsModel.actions.setActiveToothID(newToothID));
  };

  return (
    <RenderViewSlider
      updateActiveItem={updateActiveToothID}
      itemIDs={teethIDs}
      activeItemID={activeToothID}
      className={cn(styles.container, className)}
    />
  );
};
