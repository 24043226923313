import { useParams } from 'react-router';
import { useIntl } from 'react-intl';

import { ReportType } from '@/shared/api/protocol_gen/model/dto_report';

import { ReportLayout, i18n } from '@/entities/reports';

import { Conclusion } from '@/widgets/Conclusion';
import { ReportActions } from '@/widgets/ReportActions';
import { Report2DToothChart } from '@/widgets/Report2DToothChart';

import { PanoToothCardList } from './PanoToothCardList/PanoToothCardList';
import { PanoReportRender } from './PanoReportRender/PanoReportRender';
import { PanoChangeTeethNumberModal } from './PanoChangeTeethNumberModal/PanoChangeTeethNumberModal';
import styles from './PanoReport.module.scss';

export const PanoReport = () => {
  const { patientID, reportID } = useParams();
  const { formatMessage } = useIntl();

  return (
    <>
      <ReportLayout.LeftColumn>
        <h1>{formatMessage(i18n[ReportType.ReportType_Pano_GP])}</h1>
        <PanoReportRender reportID={reportID as string} />
      </ReportLayout.LeftColumn>

      <ReportLayout.RightColumn>
        <Report2DToothChart />

        <PanoToothCardList
          patientID={patientID as string}
          reportID={reportID as string}
        />

        <Conclusion />

        <ReportActions
          className={styles.reportActions}
          patientID={patientID as string}
          reportID={reportID as string}
        />
      </ReportLayout.RightColumn>
      {/* Modals */}
      <PanoChangeTeethNumberModal />
    </>
  );
};
