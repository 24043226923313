import { FC, useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { isEmpty } from 'lodash';

import { Tooth as ReportTooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';
import { MedicalImageInterface } from '@/shared/config';
import {
  Button,
  Column,
  Layout,
  MedicalImage,
  Skeleton,
  WidgetCard,
  WidgetLayout,
} from '@/shared/ui/';
import { useAppDispatch, useAppSelector, useMedia } from '@/shared/hooks';
import { Report } from '@/shared/api/protocol_gen/model/dto_report';

import { getDisplayToothNumber, toothModel } from '@/entities/tooth';
import {
  assetsModel,
  getFileSrc,
  useGetMedicalImageGroups,
  useGetSelectedMedicalImages,
} from '@/entities/assets';
import { reportsModel } from '@/entities/reports';
import { organizationModel } from '@/entities/organization';
import { ConfirmModal, ModalID, modalModel } from '@/entities/modal';
import { useToothConditions } from '@/entities/condition';
import { logicalConditionModel } from '@/entities/logicalCondition';

import { SimplifiedToothChart } from '@/features/simplifiedToothChart';
import { useApproveTooth } from '@/features/approveTooth';
import { useAddAndRemoveMedicalImage } from '@/features/useAddAndRemoveMedicalImage';

import { PanoImage } from '@/widgets/PanoImage';
import { ZoomedMedicalImageModal } from '@/widgets/ZoomedMedicalImageModal';
import { MedicalImagesGroup } from '@/widgets/MedicalImageGroup';
import { GetFullAccessModal } from '@/widgets/GetFullAccessModal';
import { ToothCard } from '@/widgets/ToothCard';
import { AddConditionModal } from '@/widgets/AddConditionModal';
import { UploadedSlices } from '@/widgets/UploadedSlices';
import {
  TeethNumberingModal,
  useGetTeethNumberingModalImage,
} from '@/widgets/TeethNumberingModal';

import { SelectedMedicalImages } from './ui/SelectedMedicalImages/SelectedMedicalImages';
import { PANO_TOOLS, SKELETON_DEFAULT_HEIGHT } from './config/constants';
import { ToothMPR } from './ui/ToothMPR/ToothMPR';
import styles from './Tooth.module.scss';

type ToothProps = {
  className?: string;
};

export const Tooth: FC<ToothProps> = (props) => {
  const { className } = props;

  const [panoImageToggled, setPanoImageToggled] = useState(true);
  const [panoContainerWidth] = useState(0);
  const [draggedMedicalImage, setDraggedMedicalImage] =
    useState<MedicalImageInterface>();
  const [approveButtonHovered, setApproveButtonHovered] = useState(false);

  const { reportID = '', patientID = '', toothID = '' } = useParams();
  const { isSmallDesktop, isMobile, isTablet } = useMedia();
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const selectedMedicalImages = useGetSelectedMedicalImages(toothID);

  const tooth = useAppSelector((state) =>
    toothModel.selectors.selectById(state, toothID),
  );

  const dentalNotationFormat = useAppSelector(
    organizationModel.selectors.selectDentalNotationFormat,
  );

  const report = useAppSelector(reportsModel.selectors.selectByID(reportID));

  const { CBCTPanoImage, modalWidth, CBCTPanoramaURL, CBCTPanoramaSplitURL } =
    useGetTeethNumberingModalImage(report as Report);

  const logicalConditionLoading = useAppSelector(
    logicalConditionModel.selectors.selectLoading,
  );

  const isConditionsLoaded = logicalConditionLoading === 'succeeded';

  const CBCTGPMPRSubVolume = useAppSelector(
    assetsModel.selectors.selectMPRByToothID(toothID),
  );

  const { isApproveToothLoading, toggleApproveTooth } = useApproveTooth(
    tooth as ReportTooth,
  );
  const panoImage = useAppSelector(
    assetsModel.selectors.selectCBCTGPPanoImageByReportID(reportID),
  );

  const panoImageAsset = useAppSelector(
    assetsModel.selectors.selectGPAssetByReportID(reportID),
  );

  const panoImageAssetID = panoImageAsset?.ID;

  const reportViewOptions = useAppSelector(
    reportsModel.selectors.selectReportViewOptions(reportID),
  );

  const {
    removeMedicalImageFromSelected,
    addMedicalImageToSelected,
    toggleMedicalImageSelection,
  } = useAddAndRemoveMedicalImage();

  const isToothLoaded = !!tooth;

  const displayedToothNumber = getDisplayToothNumber(
    tooth?.Numeration?.ISO ?? 0,
    dentalNotationFormat,
  );

  const toothGroups = useGetMedicalImageGroups(toothID);

  const handleAddSelected = (medicalImage: MedicalImageInterface) => {
    if (selectedMedicalImages.find((item) => item.src === medicalImage.src)) {
      return;
    }

    addMedicalImageToSelected(medicalImage.assetID, toothID);
  };

  const toggleSelected = (medicalImage: MedicalImageInterface) => {
    toggleMedicalImageSelection({
      toothID,
      medicalImage,
      selectedMedicalImages,
    });
  };

  const handleDrop = () => {
    handleAddSelected(draggedMedicalImage as MedicalImageInterface);
    setDraggedMedicalImage({} as MedicalImageInterface);
  };

  const handleDeleteMedicalImage = (medicalImage: MedicalImageInterface) => {
    removeMedicalImageFromSelected(medicalImage.assetID, toothID);
  };

  // TODO: [8|m] Just copied it from group. I don't think this is a good way to define selected medical image
  // More obvious way is to just use boolean property in MedicalImageInterface
  const checkIsTheMedicalImageSelected = useCallback(
    (src: string) =>
      selectedMedicalImages?.some((medicalImage) => medicalImage.src === src) ??
      false,
    [selectedMedicalImages],
  );

  // TODO: Make a selector just with checking missing condition code by tooth and return boolean.
  const { isMissingTooth } = useToothConditions({
    toothID: tooth?.ID ?? '',
  });

  const handleApproveButtonHover = (isHovered: boolean) => {
    setApproveButtonHovered(isHovered);
  };

  const isToothApprovedMessage = tooth?.IsApproved && !approveButtonHovered;
  const isToothDisapproveMessage = tooth?.IsApproved && approveButtonHovered;

  const handleOpenModal = useCallback(
    (medicalImage: MedicalImageInterface) => {
      dispatch(
        modalModel.actions.openModal({
          modalID: ModalID.ZoomedMedicalImage,
          data: {
            medicalImage,
            toothID,
            isSelectionEnabled: true,
          },
        }),
      );
    },
    [toothID],
  );

  useEffect(() => {
    if (isSmallDesktop || isTablet || isMobile) {
      setPanoImageToggled(false);
    }
  }, [isSmallDesktop, isTablet, isMobile]);

  return (
    <>
      <Layout.Content>
        <Layout.Main>
          <Layout.Content className={cn(styles.container, className)}>
            <WidgetLayout className={styles.columnWrapper}>
              <Column className={styles.leftColumnWrapper}>
                <div className={cn(styles.leftFixedColumn)}>
                  {isToothLoaded ? (
                    <ToothCard
                      className={styles.mb16}
                      id={tooth.ID}
                      patientID={patientID ?? ''}
                      reportID={reportID ?? ''}
                      showViewerButton={false}
                      // TODO: [8|m] Temporary fast solution for demo. Should be refactored
                      medicalImages={
                        selectedMedicalImages.length > 0
                          ? selectedMedicalImages.map((medicalImage) => {
                              const { annotations, viewOptions, masks, order } =
                                medicalImage;

                              return (
                                <MedicalImage
                                  key={medicalImage.id}
                                  onRemoveMedicalImage={
                                    handleDeleteMedicalImage
                                  }
                                  onClick={() => handleOpenModal(medicalImage)}
                                  medicalImage={medicalImage}
                                  annotations={annotations}
                                  wc={viewOptions?.wwwc?.wc}
                                  ww={viewOptions?.wwwc?.ww}
                                  sharpness={viewOptions?.sharpness}
                                  inverted={viewOptions?.invert}
                                  masks={masks}
                                  order={order}
                                  checkIsTheMedicalImageSelected={
                                    checkIsTheMedicalImageSelected
                                  }
                                />
                              );
                            })
                          : undefined
                      }
                      dndComponent={
                        <SelectedMedicalImages
                          className={cn(
                            styles.selectedMedicalImages,
                            panoImageToggled && styles.medicalImageToogled,
                            draggedMedicalImage && styles.draggedMedicalImage,
                          )}
                          onDrop={handleDrop}
                          dragged={!isEmpty(draggedMedicalImage)}
                        />
                      }
                    />
                  ) : (
                    <Skeleton width="100%" borderRadius="8px" height="46px" />
                  )}

                  <WidgetCard className={cn(styles.panoImageWidget)}>
                    {!panoImage && (
                      <Skeleton
                        width="100%"
                        borderRadius="8px"
                        height={`${SKELETON_DEFAULT_HEIGHT}px`}
                      />
                    )}
                    {CBCTPanoImage && CBCTPanoramaURL && (
                      <PanoImage
                        src={CBCTPanoramaURL}
                        splitUrl={CBCTPanoramaSplitURL}
                        controls={PANO_TOOLS}
                        style={{
                          width: '100%',
                        }}
                        toggled={false}
                        containerWidth={panoContainerWidth}
                        toolbarPosition="inside"
                        viewOptions={reportViewOptions}
                        assetID={panoImageAssetID}
                        reportID={reportID}
                      />
                    )}
                  </WidgetCard>
                </div>
              </Column>

              {isConditionsLoaded && (
                <Column className={cn(styles.rightColumn)}>
                  <WidgetCard>
                    <div className={styles.mprHeader}>
                      <div className={styles.mprHeaderText}>
                        <h4 className="h4">
                          <FormattedMessage
                            id="tooth.mpr.abbreviation"
                            defaultMessage="MPR"
                          />
                        </h4>
                        <p className="p3">
                          <FormattedMessage
                            id="tooth.mpr"
                            defaultMessage="Multi-Planar Reconstruction"
                          />
                        </p>
                      </div>
                      {/* TODO: make full screen mode and connect this btn */}
                      {/* <button */}
                      {/*   className={styles.mprExpand} */}
                      {/*   type="button" */}
                      {/*   disabled={!CBCTGPMPRSubVolume} */}
                      {/*   onClick={() => */}
                      {/*     dispatch( */}
                      {/*       modalModel.actions.openMPRFullScreenModal( */}
                      {/*         getImageSrc(CBCTGPMPRSubVolume?.Volume?.ID), */}
                      {/*       ), */}
                      {/*     ) */}
                      {/*   } */}
                      {/* > */}
                      {/*   <Icon */}
                      {/*     name="expand" */}
                      {/*     size={32} */}
                      {/*   /> */}
                      {/* </button> */}
                    </div>

                    <ToothMPR
                      isMissingTooth={isMissingTooth}
                      mprUrl={getFileSrc(CBCTGPMPRSubVolume?.Volume?.ID ?? '')}
                      toothID={toothID}
                      initialWw={CBCTGPMPRSubVolume?.WindowWidth}
                      initialWc={CBCTGPMPRSubVolume?.WindowLevel}
                    />
                  </WidgetCard>

                  {!isMissingTooth && (
                    <WidgetCard>
                      <MedicalImagesGroup
                        onDragMedicalImage={setDraggedMedicalImage}
                        selectedMedicalImages={selectedMedicalImages}
                        toggleSelected={toggleSelected}
                        groups={toothGroups}
                      />
                    </WidgetCard>
                  )}

                  <UploadedSlices
                    toothID={toothID}
                    toggleSelected={toggleSelected}
                    onDragMedicalImage={setDraggedMedicalImage}
                  />
                </Column>
              )}
            </WidgetLayout>
          </Layout.Content>
        </Layout.Main>
      </Layout.Content>

      <Layout.Footer settingsFooter>
        <div className={styles.footerWrapper}>
          <SimplifiedToothChart
            reportID={reportID ?? ''}
            patientID={patientID ?? ''}
            toothOfInterestNumber={displayedToothNumber as number}
          />

          <div className={styles.footerButtons}>
            {/* TODO: [2|m] refactore and move to ApproveToothButton feature */}
            <Button
              onMouseMove={() => handleApproveButtonHover(true)}
              onMouseLeave={() => handleApproveButtonHover(false)}
              size={isSmallDesktop || isMobile ? 'medium' : 'large'}
              icon={tooth?.IsApproved ? 'check' : 'plus'}
              loading={isApproveToothLoading}
              onClick={toggleApproveTooth}
              disabled={!report?.YourPermissions?.CanChangeToothApproved}
              success={!approveButtonHovered && tooth?.IsApproved}
              danger={approveButtonHovered && tooth?.IsApproved}
              className={cn(tooth?.IsApproved && styles.buttonApproved)}
            >
              {isToothApprovedMessage && (
                <FormattedMessage
                  id="toothCard.approved"
                  defaultMessage="Approved"
                />
              )}

              {isToothDisapproveMessage && (
                <FormattedMessage
                  id="toothCard.disapprove"
                  defaultMessage="Disapprove"
                />
              )}

              {!tooth?.IsApproved && (
                <FormattedMessage
                  id="toothCard.approve"
                  defaultMessage="Approve"
                />
              )}
            </Button>
          </div>
        </div>
      </Layout.Footer>

      <TeethNumberingModal
        title={formatMessage({
          id: 'report.EditTeethNumbers',
          defaultMessage: 'Edit teeth numbers',
        })}
        patientID={patientID}
        reportID={reportID}
        reportImage={CBCTPanoImage}
        modalWidth={modalWidth}
      />

      <ZoomedMedicalImageModal />

      {/* <MprViewerModal /> */}

      <GetFullAccessModal />

      <AddConditionModal />

      <ConfirmModal />
    </>
  );
};
