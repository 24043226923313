import { FC, useCallback, useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { isEmpty } from 'ramda';

import { StudyType } from '@/shared/api/protocol_gen/model/dto_study';
import {
  Button,
  Column,
  Icon,
  Layout,
  Tooltip,
  WidgetCard,
  WidgetLayout,
} from '@/shared/ui';
import { useAppDispatch, useAppSelector, useMedia } from '@/shared/hooks';
import { IS_DEVELOP_FEATURE, LocationStateType } from '@/shared/config';
import { ReportType } from '@/shared/api/protocol_gen/model/dto_report';
import { ServiceType } from '@/shared/api/protocol_gen/api/billing/dto_services';
import { gender } from '@/shared/i18n';
import { FeatureFlag } from '@/shared/api/protocol_gen/model/dto_organization';

import { ConfirmModal, ModalID, modalModel } from '@/entities/modal';
import {
  getPatientFullName,
  getPatientYourPermissions,
  patientModel,
  useFormatPatientDateOfBirth,
} from '@/entities/patient';
import { reportsModel } from '@/entities/reports';
import { filterStudiesByType, studyModel } from '@/entities/study';
import { userModel } from '@/entities/user';
import { useBillingAccess } from '@/entities/billing';
import { organizationModel } from '@/entities/organization';

import { Footer } from '@/features/footer';

import { Header } from '@/widgets/Header';
import { PatientInfo } from '@/widgets/PatientInfo';
import { PatientModalForm } from '@/widgets/PatientModalForm';
import { StudyCard } from '@/widgets/StudyCard';
import {
  StudyInformation,
  useStudyInfoModal,
} from '@/widgets/StudyInformation';
import { UploadStudyModalForm } from '@/widgets/UploadStudyModalForm';
import { GetFullAccessModal } from '@/widgets/GetFullAccessModal';
import { OrderImplantOrOrthoModelModal } from '@/widgets/OrderModelModal';
import { Order3DSegmentronModal } from '@/widgets/Order3DSegmentronModal';
import { AboutLabelingModal } from '@/widgets/AboutLabelingModal';

import { SegmentronModelWidget } from './ui/SegmentronModelWidget/SegmentronModelWidget';
import { getSortedStudiesByColumn } from './utils/getSortedStudiesByColumn';
import styles from './PatientProfile.module.scss';
import { OrderImplantOrOrthoModel } from './ui/OrderModel/OrderImplantOrOrthoModel';

const SegmentationReportTypes: ReportType[] = [
  ReportType.ReportType_CBCT_Segmentation,
  ReportType.ReportType_CBCT_IOS_Superimposition,
  ReportType.ReportType_IOS_Segmentation,
  ReportType.ReportType_StudioImplant,
  ReportType.ReportType_StudioOrtho,
];

export const PatientProfile: FC = () => {
  const { patientID = '' } = useParams();

  const dispatch = useAppDispatch();

  const { formatMessage, formatDate } = useIntl();

  const {
    currentStudyID,
    openStudyInfoModal,
    closeStudyModal,
    showStudyInfoModal,
  } = useStudyInfoModal();

  const { isMobile } = useMedia();

  const location = useLocation();

  const user = useAppSelector(userModel.selectors.selectCurrentUser);

  const showOrder3DReportsWidget = useAppSelector(
    organizationModel.selectors.selectIsFeatureActiveByName(
      FeatureFlag.FeatureFlag_Show_Order3DReports,
    ),
  );

  const services = useBillingAccess();

  const patient = useAppSelector(
    patientModel.selectors.selectPatientByID(patientID),
  );

  const studies = useAppSelector(
    studyModel.selectors.selectByPatientID(patientID),
  );

  const patientReports = useAppSelector(
    reportsModel.selectors.selectByPatientID(patientID),
  );

  const patientDateOfBirth = patient?.PersonalData?.DateOfBirth;

  const formattedPatientAge = useFormatPatientDateOfBirth({
    patientDateOfBirth,
    pluralized: true,
  });

  const { CanEditPatient } = getPatientYourPermissions(patient);

  const segmentationReports = patientReports
    .filter(
      (report) =>
        SegmentationReportTypes.includes(report.Type) &&
        !report.Deleted?.Deleted,
    )
    .sort((a, b) => a.Type - b.Type);

  const locationState = location?.state as LocationStateType;

  const scrollToLastPositionPatientProfile =
    locationState?.lastPositionPatientProfile;

  const isSegmentronActive =
    showOrder3DReportsWidget &&
    !!(
      services[ServiceType.ServiceType_Order_ImplantStudio_Analysis] ||
      services[ServiceType.ServiceType_Order_OrthoStudio_Analysis] ||
      services[
        ServiceType.ServiceType_Order_CBCT_IOS_Superimposition_Analysis
      ] ||
      services[ServiceType.ServiceType_Order_IOS_Segmentation_Analysis] ||
      services[ServiceType.ServiceType_Order_CBCT_STL_Analysis]
    );

  const hasSegmentronReports = !!segmentationReports.length;

  useEffect(() => {
    if (scrollToLastPositionPatientProfile) {
      window.scrollTo(0, scrollToLastPositionPatientProfile);
    }
  }, [scrollToLastPositionPatientProfile]);

  // Mabye simpler just group studies by type and render each type when we need?
  const CBCTStudies = filterStudiesByType(StudyType.StudyType_CBCT)(studies);
  const IOSStudies = filterStudiesByType(StudyType.StudyType_IOS_Meshes)(
    studies,
  );

  const panoramicStudies = filterStudiesByType(
    StudyType.StudyType_PanoramicXRay,
  )(studies);
  const ioxrayStudies = filterStudiesByType(StudyType.StudyType_IntraoralXRay)(
    studies,
  );

  const rawSTLStudies = filterStudiesByType(StudyType.StudyType_IOS_Meshes)(
    studies,
  );

  const collectedStudies = useMemo(() => {
    const studiesArray = [
      { id: 1, data: CBCTStudies },
      { id: 2, data: panoramicStudies },
      { id: 3, data: ioxrayStudies },
    ];

    return studiesArray.sort((firstStudies, secondStudies) => {
      const firstStudiesFirstItem = firstStudies.data[0];
      const firstItemCreatedAt = firstStudiesFirstItem?.Created?.At;

      const secondStudiesFirstItem = secondStudies.data[0];
      const secondItemCreatedAt = secondStudiesFirstItem?.Created?.At;

      if (!firstItemCreatedAt || !secondItemCreatedAt) {
        return 0;
      }

      return (
        new Date(secondItemCreatedAt).getTime() -
        new Date(firstItemCreatedAt).getTime()
      );
    });
  }, [CBCTStudies, panoramicStudies, ioxrayStudies]);

  const { leftColumn, rightColumn } = getSortedStudiesByColumn(
    collectedStudies,
    isSegmentronActive,
    hasSegmentronReports,
  );

  const openUploadStudyModal = useCallback(() => {
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.UploadStudy,
        data: { patientID },
      }),
    );
  }, [dispatch, patientID]);

  if (isEmpty(user) || patient === undefined) {
    return null;
  }

  const {
    CanUploadCBCT,
    CanUploadDentalPhoto,
    CanUploadSTL,
    CanUploadPanoramicXRay,
    CanUploadIOXRay,
    CanViewStudies,
  } = getPatientYourPermissions(patient);

  const handleEditPatient = () => {
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.PatientModalForm,
        data: {
          patientID,
        },
      }),
    );
  };

  const patientGender = formatMessage(gender[patient?.Gender]);

  const patientExternalID = `${formatMessage({
    id: 'patientInfo.externalID',
    defaultMessage: 'ID',
  })}: ${patient?.ExternalID}`;

  const patientEmail = patient?.PersonalData?.Emails[0];

  const isAnyUploadsAvailable =
    (CanUploadCBCT && services[ServiceType.ServiceType_Upload_CBCT]) ||
    (CanUploadIOXRay && services[ServiceType.ServiceType_Upload_IOXRay]) ||
    (CanUploadPanoramicXRay &&
      services[ServiceType.ServiceType_Upload_Panorama]) ||
    (CanUploadSTL && services[ServiceType.ServiceType_Upload_STL]) ||
    (CanUploadDentalPhoto &&
      services[ServiceType.ServiceType_Upload_DentalPhoto]);

  return (
    <Layout>
      <Layout.Header>
        <Header />
      </Layout.Header>

      <Layout.Content>
        <Layout.Main className={styles.main}>
          <div className={styles.header}>
            <div className={styles.patientInfo}>
              <h1 className={cn(styles.patientName, 'h3')}>
                {getPatientFullName(patient)}
              </h1>

              <div className={styles.patientInfoDetails}>
                <div className={styles.patientInfoDetailsWrapper}>
                  <p className="p2">{formattedPatientAge}</p>
                  <p className="p2">{patientGender}</p>
                  {patient.ExternalID ? (
                    <p className="p2">{patientExternalID}</p>
                  ) : null}
                </div>
                {patientEmail && (
                  <p className={cn(styles.patientEmail, 'p2')}>
                    {patientEmail}{' '}
                  </p>
                )}
                {CanEditPatient && (
                  <Tooltip.Primary
                    content={
                      <FormattedMessage
                        id="global.edit"
                        defaultMessage="Edit"
                      />
                    }
                  >
                    <Icon
                      name="pen"
                      size={24}
                      onClick={handleEditPatient}
                      className={styles.penIcon}
                    />
                  </Tooltip.Primary>
                )}
              </div>
            </div>

            <Button
              disabled={!isAnyUploadsAvailable}
              icon="plus"
              size={isMobile ? 'medium' : 'large'}
              onClick={openUploadStudyModal}
              className={styles.newStudyButton}
            >
              <FormattedMessage
                id="PatientProfile.openUploadStudyModal"
                defaultMessage="Upload new study"
              />
            </Button>
          </div>

          <WidgetLayout>
            <Column className={styles.column}>
              <PatientInfo patientID={patientID} />

              {CanViewStudies &&
                leftColumn.map(
                  (currentStudies) =>
                    !!currentStudies.data.length && (
                      <StudyCard
                        key={currentStudies.id}
                        studies={currentStudies.data}
                        onViewDetails={openStudyInfoModal}
                      />
                    ),
                )}
            </Column>

            <Column className={styles.column}>
              {/* TODO: [1/l] return the widget when the segmentron subscriptions will be ready */}
              {isSegmentronActive && <OrderImplantOrOrthoModel />}
              {isSegmentronActive &&
                hasSegmentronReports &&
                segmentationReports.map((report) => (
                  <SegmentronModelWidget key={report.ID} report={report} />
                ))}
              {CanViewStudies &&
                rightColumn.map(
                  (currentStudies) =>
                    !!currentStudies.data.length && (
                      <StudyCard
                        key={currentStudies.id}
                        studies={currentStudies.data}
                        onViewDetails={openStudyInfoModal}
                      />
                    ),
                )}
              {IS_DEVELOP_FEATURE &&
                rawSTLStudies?.map((rawSTLStudy) => (
                  <WidgetCard key={rawSTLStudy.ID}>
                    <a
                      href={`https://dev.diagnocat.dev/studio/viewer/?study_uid=${rawSTLStudy?.ID}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Raw STL uploaded at {` `}
                      {formatDate(rawSTLStudy?.Created?.At, {
                        dateStyle: 'medium',
                        timeStyle: 'short',
                      })}
                    </a>
                  </WidgetCard>
                ))}
            </Column>
          </WidgetLayout>

          <StudyInformation
            studyID={currentStudyID as string}
            isOpen={showStudyInfoModal}
            onClose={closeStudyModal}
          />

          <PatientModalForm />

          <UploadStudyModalForm />

          <ConfirmModal />
        </Layout.Main>
      </Layout.Content>

      <Layout.Footer>
        <Footer />
      </Layout.Footer>

      <AboutLabelingModal />

      <GetFullAccessModal />

      <OrderImplantOrOrthoModelModal
        patientID={patientID}
        cbctStudies={CBCTStudies}
        iosStudies={IOSStudies}
      />

      <Order3DSegmentronModal CBCTStudies={CBCTStudies} />
    </Layout>
  );
};
