import { Group, Vector3, PerspectiveCamera, OrthographicCamera, NoToneMapping, LinearEncoding, Vector4, Scene, WebGLRenderer, Raycaster, Vector2, MathUtils, Material, Color, BufferAttribute, Line, Line3, Matrix4, Quaternion, ShapeUtils, Mesh, Float32BufferAttribute, Object3D, ShaderMaterial, LoadingManager, TextureLoader, DataTexture, RedFormat, FloatType, LinearFilter, RawShaderMaterial, PlaneGeometry, RepeatWrapping, Matrix3, NearestFilter, Box3, MeshBasicMaterial } from 'three';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry';
import dicomParser from 'dicom-parser';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { ClearPass } from 'three/examples/jsm/postprocessing/ClearPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { Text } from 'troika-three-text';

class ToolsController {
  constructor(hostObject) {
    this.hostObject = hostObject;
    this.tools = /* @__PURE__ */ new Map();
    this.enable = false;
  }
  init() {
    if (!this.enable) {
      this.enable = true;
      this.initServiceTools();
      this.activeMode?.init();
    }
  }
  dispose() {
    if (this.enable) {
      this.disposeServiceTools();
      this.tools?.forEach((tool) => tool.dispose());
      this.activeMode?.dispose();
      this.activeMode = void 0;
      this.enable = false;
    }
  }
  clear() {
    this.dispose();
    this.serviceTools = void 0;
    this.modes = void 0;
    this.tools.clear();
  }
  setServiceTools(toolTypes) {
    this.serviceTools = /* @__PURE__ */ new Set();
    toolTypes.forEach((toolType) => this.serviceTools.add(new toolType(this.hostObject)));
  }
  initServiceTools() {
    this.serviceTools?.forEach((tool) => tool.init());
  }
  disposeServiceTools() {
    this.serviceTools?.forEach((tool) => tool.dispose());
  }
  setTools(tools) {
    let toolInstance;
    tools.forEach((tool) => {
      toolInstance = new tool(this.hostObject);
      if (!this.tools.has(tool)) {
        this.tools.set(tool, toolInstance);
      }
    });
  }
  getTools() {
    return this.tools;
  }
  getTool(name) {
    return this.tools?.get(name);
  }
  getToolByName(name) {
    for (const [key, value] of this.tools) {
      if (key.name === name)
        return value;
    }
  }
  setModes(modes) {
    if (!this.modes) {
      this.modes = modes;
      Object.keys(modes).forEach(
        (modeName) => modes[modeName].setHostObject(this.hostObject, modeName)
      );
    }
  }
  activateMode(name) {
    if (this.modes && this.activeMode !== this.modes[name]) {
      this.enable && this.modes[name].init();
      this.activeMode = this.modes[name];
    }
  }
  getModeName() {
    return this.activeMode?.getName();
  }
  getModes() {
    return this.modes;
  }
  getMode() {
    return this.activeMode;
  }
}

class Container {
  constructor(hostObject, objects) {
    this.hostObject = hostObject;
    this.objects = objects;
  }
}

class LayerContainer extends Container {
  constructor(layer, objects) {
    super(layer, objects);
    this.objects = objects;
    this.components = /* @__PURE__ */ new Map();
    this.deleteOne = this.deleteOne.bind(this);
    this.addOne = this.addOne.bind(this);
  }
  size() {
    return this.objects.length;
  }
  add(entity) {
    if (Array.isArray(entity)) {
      const added = entity.filter(this.addOne);
      if (added.length) {
        App.Instance().dispatchEvent({ type: "add", entities: [...entity], container: this.hostObject });
      }
    } else {
      if (this.addOne(entity)) {
        App.Instance().dispatchEvent({ type: "add", entities: [entity], container: this.hostObject });
      }
    }
  }
  delete(entity) {
    if (Array.isArray(entity)) {
      const deleted = entity.filter(this.deleteOne);
      if (deleted.length) {
        this.hostObject.getGroup().remove(...entity.map((item) => item.object3d));
        App.Instance().dispatchEvent({ type: "delete", entities: deleted, container: this.hostObject });
      }
    } else {
      if (this.objects.includes(entity)) {
        if (this.deleteOne(entity)) {
          this.hostObject.getGroup().remove(entity.object3d);
          App.Instance().dispatchEvent({ type: "delete", entities: [entity], container: this.hostObject });
        }
      }
    }
  }
  addOne(entity) {
    if (entity.getLayer() !== this.hostObject) {
      this.objects.push(entity);
      entity.setLayer(this.hostObject);
      this.hostObject.getGroup().add(entity.object3d);
      entity.components.forEach((value, key) => {
        const set = this.components.get(key);
        if (set) {
          set.add(value);
        } else {
          this.components.set(key, /* @__PURE__ */ new Set([value]));
        }
      });
      return true;
    }
    return false;
  }
  deleteOne(entity) {
    const index = this.objects.indexOf(entity);
    if (index >= 0) {
      this.objects.splice(index, 1);
      entity.setLayer(void 0);
      entity.components.forEach((value, key) => {
        const set = this.components.get(key);
        if (set) {
          set.delete(value);
        }
      });
      return true;
    }
    return false;
  }
}

class Layer {
  constructor(view, name, objects = []) {
    this.getObjectByName = (name, force = false) => {
      if (!(force || this.on && !this.lock))
        return;
      return this.container.objects.find((entity) => entity.object3d.name === name);
    };
    this.objects = objects;
    this.on = true;
    this.lock = false;
    this.view = view;
    this.name = name;
    this.group = new Group();
    this.group.name = name;
    this.container = new LayerContainer(this, objects);
  }
  getGroup() {
    return this.group;
  }
  getObjects(force = false) {
    if (force || this.on && !this.lock) {
      return this.objects;
    }
    return [];
  }
  getComponent(type) {
    return this.container.components.get(type);
  }
  has(obj) {
    return this.objects.includes(obj);
  }
  add(object) {
    this.container.add(object);
  }
  delete(object) {
    this.container.delete(object);
  }
  clear() {
    this.objects.forEach((obj) => {
      obj.setLayer(void 0);
    });
    this.group.remove(...this.objects.map((item) => item.object3d));
    const tmpObjects = [...this.objects];
    this.objects.length = 0;
    this.container.components.clear();
    App.Instance().dispatchEvent({ type: "delete", entities: tmpObjects, container: this });
  }
  hide() {
    this.group.visible = false;
  }
  show() {
    this.group.visible = true;
  }
  getName() {
    return this.name;
  }
  getView() {
    return this.view;
  }
}

class CameraHelper {
  constructor(cameraController) {
    this.cameraController = cameraController;
  }
  init() {
    this.handleResize();
    this.cameraController.viewport.addEventListener("resize", this.handleResize, 10);
  }
  dispose() {
    App.Instance().removeEventListener("resize", this.handleResize);
  }
  getDistanceFactor() {
    return this.distanceFactor;
  }
  get viewport() {
    return this.cameraController.viewport;
  }
}

class OrthographicCameraHelper extends CameraHelper {
  constructor(cameraController) {
    super(cameraController);
    this.handleResize = () => {
      this.aspect = this.viewport.width / this.viewport.height;
      if (this.viewport.width === 0 || this.viewport.height === 0) {
        return;
      }
      this.sizeRatio = this.frustumSize / this.viewport.height;
      this.camera.left = -0.5 * this.frustumSize * this.aspect;
      this.camera.right = 0.5 * this.frustumSize * this.aspect;
      this.camera.top = this.frustumSize / 2;
      this.camera.bottom = -this.frustumSize / 2;
      this.camera.updateProjectionMatrix();
    };
    this.frustumSize = 600;
    this.aspect = 1;
    this.sizeRatio = 1;
    this.updateDistanceFactor();
  }
  get camera() {
    return this.cameraController.getOrthographicCamera();
  }
  getSizeRatio() {
    return this.sizeRatio;
  }
  updateDistanceFactor() {
    return this.distanceFactor = 1;
  }
}

class PerspectiveCameraHelper extends CameraHelper {
  constructor(cameraController) {
    super(cameraController);
    this.offset = new Vector3();
    this.handleResize = () => {
      this.camera.aspect = this.viewport.width / this.viewport.height;
      this.camera.updateProjectionMatrix();
      this.updateSubDistCoefficient();
      this.updateDistanceFactor();
    };
    this.updateDistanceFactor();
  }
  get camera() {
    return this.cameraController.getPerspectiveCamera();
  }
  updateSubDistCoefficient() {
    this.subCoefficient = 2 * Math.tan(this.camera.fov / 2 * Math.PI / 180) / this.viewport.height;
  }
  updateDistanceFactor() {
    return this.distanceFactor = this.offset.copy(this.cameraController.getPivotPoint()).sub(this.camera.position).length() * this.subCoefficient;
  }
}

const initialProps = {
  perspective: {},
  orthographic: { left: -1, right: 1, top: 1, bottom: -1, near: -1e3, far: 1e3 },
  zoom: 1,
  matrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
  type: "camera-controller",
  key: "",
  up: [0, 1, 0],
  pivotPoint: [0, 0, 0]
};
class CameraController {
  constructor(viewport, props = initialProps) {
    this.viewport = viewport;
    var { fov, aspect, near, far } = props.perspective || {};
    this.perspectiveCamera = new PerspectiveCamera(fov, aspect, near, far);
    var { left, right, top, bottom, near, far } = props.orthographic || initialProps.orthographic;
    this.orthographicCamera = new OrthographicCamera(left, right, top, bottom, near, far);
    const matrix = props.matrix ? props.matrix : initialProps.matrix;
    this.perspectiveCamera.matrix.set(...matrix);
    this.perspectiveCamera.matrixWorldNeedsUpdate = true;
    this.orthographicCamera.matrix.set(...matrix);
    this.orthographicCamera.matrixWorldNeedsUpdate = true;
    if (props.up) {
      this.perspectiveCamera.up.set(...props.up).normalize();
      this.orthographicCamera.up.set(...props.up).normalize();
    }
    this.pivotPoint = new Vector3();
    this.cameraX = new Vector3();
    this.cameraY = new Vector3();
    this.cameraZ = new Vector3();
    this.perspectiveCameraHelper = new PerspectiveCameraHelper(this);
    this.orthographicCameraHelper = new OrthographicCameraHelper(this);
    this.orthographic();
    this.onTransform = this.onTransform.bind(this);
  }
  init() {
    this.cameraHelper.init();
    App.Instance().addEventListener("transformCamera", this.onTransform, 10);
  }
  onTransform(event) {
    if (event.viewport === this.viewport) {
      this.camera.updateMatrixWorld();
    }
  }
  updatePivotPoint(point, fireEvent = true) {
    this.pivotPoint.copy(point);
    this.viewport.dispatchEvent({ type: "updatePivotPoint", point: this.pivotPoint, fireEvent });
  }
  getPivotPoint() {
    return this.pivotPoint;
  }
  dispose() {
    this.perspectiveCameraHelper.dispose();
    this.orthographicCameraHelper.dispose();
  }
  saveState() {
    this.savedState = this.toJson();
  }
  setPosition(v, y, z) {
    this.camera.matrix.setPosition(v, y, z);
  }
  updateMatrix(matrix) {
    this.camera.matrix.copy(matrix);
    this.camera.matrixWorldNeedsUpdate = true;
  }
  orthographic() {
    this.camera = this.orthographicCamera;
    this.cameraHelper = this.orthographicCameraHelper;
    this.perspectiveCameraHelper.dispose();
    this.orthographicCameraHelper.init();
    this.getDistanceFactor = () => this.orthographicCameraHelper.updateDistanceFactor();
    this.viewport.dispatchEvent({
      type: "cameraChanged",
      previous: this.perspectiveCamera,
      current: this.orthographicCamera
    });
  }
  perspective() {
    this.camera = this.perspectiveCamera;
    this.cameraHelper = this.perspectiveCameraHelper;
    this.orthographicCameraHelper.dispose();
    this.perspectiveCameraHelper.init();
    this.getDistanceFactor = () => this.perspectiveCameraHelper.updateDistanceFactor();
    this.viewport.dispatchEvent({
      type: "cameraChanged",
      previous: this.orthographicCamera,
      current: this.perspectiveCamera
    });
  }
  copyFromActive() {
    const camera = this.camera === this.orthographicCamera ? this.perspectiveCamera : this.orthographicCamera;
    camera.matrix.copy(this.camera.matrix);
    camera.matrix.decompose(camera.position, camera.quaternion, camera.scale);
    camera.layers = this.camera.layers;
  }
  getPerspectiveCamera() {
    return this.perspectiveCamera;
  }
  getOrthographicCamera() {
    return this.orthographicCamera;
  }
  get xDir() {
    return this.cameraX.setFromMatrixColumn(this.camera.matrix, 0);
  }
  get yDir() {
    return this.cameraY.setFromMatrixColumn(this.camera.matrix, 1);
  }
  get zDir() {
    return this.cameraZ.setFromMatrixColumn(this.camera.matrix, 2);
  }
  fromJson(props) {
    this.perspectiveCamera.matrix.fromArray(props.matrix);
    this.perspectiveCamera.matrix.decompose(this.perspectiveCamera.position, this.perspectiveCamera.quaternion, this.perspectiveCamera.scale);
    this.perspectiveCamera.matrixWorldNeedsUpdate = true;
    props.perspective.fov ? this.perspectiveCamera.fov = props.perspective.fov : void 0;
    props.perspective.aspect ? this.perspectiveCamera.aspect = props.perspective.aspect : void 0;
    props.perspective.near ? this.perspectiveCamera.near = props.perspective.near : void 0;
    props.perspective.far ? this.perspectiveCamera.far = props.perspective.far : void 0;
    this.perspectiveCamera.zoom = props.zoom;
    props.up ? this.perspectiveCamera.up.fromArray(props.up) : void 0;
    this.perspectiveCameraHelper.handleResize();
    this.orthographicCamera.matrix.fromArray(props.matrix);
    this.orthographicCamera.matrix.decompose(this.orthographicCamera.position, this.orthographicCamera.quaternion, this.orthographicCamera.scale);
    this.orthographicCamera.matrixWorldNeedsUpdate = true;
    this.orthographicCamera.left = props.orthographic.left;
    this.orthographicCamera.right = props.orthographic.right;
    this.orthographicCamera.top = props.orthographic.top;
    this.orthographicCamera.bottom = props.orthographic.bottom;
    props.orthographic.near ? this.orthographicCamera.near = props.orthographic.near : void 0;
    props.orthographic.far ? this.orthographicCamera.far = props.orthographic.far : void 0;
    this.orthographicCamera.zoom = props.zoom;
    props.up ? this.orthographicCamera.up.fromArray(props.up) : void 0;
    this.orthographicCameraHelper.handleResize();
    const pivotPoint = new Vector3().fromArray(props.pivotPoint);
    this.updatePivotPoint(pivotPoint);
  }
  toJson() {
    return {
      perspective: {
        fov: this.perspectiveCamera.fov,
        aspect: this.perspectiveCamera.aspect,
        near: this.perspectiveCamera.near,
        far: this.perspectiveCamera.far
      },
      orthographic: {
        left: this.orthographicCamera.left,
        right: this.orthographicCamera.right,
        top: this.orthographicCamera.top,
        bottom: this.orthographicCamera.bottom,
        near: this.orthographicCamera.near,
        far: this.orthographicCamera.far
      },
      zoom: this.camera.zoom,
      matrix: this.camera.matrix.toArray(),
      type: "camera-controller",
      key: this.camera.uuid ?? "",
      up: this.camera.up.toArray(),
      pivotPoint: this.getPivotPoint().toArray()
    };
  }
  fromSavedState() {
    if (this.savedState) {
      this.fromJson(this.savedState);
    } else {
      console.error("state is not saved");
    }
  }
}

class ListenerStorage {
  constructor() {
    this.listeners = [];
  }
  add(callback, priority = 0, force = false) {
    if (!this.has(callback)) {
      if (priority !== 0 && this.listeners.length > 0) {
        const index = this.findIndex(this.listeners, priority, 0, this.listeners.length - 1);
        this.listeners.splice(index, 0, {
          callback,
          enable: true,
          priority
        });
      } else {
        this.listeners.push({ callback, enable: true, priority });
      }
      if (force && this.listenersForFire && typeof this.firedListenerIndex !== "undefined") {
        if (priority !== 0 && this.listeners.length > 0) {
          const index = this.findIndex(this.listenersForFire, priority, 0, this.listeners.length - 1);
          if (index > this.firedListenerIndex) {
            this.listenersForFire.splice(index, 0, {
              callback,
              enable: true,
              priority
            });
          } else if (index <= this.firedListenerIndex) {
            if (this.listenersForFire[this.firedListenerIndex + 1].priority === priority) {
              this.listenersForFire.splice(this.firedListenerIndex + 1, 0, {
                callback,
                enable: true,
                priority
              });
            }
          }
        } else {
          this.listenersForFire.push({
            callback,
            enable: true,
            priority
          });
        }
      }
    }
  }
  remove(callback, force = false) {
    let index = this.listeners.findIndex((listener) => listener.callback === callback);
    if (index >= 0) {
      this.listeners.splice(index, 1);
    }
    if (force && this.listenersForFire && typeof this.firedListenerIndex !== "undefined") {
      index = this.listenersForFire.findIndex((listener) => listener.callback === callback);
      if (index > this.firedListenerIndex) {
        this.listenersForFire.splice(index, 1);
      } else {
        console.error("listener already executed");
      }
    }
  }
  findIndex(listeners, priority, start, end) {
    let middle = Math.floor((start + end) / 2);
    if (listeners[middle].priority === priority) {
      return middle;
    } else {
      if (priority < listeners[middle].priority) {
        if (middle < end) {
          return this.findIndex(listeners, priority, middle + 1, end);
        }
        return end + 1;
      } else {
        if (start < middle) {
          return this.findIndex(listeners, priority, start, middle - 1);
        }
        return start;
      }
    }
  }
  fire(arg) {
    if (typeof arg === "undefined" && typeof this.fireArgument !== "undefined") {
      arg = this.fireArgument;
    }
    this.listenersForFire = this.getListeners();
    this.listenersForFire.forEach((listener, index) => {
      this.firedListenerIndex = index;
      if (listener.enable) {
        listener.callback(arg);
      }
    });
    this.listenersForFire = void 0;
    this.firedListenerIndex = void 0;
  }
  getListeners() {
    return [...this.listeners];
  }
  clear() {
    this.listeners.splice(0);
  }
  isEmpty() {
    return this.listeners.length === 0;
  }
  has(callback) {
    return this.listeners.findIndex((listener) => listener.callback === callback) >= 0;
  }
  setEnabled(value, callback) {
    if (callback) {
      if (callback.flag === "me") {
        const listener = this.listeners.find((listener2) => listener2.callback === callback.callback);
        if (listener) {
          listener.enable = value;
        }
      } else {
        const listener = this.listeners.filter((listener2) => listener2.callback !== callback.callback);
        listener.forEach((listener2) => listener2.enable = value);
      }
    } else {
      this.listeners.forEach((listener) => listener.enable = value);
    }
  }
  enable(callback) {
    if (callback) {
      this.setEnabled(true, { callback, flag: "me" });
    } else {
      this.setEnabled(true);
    }
  }
  disable(callback) {
    if (callback) {
      this.setEnabled(false, { callback, flag: "me" });
    } else {
      this.setEnabled(false);
    }
  }
  enableExcept(callbacks) {
    if (Array.isArray(callbacks)) {
      this.disable();
      callbacks.forEach((callback) => this.setEnabled(false, { callback, flag: "me" }));
    } else {
      this.setEnabled(true, { callback: callbacks, flag: "exceptMe" });
    }
  }
  disableExcept(callbacks) {
    if (Array.isArray(callbacks)) {
      this.disable();
      callbacks.forEach((callback) => this.setEnabled(true, { callback, flag: "me" }));
    } else {
      this.setEnabled(false, { callback: callbacks, flag: "exceptMe" });
    }
  }
}

class EventDispatcher {
  constructor(eventsList) {
    this.listeners = /* @__PURE__ */ new Map();
    this.map = /* @__PURE__ */ new Map();
    if (eventsList) {
      this.setListeners(eventsList);
    }
  }
  setListeners(eventsList) {
    !this.listeners.size && eventsList.forEach((event) => this.listeners.set(event, new ListenerStorage()));
  }
  deleteListeners() {
    this.clearListeners();
    this.listeners.clear();
  }
  addEventListener(type, callback, priority) {
    this.listeners.get(type).add(callback, priority);
    return () => this.removeEventListener(type, callback);
  }
  addEventListenerOnce(type, callback, priority) {
    let fnOnce = this.map.get(callback);
    if (!fnOnce) {
      fnOnce = (event) => {
        callback(event);
        this.removeEventListener(type, fnOnce);
        this.map.delete(callback);
      };
      this.map.set(callback, fnOnce);
    }
    return this.addEventListener(type, fnOnce, priority);
  }
  removeEventListener(type, callback, force) {
    this.listeners.get(type)?.remove(callback, force);
  }
  hasEventListener(type, callback) {
    return this.listeners.get(type).has(callback);
  }
  eventIsListened(eventName) {
    return this.listeners.has(eventName);
  }
  dispatchEvent(event) {
    this.listeners.get(event.type).fire(event);
  }
  clearListeners(type) {
    if (type) {
      this.listeners.get(type)?.clear();
    } else {
      this.listeners.forEach((listenerStorage) => listenerStorage.clear());
    }
  }
}

class ViewportRenderer {
  constructor(viewport) {
    this.viewport = viewport;
  }
}
class StandardViewportRenderer extends ViewportRenderer {
  constructor(viewport, toneMapping = NoToneMapping, outputEncoding = LinearEncoding) {
    super(viewport);
    this.toneMapping = toneMapping;
    this.outputEncoding = outputEncoding;
  }
  render() {
    const r = this.viewport.view.getRenderer();
    r.setViewport(this.viewport.size);
    r.setScissor(this.viewport.size);
    r.setScissorTest(true);
    r.setClearColor(this.viewport.clearColor, this.viewport.alpha);
    const toneMappingOld = r.toneMapping;
    const outputEncodingOld = r.outputEncoding;
    r.toneMapping = this.toneMapping;
    r.outputEncoding = this.outputEncoding;
    r.render(this.viewport.view.scene, this.viewport.camera);
    r.toneMapping = toneMappingOld;
    r.outputEncoding = outputEncodingOld;
  }
}

const viewportEventNames = ["cameraChanged", "updatePivotPoint", "resize", "reposition"];
class Viewport extends EventDispatcher {
  constructor(view, name, domElement, threeLayer = 0, cameraControllerProps, eventNames) {
    super(eventNames || viewportEventNames);
    this.onMutateViewport = (() => {
      const cachedSize = new Vector4();
      return () => {
        this.handleResize();
        if (cachedSize.x !== this.size.x || cachedSize.y !== this.size.y) {
          this.dispatchEvent({ type: "reposition" });
          App.Instance().dispatchEvent({ type: "render" });
        }
        cachedSize.copy(this.size);
      };
    })();
    this.view = view;
    this.name = name;
    this.size = new Vector4();
    this.domElement = domElement;
    if (!this.domElement)
      console.error(`[Viewport] Reference ${name} was not found`);
    this.cameraController = new CameraController(this, cameraControllerProps);
    this.toolController = new ToolsController(this);
    this.rect = this.domElement.getBoundingClientRect();
    this.handleResize = this.handleResize.bind(this);
    this.onResizeViewport = this.onResizeViewport.bind(this);
    this.resizeObserver = new ResizeObserver(this.onResizeViewport);
    this.mutationObserver = new MutationObserver(this.onMutateViewport);
    this.enabled = false;
    this.camera.layers.enable(threeLayer);
    this.clearColor = 0;
    this.alpha = 1;
    this.viewportRenderer = new StandardViewportRenderer(this);
  }
  init() {
    if (this.enabled)
      return;
    this.resizeObserver.observe(this.domElement);
    this.mutationObserver.observe(this.view.getContainer(), {
      attributeFilter: ["style", "class"],
      subtree: true
    });
    this.handleResize();
    this.cameraController.init();
    this.toolController.init();
    this.enabled = true;
  }
  dispose() {
    if (!this.enabled)
      return;
    this.cameraController.dispose();
    this.toolController.dispose();
    this.resizeObserver.unobserve(this.domElement);
    this.mutationObserver.disconnect();
    this.enabled = false;
  }
  setDomElement(domElement) {
    if (this.domElement === domElement)
      return;
    if (this.domElement) {
      this.resizeObserver.unobserve(this.domElement);
    }
    this.resizeObserver.observe(domElement);
    App.Instance().mouseController.redefineDomElement(domElement, this.domElement);
    this.domElement = domElement;
  }
  getDomElement() {
    return this.domElement;
  }
  handleResize() {
    this.rect = this.domElement.getBoundingClientRect();
    const { left, bottom, width, height } = this.rect;
    const { height: parentHeight, top: parentTop, left: parentLeft } = this.view.getContainer().getBoundingClientRect();
    this.size.set(left - parentLeft, parentHeight - bottom + parentTop, Math.ceil(width), Math.ceil(height));
  }
  onResizeViewport() {
    this.handleResize();
    this.dispatchEvent({ type: "resize" });
    App.Instance().dispatchEvent({ type: "render" });
  }
  get x() {
    return this.size.x;
  }
  set x(val) {
    this.size.x = val;
  }
  get y() {
    return this.size.y;
  }
  set y(val) {
    this.size.y = val;
  }
  get width() {
    return this.size.z;
  }
  set width(val) {
    this.size.z = val;
  }
  get height() {
    return this.size.w;
  }
  set height(val) {
    this.size.w = val;
  }
  get camera() {
    return this.cameraController.camera;
  }
  get cameraLayer() {
    return 31 - Math.clz32(this.camera.layers.mask);
  }
  activateMode(modeName) {
    this.toolController.activateMode(modeName);
  }
  renderImmediately() {
    this.viewportRenderer.render();
  }
  render() {
    if (this.requestID !== void 0)
      return;
    this.requestID = requestAnimationFrame(() => {
      this.renderImmediately();
      this.requestID = void 0;
    });
  }
  isEnabled() {
    return this.enabled;
  }
}

class SelectContainer extends Container {
  constructor(hostObject, objects) {
    super(hostObject, objects);
  }
  add(selectComponent) {
    if (Array.isArray(selectComponent)) {
      selectComponent.forEach((child) => {
        this.addOne(child);
      });
    } else {
      this.addOne(selectComponent);
    }
  }
  delete(selectComponent) {
    if (Array.isArray(selectComponent)) {
      selectComponent.forEach((child) => {
        this.deleteOne(child);
      });
    } else {
      this.deleteOne(selectComponent);
    }
  }
  size() {
    return this.objects.size;
  }
  addOne(selectComponent) {
    selectComponent.select();
    const layer = selectComponent.entity.getLayer();
    if (layer) {
      const selectables = this.objects.get(layer);
      if (selectables) {
        selectables.add(selectComponent);
      } else {
        this.objects.set(layer, /* @__PURE__ */ new Set([selectComponent]));
      }
    }
  }
  deleteOne(selectComponent) {
    selectComponent.deselect();
    const layer = selectComponent.entity.getLayer();
    if (layer) {
      const selectables = this.objects.get(layer);
      if (selectables) {
        selectables.delete(selectComponent);
      }
    }
  }
}

class SelectController {
  constructor() {
    this.objects = /* @__PURE__ */ new Map();
    this.container = new SelectContainer(this, this.objects);
  }
  getSelected(layer) {
    if (layer) {
      const selected = this.objects.get(layer);
      return selected ? [...selected] : [];
    } else {
      const selected = [];
      this.objects.forEach((objects) => {
        selected.push(...objects);
      });
      return selected;
    }
  }
  add(selectables) {
    this.container.add(selectables);
    App.Instance().dispatchEvent({
      type: "select",
      objects: Array.isArray(selectables) ? selectables : [selectables]
    });
  }
  delete(selectables) {
    this.container.delete(selectables);
    App.Instance().dispatchEvent({
      type: "deselect",
      objects: Array.isArray(selectables) ? selectables : [selectables]
    });
  }
  clear(layer) {
    const objects = this.getSelected(layer);
    objects?.forEach((object) => object.deselect());
    if (layer) {
      this.objects.delete(layer);
    } else {
      this.objects.clear();
    }
    App.Instance().dispatchEvent({ type: "deselect", objects });
  }
  selectedCount(layer) {
    if (layer) {
      const selected = this.objects.get(layer);
      return selected ? selected.size : 0;
    } else {
      let selectedCount = 0;
      this.objects.forEach((objects) => {
        selectedCount += objects.size;
      });
      return selectedCount;
    }
  }
  has(obj) {
    const map = this.objects;
    for (let set of map.values()) {
      if (set.has(obj)) {
        return true;
      }
    }
    return false;
  }
}

class View {
  constructor() {
    this.layers = /* @__PURE__ */ new Map();
    this.scene = new Scene();
    this.viewports = /* @__PURE__ */ new Map();
    this.toolController = new ToolsController(this);
    this.selectController = new SelectController();
    this.cameraLayers = [0];
    this.handleResize = this.handleResize.bind(this);
    this.onResizeView = this.onResizeView.bind(this);
    this.resizeObserver = new ResizeObserver(this.onResizeView);
  }
  init(props) {
    this.name = props?.name;
    this.container = props?.container || document.getElementById("container") || document.body.appendChild(document.createElement("div"));
    if (!this.container)
      console.error(`[View] Reference ${this.name} was not found`);
    this.renderer = new WebGLRenderer(props?.rendererProps);
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.canvas = this.renderer.domElement;
    this.canvas.dataset.belongsTo = this.name;
    if (!props?.rendererProps?.canvas) {
      this.container.appendChild(this.canvas);
      this.canvas.style.width = "100%";
      this.canvas.style.height = "100%";
    }
    this.handleResize();
    this.toolController.init();
    this.resizeObserver.observe(this.container);
    App.Instance().addEventListener("resize", this.handleResize, 1);
    App.Instance().dispatchEvent({ type: "render" });
    this.container.addEventListener("contextmenu", (event) => event.preventDefault(), false);
  }
  dispose() {
    this.toolController.dispose();
    this.selectController.clear();
    this.clearLayers();
    this.viewports.forEach((viewport) => {
      viewport.dispose();
    });
    if (this.container)
      this.resizeObserver.unobserve(this.container);
    App.Instance().removeEventListener("resize", this.handleResize);
  }
  clear() {
    this.dispose();
    this.viewports.forEach((viewport) => {
      this.deleteViewport(viewport.name);
    });
    this.layers.forEach((layer) => {
      this.removeLayer(layer.getName());
    });
    this.toolController.clear();
  }
  clearLayers() {
    if (!this.layers.size)
      return;
    const layers = this.getLayers();
    for (const layer of layers) {
      layer.getObjects()?.forEach((obj) => obj.dispose());
      layer.clear();
    }
    App.Instance().dispatchEvent({ type: "render" });
  }
  getRenderer() {
    return this.renderer;
  }
  getContainer() {
    return this.container;
  }
  getCanvas() {
    return this.canvas;
  }
  onResizeView() {
    this.handleResize();
  }
  handleResize() {
    const width = this.container.clientWidth || 0;
    const height = this.container.clientHeight || 0;
    this.renderer.setSize(width, height, true);
  }
  addViewport(name, domElement, cameraControllerProps, viewportEvents) {
    const viewport = new Viewport(this, name, domElement, this.getEmptyCameraLayer(), cameraControllerProps, viewportEvents);
    this.viewports.set(name, viewport);
    return viewport;
  }
  deleteViewport(name) {
    const viewport = this.viewports.get(name);
    if (viewport) {
      const layer = viewport.cameraLayer;
      if (layer !== 0) {
        this.cameraLayers[layer] = void 0;
      }
      viewport.dispose();
      this.viewports.delete(name);
    }
  }
  getEmptyCameraLayer() {
    for (let i = 1; i < 32; i++) {
      if (!this.cameraLayers[i]) {
        this.cameraLayers[i] = i;
        return i;
      }
    }
  }
  getViewport(name) {
    return this.viewports.get(name);
  }
  addLayer(name, objects) {
    let layer = this.layers.get(name);
    if (layer) {
      objects && layer.add(objects);
      return layer;
    }
    layer = new Layer(this, name, objects);
    this.layers.set(name, layer);
    this.scene.add(layer.getGroup());
    App.Instance().dispatchEvent({ type: "addLayer", container: layer });
    return layer;
  }
  removeLayer(name) {
    const layer = this.layers.get(name);
    if (!layer)
      return;
    layer.getObjects()?.forEach((obj) => obj.dispose());
    layer.clear();
    this.scene.remove(layer.getGroup());
    this.layers.delete(name);
    App.Instance().dispatchEvent({ type: "deleteLayer", container: layer });
  }
  getLayer(name) {
    if (!this.layers.has(name))
      console.warn("getLayer: no layer=", name);
    return this.layers.get(name);
  }
  *getLayers() {
    for (const layer of this.layers) {
      yield layer[1];
    }
  }
  *getVisibleLayers() {
    for (const layer of this.layers) {
      if (layer[1].getGroup().visible) {
        yield layer[1];
      }
    }
  }
  activateMode(modeName) {
    const previous = this.toolController.getModeName();
    if (previous === modeName)
      return;
    this.toolController.activateMode(modeName);
    this.viewports.forEach((viewport) => viewport.activateMode(modeName));
    const mode = this.toolController.getModeName();
    App.Instance().dispatchEvent({ type: "modeChanged", previous: previous !== void 0 ? previous : "", mode: mode !== void 0 ? mode : "" });
  }
  setActiveViewport(viewportName) {
    const viewport = typeof viewportName === "undefined" ? viewportName : this.viewports.get(viewportName);
    if (this.activeViewport === viewport)
      return;
    App.Instance().dispatchEvent({ type: "ActiveViewportWillChange", current: this.activeViewport, next: viewport });
    const previousViewport = this.activeViewport;
    this.activeViewport = viewport;
    App.Instance().dispatchEvent({ type: "ActiveViewportChanged", previous: previousViewport, current: viewport });
  }
  getActiveViewport() {
    return this.activeViewport;
  }
}

const undoStackEventNames = ["add", "undo", "redo", "clear"];
class UndoStack extends EventDispatcher {
  constructor() {
    super(undoStackEventNames);
    this.stack = [];
    this.index = -1;
    this.lastIndex = -1;
  }
  add(command) {
    if (this.index !== this.lastIndex) {
      this.stack.splice(this.index + 1);
    }
    this.stack.push(command);
    this.index++;
    this.lastIndex = this.index;
    this.dispatchEvent({ type: "add" });
  }
  getCommandList() {
    return [...this.stack];
  }
  removeLast() {
    this.stack.pop();
    this.index--;
    this.lastIndex = this.index;
  }
  undo() {
    if (this.index >= 0) {
      this.stack[this.index].undo();
      this.index--;
      this.dispatchEvent({ type: "undo" });
    }
  }
  redo() {
    if (this.index !== this.lastIndex) {
      this.stack[this.index + 1].execute();
      this.index++;
      this.dispatchEvent({ type: "redo" });
    }
  }
  undoAll() {
    do {
      this.undo();
    } while (this.index >= 0);
  }
  getIndex() {
    return this.index;
  }
  getLastIndex() {
    return this.lastIndex;
  }
  clear() {
    this.stack = [];
    this.index = -1;
    this.lastIndex = -1;
    this.dispatchEvent({ type: "clear" });
  }
}

const mouseButtons = ["left", "middle", "right"];
const anyButtonEventTypesArr = ["pointerdown", "pointerup"];
const specialEventTypesArr = ["click", "dblclick", "contextmenu", "pointermove", "pointerenter", "pointerleave", "pointerout", "pointerover", "wheel"];
const isSpecialEventType = (type) => {
  return specialEventTypesArr.some((el) => el === type);
};
class PointerEventsStorage {
  constructor(element = document) {
    this.element = element;
    this.listeners = {};
    this.callbacks = {};
  }
  init() {
    anyButtonEventTypesArr.forEach((type) => {
      this.listeners[type] = {
        left: new ListenerStorage(),
        middle: new ListenerStorage(),
        right: new ListenerStorage()
      };
      this.callbacks[type] = {
        callback: (event) => this.listeners[type][mouseButtons[event.button]].fire(event),
        isListening: false
      };
    });
    specialEventTypesArr.forEach((type) => {
      this.listeners[type] = new ListenerStorage();
      this.callbacks[type] = {
        callback: (event) => this.listeners[type].fire(event),
        isListening: false
      };
    });
  }
  dispose() {
    if (this.listeners) {
      this.clear();
      Object.keys(this.listeners).forEach((key) => delete this.listeners[key]);
    }
  }
  changeDomElement(element) {
    const oldDomElement = this.element;
    this.element = element;
    anyButtonEventTypesArr.forEach((eventType) => {
      oldDomElement.removeEventListener(eventType, this.callbacks[eventType].callback, false);
      this.callbacks[eventType].isListening = false;
      this.startListening(eventType);
    });
    specialEventTypesArr.forEach((eventType) => {
      oldDomElement.removeEventListener(eventType, this.callbacks[eventType].callback, false);
      this.callbacks[eventType].isListening = false;
      this.startListening(eventType);
    });
  }
  addAnyButtonListener(eventType, button, listener, priority, force) {
    this.listeners[eventType][button].add(listener, priority, force);
    this.startListening(eventType);
  }
  addSpecialEventListeners(eventType, listener, priority, force) {
    this.listeners[eventType].add(listener, priority, force);
    this.startListening(eventType);
  }
  removeButtonListener(eventType, button, listener, force) {
    if (this.listeners) {
      this.listeners[eventType][button].remove(listener, force);
      this.stopListening(eventType);
    }
  }
  removeMoveAndWheelListener(eventType, listener, force) {
    if (this.listeners) {
      this.listeners[eventType].remove(listener, force);
      this.stopListening(eventType);
    }
  }
  startListening(eventType) {
    if (this.callbacks[eventType].callback && !this.callbacks[eventType].isListening) {
      if (!this.isEmpty(eventType)) {
        this.callbacks[eventType].isListening = true;
        this.element.addEventListener(eventType, this.callbacks[eventType].callback, { capture: false, passive: false });
      }
    }
  }
  stopListening(eventType) {
    if (this.callbacks[eventType].callback && this.callbacks[eventType].isListening) {
      if (this.isEmpty(eventType)) {
        this.callbacks[eventType].isListening = false;
        this.element.removeEventListener(eventType, this.callbacks[eventType].callback, false);
      }
    }
  }
  clear() {
    anyButtonEventTypesArr.forEach((eventType) => {
      Object.values(this.listeners[eventType]).forEach((value) => value.clear());
      this.stopListening(eventType);
    });
    specialEventTypesArr.forEach((eventType) => {
      this.listeners[eventType].clear();
      this.stopListening(eventType);
    });
  }
  isEmpty(eventType) {
    if (isSpecialEventType(eventType)) {
      return this.listeners[eventType].isEmpty();
    }
    return Object.values(this.listeners[eventType]).every((value) => value.isEmpty());
  }
  has(eventType, listener, button) {
    if (this.listeners[eventType] instanceof ListenerStorage) {
      return this.listeners[eventType].has(listener);
    } else if (button) {
      return this.listeners[eventType][button].has(listener);
    }
    return false;
  }
  disableButton(eventType, button, listener) {
    if (eventType === "click" || eventType === "dblclick" || eventType === "contextmenu") {
      this.listeners[eventType].disable(listener);
    } else if (button && typeof button !== "function") {
      this.listeners[eventType][button].disable(listener);
    }
  }
  enableButton(eventType, button, listener) {
    if (eventType === "click" || eventType === "dblclick" || eventType === "contextmenu") {
      this.listeners[eventType].enable(listener);
    } else if (button && typeof button !== "function") {
      this.listeners[eventType][button].enable(listener);
    }
  }
  disableButtonExcept(eventType, listener, button) {
    if (eventType === "click" || eventType === "dblclick" || eventType === "contextmenu") {
      this.listeners[eventType].disableExcept(listener);
    } else if (button && typeof button !== "function") {
      this.listeners[eventType][button].disableExcept(listener);
    }
  }
  enableButtonExcept(eventType, listener, button) {
    if (eventType === "click" || eventType === "dblclick" || eventType === "contextmenu") {
      this.listeners[eventType].enableExcept(listener);
    } else if (button && typeof button !== "function") {
      this.listeners[eventType][button].enableExcept(listener);
    }
  }
  disableWheel(listener) {
    this.listeners["wheel"].disable(listener);
  }
  enableWheel(listener) {
    this.listeners["wheel"].enable(listener);
  }
  disableMove(listener) {
    this.listeners["pointermove"].disable(listener);
  }
  enableMove(listener) {
    this.listeners["pointermove"].enable(listener);
  }
  disableMoveExcept(listeners) {
    this.listeners["pointermove"].disableExcept(listeners);
  }
  enableMoveExcept(listeners) {
    this.listeners["pointermove"].enableExcept(listeners);
  }
}

class PointerController {
  constructor() {
    this.listeners = (/* @__PURE__ */ new Map()).set(document, new PointerEventsStorage());
  }
  init() {
    this.listeners.forEach((val) => val.init());
  }
  dispose() {
    this.listeners.forEach((val) => val.dispose());
  }
  clear() {
    this.dispose();
    this.listeners.clear();
  }
  redefineDomElement(newElement, oldElement) {
    const oldElementListeners = this.listeners.get(oldElement);
    if (oldElementListeners) {
      oldElementListeners.changeDomElement(newElement);
      this.listeners.set(newElement, oldElementListeners);
      this.listeners.delete(oldElement);
    }
  }
  addListener(eventType, listener, element = document, button, priority, force) {
    let mouseEventListeners = this.listeners.get(element);
    if (!mouseEventListeners) {
      mouseEventListeners = new PointerEventsStorage(element);
      this.listeners.set(element, mouseEventListeners);
      mouseEventListeners.init();
    }
    if (isSpecialEventType(eventType)) {
      if ((!button || typeof button === "number") && typeof priority !== "number") {
        mouseEventListeners.addSpecialEventListeners(eventType, listener, button, priority);
        return () => this.removeListener(eventType, listener, element);
      } else {
        console.error("listener is not added. Event type: " + eventType);
      }
    } else if (button !== void 0) {
      if (typeof button !== "number" && typeof priority !== "boolean") {
        mouseEventListeners.addAnyButtonListener(eventType, button, listener, priority, force);
        return () => this.removeListener(eventType, listener, element, button);
      } else {
        console.error("listener is not added. Event type: " + eventType + ", button: " + button);
      }
    } else {
      console.error("listener is not added. Event type: " + eventType + ", button: " + button);
    }
  }
  removeListener(eventType, listener, element = document, button, force = false) {
    let mouseEventListeners;
    if (element) {
      mouseEventListeners = this.listeners.get(element);
    }
    if (mouseEventListeners) {
      if (isSpecialEventType(eventType)) {
        if (typeof button === "boolean" || typeof button === "undefined") {
          mouseEventListeners.removeMoveAndWheelListener(eventType, listener, button);
        }
      } else if (button !== void 0 && typeof button !== "boolean") {
        mouseEventListeners.removeButtonListener(eventType, button, listener, force);
      }
    } else {
      console.warn("listener is not found");
    }
  }
}

const keyBoardEventTypesArr = ["keydown", "keyup", "keypress"];
class KeyboardController {
  init() {
    this.listeners = {
      keydown: /* @__PURE__ */ new Map(),
      keyup: /* @__PURE__ */ new Map(),
      keypress: /* @__PURE__ */ new Map()
    };
    this.callbacks = {};
    keyBoardEventTypesArr.forEach((type) => {
      this.callbacks[type] = {
        callback: (event) => this.listeners[type].get(event.code)?.fire(event),
        isListening: true
      };
    });
  }
  dispose() {
    if (this.listeners) {
      keyBoardEventTypesArr.forEach((eventType) => {
        this.listeners[eventType].forEach(
          (ListenerStorage2) => ListenerStorage2.clear()
        );
        this.listeners[eventType].clear();
        this.stopListening(eventType);
      });
    }
  }
  addListener(eventType, listener, eventCode, priority) {
    const listenerStorage = this.listeners[eventType].get(eventCode);
    if (listenerStorage) {
      listenerStorage.add(listener, priority);
    } else {
      const storage = new ListenerStorage();
      storage.add(listener, priority);
      this.listeners[eventType].set(eventCode, storage);
    }
    this.startListening(eventType);
  }
  removeListener(eventType, listener, code) {
    const listenerStorage = this.listeners[eventType].get(code);
    if (listenerStorage) {
      listenerStorage.remove(listener);
      this.stopListening(eventType);
    }
  }
  disableKeys(keys) {
    if (keys) {
      Object.values(this.listeners).forEach((item) => keys.forEach((key) => item.get(key)?.disable()));
    } else {
      Object.values(this.listeners).forEach((item) => item.forEach((ListenerStorage2) => ListenerStorage2.disable()));
    }
  }
  enableKeys(keys) {
    if (keys) {
      Object.values(this.listeners).forEach((item) => keys.forEach((key) => item.get(key)?.enable()));
    } else {
      Object.values(this.listeners).forEach((item) => item.forEach((ListenerStorage2) => ListenerStorage2.enable()));
    }
  }
  startListening(eventType) {
    if (!this.isEmpty(eventType)) {
      document.addEventListener(eventType, this.callbacks[eventType].callback, false);
    }
  }
  stopListening(eventType) {
    if (this.callbacks[eventType].isListening) {
      if (this.isEmpty(eventType)) {
        this.callbacks[eventType].isListening = false;
        document.removeEventListener(eventType, this.callbacks[eventType].callback, false);
      }
    }
  }
  isEmpty(eventType) {
    return this.listeners[eventType].size === 0;
  }
}

class Component {
  constructor(entity) {
    this.entity = entity;
  }
}

class Raycast extends Component {
  constructor(entity) {
    super(entity);
  }
  isRaycast(raycaster, recursive = true) {
    const activeViewport = App.Instance().getActiveView().getActiveViewport();
    if (activeViewport) {
      raycaster.layers.mask = activeViewport.camera.layers.mask;
      const intersections = raycaster.intersectObject(this.entity.object3d, recursive);
      return intersections.length ? intersections : false;
    }
    return false;
  }
  *intersect(raycaster) {
    if (!this.entity.object3d.visible)
      return;
    const intersections = this.isRaycast(raycaster, true);
    if (intersections) {
      yield { raycast: this, intersections };
    }
  }
}
class RaycastChildrenOnly extends Raycast {
  *intersect(raycaster) {
    for (const child of this.entity.children) {
      const raycast = child.getComponent(Raycast);
      if (raycast) {
        yield* raycast.intersect(raycaster);
      }
    }
  }
}

var EventPriority = /* @__PURE__ */ ((EventPriority2) => {
  EventPriority2[EventPriority2["None"] = 0] = "None";
  EventPriority2[EventPriority2["Tool"] = 10] = "Tool";
  EventPriority2[EventPriority2["RaycastController"] = 20] = "RaycastController";
  EventPriority2[EventPriority2["CursorController"] = 30] = "CursorController";
  return EventPriority2;
})(EventPriority || {});

class RaycastController {
  get view() {
    return App.Instance().getActiveView();
  }
  get position() {
    return App.Instance().cursorController.position;
  }
  constructor() {
    this.raycaster = new Raycaster();
    this.raycaster.params.Line = { threshold: 0.01 };
    this.state = {
      transformObject: false,
      transformCamera: false
    };
    this.handleChangeActiveViewport = this.handleChangeActiveViewport.bind(this);
    this.onTransformStart = this.onTransformStart.bind(this);
    this.onTransformEnd = this.onTransformEnd.bind(this);
    this.onTransformCameraStart = this.onTransformCameraStart.bind(this);
    this.onTransformCameraEnd = this.onTransformCameraEnd.bind(this);
    this.onTransformCamera = this.onTransformCamera.bind(this);
    this.update = this.update.bind(this);
  }
  init() {
    this.changeListenActiveViewport(this.viewport, this.view.getActiveViewport());
    App.Instance().addEventListener("ActiveViewportWillChange", this.handleChangeActiveViewport);
    App.Instance().addEventListener("transformStart", this.onTransformStart);
    App.Instance().addEventListener("transformCamera", this.onTransformCamera);
    App.Instance().addEventListener("transformCameraStart", this.onTransformCameraStart);
  }
  dispose() {
    this.changeListenActiveViewport(this.viewport);
    App.Instance().removeEventListener("ActiveViewportWillChange", this.handleChangeActiveViewport);
    App.Instance().removeEventListener("transformStart", this.onTransformStart);
    App.Instance().removeEventListener("transformEnd", this.onTransformEnd);
    App.Instance().removeEventListener("transformCamera", this.onTransformCamera);
    App.Instance().removeEventListener("transformCameraStart", this.onTransformCameraStart);
    this.raycastData = void 0;
    this.object = void 0;
  }
  changeListenActiveViewport(currentViewport, viewport) {
    if (currentViewport) {
      App.Instance().mouseController.removeListener("pointermove", this.update, currentViewport.getDomElement(), true);
      App.Instance().mouseController.removeListener("pointerdown", this.update, currentViewport.getDomElement(), "left", true);
      currentViewport.removeEventListener("resize", this.update);
      currentViewport.removeEventListener("reposition", this.update);
    }
    if (viewport) {
      App.Instance().mouseController.addListener("pointermove", this.update, viewport.getDomElement(), EventPriority.RaycastController);
      App.Instance().mouseController.addListener("pointerdown", this.update, viewport.getDomElement(), "left", EventPriority.RaycastController);
      viewport.addEventListener("resize", this.update);
      viewport.addEventListener("reposition", this.update);
      this.updateLineThreshold(viewport);
    } else if (this.object) {
      const previousObject = this.object;
      this.object = void 0;
      App.Instance().dispatchEvent({ type: "unhover", entity: previousObject });
    }
    this.viewport = viewport;
  }
  start() {
    this.changeListenActiveViewport(this.viewport, this.view.getActiveViewport());
    this.update();
  }
  stop() {
    this.changeListenActiveViewport(this.viewport);
  }
  handleChangeActiveViewport(event) {
    this.changeListenActiveViewport(this.viewport, event.next);
  }
  updateLineThreshold(viewport) {
    const zoom = viewport.cameraController.camera.zoom;
    this.raycaster.params.Line.threshold = 60 / zoom;
  }
  onTransformCamera(event) {
    if (event.viewport === this.viewport)
      this.updateLineThreshold(this.viewport);
  }
  onTransformCameraStart() {
    if (!this.state.transformCamera && !this.state.transformObject) {
      this.stop();
    }
    App.Instance().addEventListener("transformCameraEnd", this.onTransformCameraEnd);
    this.state.transformCamera = true;
  }
  onTransformCameraEnd() {
    this.state.transformCamera = false;
    if (!this.state.transformObject) {
      this.start();
    }
    App.Instance().removeEventListener("transformCameraEnd", this.onTransformCameraEnd);
  }
  onTransformStart() {
    if (!this.state.transformCamera && !this.state.transformObject) {
      this.stop();
    }
    App.Instance().addEventListener("transformEnd", this.onTransformEnd);
    this.state.transformObject = true;
  }
  onTransformEnd() {
    this.state.transformObject = false;
    if (!this.state.transformCamera) {
      this.start();
    }
    App.Instance().removeEventListener("transformEnd", this.onTransformEnd);
  }
  getNearest() {
    const generator = this.raycast();
    const results = [];
    for (const intersection of generator) {
      intersection.intersections.sort((a, b) => a.distance - b.distance);
      results.push(intersection);
    }
    if (results.length) {
      results.sort((a, b) => a.intersections[0].distance - b.intersections[0].distance);
      return results[0];
    }
  }
  *raycast() {
    this.raycaster.setFromCamera(this.position, this.viewport.camera);
    for (const layer of this.view.getLayers()) {
      const objects = layer.getComponent(Raycast);
      if (objects) {
        for (const object of objects) {
          const generator = object.intersect(this.raycaster);
          for (const intersection of generator) {
            yield intersection;
          }
        }
      }
    }
  }
  *raycastObject(object) {
    this.raycaster.setFromCamera(this.position, this.viewport.camera);
    let generator;
    if (object instanceof Raycast) {
      generator = object.intersect(this.raycaster);
    } else {
      const raycast = object.getComponent(Raycast);
      if (!raycast)
        return;
      generator = raycast.intersect(this.raycaster);
    }
    for (const intersection of generator) {
      yield intersection;
    }
  }
  getPositionOnZeroPlane(v) {
    this.raycaster.setFromCamera(this.position, this.viewport.camera);
    const distance = -this.raycaster.ray.origin.z / this.raycaster.ray.direction.z;
    v.x = this.raycaster.ray.origin.x + this.raycaster.ray.direction.x * distance;
    v.y = this.raycaster.ray.origin.y + this.raycaster.ray.direction.y * distance;
    v.z = 0;
    return v;
  }
  update() {
    this.raycastData = this.getNearest();
    const previousObject = this.object;
    this.object = this.raycastData?.raycast.entity;
    if (previousObject !== this.object) {
      if (previousObject)
        App.Instance().dispatchEvent({ type: "unhover", entity: previousObject });
      if (this.raycastData)
        App.Instance().dispatchEvent({ type: "hover", entity: this.raycastData.raycast.entity });
    }
  }
  getObject() {
    return this.object;
  }
}

class CursorController {
  constructor() {
    this.position = new Vector2(0, 0);
    this.clientPosition = new Vector2(0, 0);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleChangePosition = this.handleChangePosition.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.onChangeActiveViewport = this.onChangeActiveViewport.bind(this);
  }
  init() {
    App.Instance().addEventListener("ActiveViewportWillChange", this.onChangeActiveViewport);
    App.Instance().mouseController.addListener("pointermove", this.handleMouseMove, document);
    if (this.viewport) {
      this.viewport.addEventListener("resize", this.handleResize, EventPriority.CursorController);
      this.viewport.addEventListener("reposition", this.handleResize, EventPriority.CursorController);
      App.Instance().mouseController.addListener("pointermove", this.handleChangePosition, this.viewport.getDomElement(), EventPriority.CursorController);
      App.Instance().mouseController.addListener("pointerdown", this.handleChangePosition, this.viewport.getDomElement(), "left", EventPriority.CursorController);
    }
  }
  dispose() {
    App.Instance().removeEventListener("ActiveViewportWillChange", this.onChangeActiveViewport);
    App.Instance().mouseController.removeListener("pointermove", this.handleMouseMove, document);
    if (this.viewport) {
      App.Instance().mouseController.removeListener("pointermove", this.handleChangePosition, this.viewport.getDomElement());
      App.Instance().mouseController.removeListener("pointerdown", this.handleChangePosition, this.viewport.getDomElement(), "left");
      this.viewport.removeEventListener("resize", this.handleResize);
      this.viewport.removeEventListener("reposition", this.handleResize);
    }
  }
  get view() {
    return App.Instance().getActiveView();
  }
  get viewport() {
    return this.view.getActiveViewport();
  }
  onChangeActiveViewport(event) {
    event.current?.removeEventListener("resize", this.handleResize);
    event.next?.addEventListener("resize", this.handleResize, EventPriority.CursorController);
    event.current?.removeEventListener("reposition", this.handleResize);
    event.next?.addEventListener("reposition", this.handleResize, EventPriority.CursorController);
    if (event.current) {
      App.Instance().mouseController.removeListener("pointermove", this.handleChangePosition, event.current.getDomElement());
      App.Instance().mouseController.removeListener("pointerdown", this.handleChangePosition, event.current.getDomElement(), "left");
    }
    if (event.next) {
      App.Instance().mouseController.addListener("pointermove", this.handleChangePosition, event.next.getDomElement(), EventPriority.CursorController);
      App.Instance().mouseController.addListener("pointerdown", this.handleChangePosition, event.next.getDomElement(), "left", EventPriority.CursorController);
    }
  }
  setPosition(x, y) {
    this.position.set(
      (x - this.viewport.rect.left) / this.viewport.rect.width * 2 - 1,
      -(y - this.viewport.rect.top) / this.viewport.rect.height * 2 + 1
    );
  }
  handleChangePosition(event) {
    this.setPosition(event.clientX, event.clientY);
  }
  handleMouseMove(event) {
    this.clientPosition.set(event.clientX, event.clientY);
  }
  handleResize() {
    this.setPosition(this.clientPosition.x, this.clientPosition.y);
  }
}

class App extends EventDispatcher {
  constructor() {
    super();
    this.inited = false;
    this.view = new View();
    this.activeView = this.view;
    this.views = {};
    this.mouseController = new PointerController();
    this.keyboardController = new KeyboardController();
    this.raycastController = new RaycastController();
    this.cursorController = new CursorController();
    this.undoStack = new UndoStack();
    this.handleResize = this.handleResize.bind(this);
    this.resources = /* @__PURE__ */ new Map();
    this.startupSystems = [];
  }
  static Instance() {
    return this.instance ?? (this.instance = new App());
  }
  init(props) {
    this.mouseController.init();
    this.keyboardController.init();
    this.raycastController.init();
    this.cursorController.init();
    this.view.init(props);
    window.addEventListener("resize", this.handleResize, false);
    if (props?.container) {
      this.resizeObserver = new ResizeObserver(this.handleResize);
      this.resizeObserver.observe(props.container);
    }
    this.inited = true;
  }
  dispose() {
    if (!this.inited)
      return;
    this.view.dispose();
    for (const key in this.views) {
      this.views[key].dispose();
    }
    this.cursorController.dispose();
    this.raycastController.dispose();
    this.keyboardController.dispose();
    this.mouseController.dispose();
    this.undoStack.clearListeners();
    this.clearListeners();
    window.removeEventListener("resize", this.handleResize, false);
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = void 0;
    }
    this.inited = false;
  }
  clear() {
    this.dispose();
    this.view.clear();
    for (const key in this.views) {
      this.views[key].clear();
    }
    this.deleteListeners();
    this.undoStack.clear();
  }
  async runStartups() {
    for (let i = 0, il = this.startupSystems.length; i < il; i++) {
      const system = this.startupSystems[i];
      if (system.prototype?.constructor) {
        await new system().start();
      } else {
        await system();
      }
      this.dispatchEvent({ type: "applicationProgress", progress: (i + 1) / il });
    }
  }
  executeCommand(command, immediately = true) {
    if (immediately) {
      if (command.execute()) {
        this.undoStack.add(command);
      }
    } else {
      this.undoStack.add(command);
    }
  }
  getActiveView() {
    return this.activeView;
  }
  setActiveView(view) {
    if (this.activeView === view) {
      return;
    }
    this.activeView.setActiveViewport(void 0);
    this.activeView = view;
  }
  addResources(name, resource) {
    this.resources.set(name, resource);
    this.dispatchEvent({ type: "resourceAdded", name });
  }
  getResources(name) {
    return this.resources.get(name);
  }
  handleResize() {
    this.dispatchEvent({ type: "resize" });
  }
}

class ViewportComponent extends Component {
  constructor(entity) {
    super(entity);
    this.addViewport = (name, recursive = true) => {
      const viewport = name instanceof Viewport ? name : App.Instance().view.getViewport(name);
      if (viewport) {
        const cameraLayer = viewport.cameraLayer;
        if (cameraLayer) {
          this.viewports.add(viewport);
          this.entity.object3d.layers.enable(cameraLayer);
          this.entity.object3d.layers.disable(0);
          if (recursive) {
            this.entity.children.forEach((entity) => {
              const component = entity.getComponent(ViewportComponent);
              component?.addViewport(name, true);
            });
          }
        }
      }
    };
    this.deleteViewport = (name, recursive = true) => {
      const viewport = name instanceof Viewport ? name : App.Instance().view.getViewport(name);
      if (viewport) {
        const cameraLayer = viewport.cameraLayer;
        if (cameraLayer) {
          this.viewports.delete(viewport);
          this.entity.object3d.layers.disable(cameraLayer);
          if (recursive) {
            this.entity.children.forEach((entity) => {
              const component = entity.getComponent(ViewportComponent);
              component?.deleteViewport(name, true);
            });
          }
        }
      }
    };
    this.viewports = /* @__PURE__ */ new Set();
  }
  deleteAllViewports(recursive = true) {
    this.viewports.clear();
    this.entity.object3d.layers.set(0);
    if (recursive) {
      this.entity.children.forEach((entity) => {
        const component = entity.getComponent(ViewportComponent);
        component?.deleteAllViewports(true);
      });
    }
  }
}

const viewer2DLayerNames = ["image", "roundCorners", "conditionBoxes", "mask"];
const Viewer2D = App.Instance();

class EntityContainer extends Container {
  constructor(parent, objects = []) {
    super(parent, objects);
  }
  size() {
    return this.objects.length;
  }
  add(entity) {
    if (Array.isArray(entity)) {
      const added = entity.filter((child) => {
        this.addOne(child);
      });
      App.Instance().dispatchEvent({ type: "add", entities: added, container: this.hostObject });
    } else {
      if (this.addOne(entity)) {
        App.Instance().dispatchEvent({ type: "add", entities: [entity], container: this.hostObject });
      }
    }
  }
  delete(entity) {
    if (Array.isArray(entity)) {
      const deleted = entity.filter((child) => {
        this.deleteOne(child);
      });
      App.Instance().dispatchEvent({ type: "delete", entities: deleted, container: this.hostObject });
    } else {
      if (this.deleteOne(entity)) {
        App.Instance().dispatchEvent({ type: "delete", entities: [entity], container: this.hostObject });
      }
    }
  }
  addOne(entity) {
    if (entity.parent !== this.hostObject) {
      if (entity.parent) {
        entity.parent.delete(entity);
      }
      this.objects.push(entity);
      entity.parent = this.hostObject;
      this.hostObject.object3d?.add(entity.object3d);
      return true;
    }
    return false;
  }
  deleteOne(entity) {
    if (entity.parent === this.hostObject) {
      const index = this.hostObject.children.indexOf(entity);
      if (index >= 0) {
        this.hostObject.children.splice(index, 1);
      }
      entity.parent = void 0;
      this.hostObject.object3d?.remove(entity.object3d);
      return true;
    }
    return false;
  }
}

const entityEventNames = ["transform", "update", "delete", "add"];
class Entity extends EventDispatcher {
  constructor(object3D) {
    super(entityEventNames);
    this.object3d = object3D;
    this.children = [];
    this.container = new EntityContainer(this, this.children);
    this.components = /* @__PURE__ */ new Map();
    this.uuid = MathUtils.generateUUID();
  }
  add(children) {
    this.container.add(children);
  }
  delete(children) {
    this.container.delete(children);
  }
  getBaseClass(component) {
    const prototype = Object.getPrototypeOf(component);
    if (prototype === Component) {
      return component;
    } else {
      return this.getBaseClass(prototype);
    }
  }
  getComponent(type) {
    return this.components.get(type);
  }
  setComponent(component, instance) {
    const BaseConcreteClass = this.getBaseClass(component);
    if (instance) {
      this.components.set(BaseConcreteClass, instance);
    } else if (component) {
      const instance2 = new component(this);
      !this.components.has(component) && this.components.set(BaseConcreteClass, instance2);
      return instance2;
    }
  }
  removeComponents() {
    this.components.clear();
  }
  getLayer() {
    return this.layer || this.parent?.getLayer();
  }
  setLayer(layer) {
    if (!this.layer?.has(this)) {
      if (layer?.has(this)) {
        this.layer = layer;
      } else {
        this.layer = void 0;
      }
    }
  }
  dispose() {
    this.object3d.traverse((obj) => {
      if (obj.geometry) {
        obj.geometry.dispose();
      }
      if (obj.material) {
        if (obj.material instanceof Material) {
          obj.material.dispose();
        } else if (Array.isArray(obj.material)) {
          obj.material.forEach((material) => material instanceof Material && material.dispose());
        }
      }
    });
    this.object3d.clear();
  }
}

class Primitive extends Entity {
  constructor(object3D, viewport) {
    super(object3D);
    const viewportComponent = this.setComponent(ViewportComponent);
    viewport && viewportComponent.addViewport(viewport);
  }
  getViewportSize() {
    return this.getViewport()?.size;
  }
  getViewport() {
    const viewports = this.getComponent(ViewportComponent)?.viewports;
    return viewports && viewports.size ? [...viewports][0] : void 0;
  }
}

const _Observer = class {
  constructor(object, property) {
    this.listeners = /* @__PURE__ */ new Set();
    this.object = object;
    this.property = property;
  }
  static observe(object, property, cb) {
    let map = _Observer.observables.get(object);
    let observer = map?.get(property);
    if (!observer) {
      observer = new _Observer(object, property);
      observer.listeners.add(cb);
      observer.value = object[property];
      Object.defineProperty(object, property, {
        writable: true,
        configurable: true,
        enumerable: true
      });
      Object.defineProperty(object, property, {
        set(x) {
          observer.value = x;
          observer.listeners.forEach((listener) => listener(x));
        },
        get() {
          return observer.value;
        }
      });
      if (map) {
        map.set(property, observer);
      } else {
        _Observer.observables.set(object, /* @__PURE__ */ new Map([[property, observer]]));
      }
    }
    observer.listeners.add(cb);
    return () => _Observer.stopObserving(object, property, cb);
  }
  static stopObserving(object, property, cb) {
    const map = _Observer.observables.get(object);
    const observer = map?.get(property);
    if (!map || !observer) {
      return;
    }
    if (cb) {
      observer.listeners.delete(cb);
      if (observer.listeners.size) {
        return;
      }
    }
    Object.defineProperty(object, property, { value: observer.value });
    map.delete(property);
    if (!map.size) {
      _Observer.observables.delete(object);
    }
  }
};
let Observer = _Observer;
Observer.observables = /* @__PURE__ */ new Map();

const vector$1 = new Vector2();
class LineLikeEntity extends Primitive {
  constructor(props, type, viewport) {
    let lineWidth;
    let color;
    if (typeof props === "function") {
      super(new props(), type);
      lineWidth = 2;
      color = new Color(16777215);
    } else {
      super(new type(), viewport);
      lineWidth = props.width;
      this.object3d.renderOrder = props.renderOrder || 0;
      color = new Color(props.color || 16777215);
    }
    if (this.object3d instanceof Line2) {
      this.object3d.geometry = new LineGeometry();
      this.object3d.material = new LineMaterial({ linewidth: lineWidth, vertexColors: false, dashed: false, worldUnits: false });
      this.onResize = this.onResize.bind(this);
      this.updateResolution = this.updateResolution.bind(this);
      this.updateResolution();
      Observer.observe(this.object3d.layers, "mask", this.updateResolution);
    } else {
      this.object3d.geometry.setAttribute("position", new BufferAttribute(new Float32Array([]), 3));
    }
    this.object3d.material.color = color;
  }
  update() {
    if (this.object3d instanceof Line) {
      this.updateLine();
    } else {
      this.updateLine2();
    }
  }
  updateResolution() {
    const bitCount = this.object3d.layers.mask.toString(2).match(/1/g)?.length;
    const isEntityInOneViewport = this.object3d.layers.mask !== 1 && bitCount === 1;
    if (isEntityInOneViewport) {
      this.object3d.onAfterRender = () => {
      };
      this.onResize();
      const [firstViewport] = this.getComponent(ViewportComponent).viewports;
      firstViewport.addEventListener("resize", this.onResize);
    } else {
      const [firstViewport] = this.getComponent(ViewportComponent).viewports;
      firstViewport?.removeEventListener("resize", this.onResize);
      this.object3d.onAfterRender = () => {
        const size = this.getLayer()?.getView().getRenderer().getSize(vector$1);
        if (size) {
          this.object3d.material.resolution.set(size.x, size.y);
        }
      };
    }
  }
  onResize() {
    const [firstViewport] = this.getComponent(ViewportComponent).viewports;
    const { z, w } = firstViewport.size;
    this.object3d.material.resolution.set(z, w);
  }
  toJson() {
    return {
      color: this.object3d.material.color,
      width: this.object3d.material.linewidth
    };
  }
}

class Polyline extends LineLikeEntity {
  constructor(props, type, viewport) {
    var __super = (...args) => {
      super(...args);
    };
    if (Array.isArray(props)) {
      __super(type, viewport);
      this.points = props;
    } else {
      __super(props, type, viewport);
      this.points = props.points.map((point) => new Vector3(point.x, point.y, point.z));
    }
    this.mathLines = [];
    this.setMathLines();
  }
  getPoints() {
    return this.points;
  }
  setMathLines() {
    for (let i = 0; i < this.points.length - 1; i++) {
      this.mathLines.push(new Line3(this.points[i], this.points[i + 1]));
    }
  }
  updateLine() {
    const positions = this.object3d.geometry.getAttribute("position");
    positions.array.set(this.points.map((point) => point.toArray()).flat());
    positions.needsUpdate = true;
  }
  updateLine2() {
    this.object3d.geometry.setPositions(this.points.map((point) => point.toArray()).flat());
  }
}

(() => {
  const tmpMatrix = new Matrix4();
  const tmpQuaternion = new Quaternion();
  return function makeRotationAroundPointAndAxis2(angle, point, axis, target) {
    return target.makeTranslation(point.x, point.y, point.z).multiply(tmpMatrix.makeRotationFromQuaternion(tmpQuaternion.setFromAxisAngle(axis, angle))).multiply(tmpMatrix.makeTranslation(-point.x, -point.y, -point.z));
  };
})();
const triangulate = (shapeVertices, indexOffset = 0, shapeHoles = []) => {
  const indices = [];
  const points = [];
  const faces = ShapeUtils.triangulateShape(shapeVertices, shapeHoles);
  for (let i = 0, l = faces.length; i < l; i++) {
    const face = faces[i];
    const a = face[0] + indexOffset;
    const b = face[1] + indexOffset;
    const c = face[2] + indexOffset;
    indices.push(a, b, c);
  }
  for (let i = 0, l = shapeHoles.length; i < l; i++) {
    shapeVertices.push(...shapeHoles[i]);
  }
  for (let i = 0, l = shapeVertices.length; i < l; i++) {
    points.push(shapeVertices[i].x, shapeVertices[i].y, 0);
  }
  return { points, indices };
};
const isPointInsidePolygon = (inPt, inPolygon) => {
  const polyLen = inPolygon.length;
  let inside = false;
  for (let p = polyLen - 1, q = 0; q < polyLen; p = q++) {
    let edgeLowPt = inPolygon[p];
    let edgeHighPt = inPolygon[q];
    let edgeDx = edgeHighPt.x - edgeLowPt.x;
    let edgeDy = edgeHighPt.y - edgeLowPt.y;
    if (Math.abs(edgeDy) > Number.EPSILON) {
      if (edgeDy < 0) {
        edgeLowPt = inPolygon[q];
        edgeDx = -edgeDx;
        edgeHighPt = inPolygon[p];
        edgeDy = -edgeDy;
      }
      if (inPt.y < edgeLowPt.y || inPt.y > edgeHighPt.y)
        continue;
      if (inPt.y === edgeLowPt.y) {
        if (inPt.x === edgeLowPt.x)
          return true;
      } else {
        const perpEdge = edgeDy * (inPt.x - edgeLowPt.x) - edgeDx * (inPt.y - edgeLowPt.y);
        if (perpEdge === 0)
          return true;
        if (perpEdge < 0)
          continue;
        inside = !inside;
      }
    } else {
      if (inPt.y !== edgeLowPt.y)
        continue;
      if (edgeHighPt.x <= inPt.x && inPt.x <= edgeLowPt.x || edgeLowPt.x <= inPt.x && inPt.x <= edgeHighPt.x)
        return true;
    }
  }
  return inside;
};

class Polygon extends Polyline {
  constructor(props, type, viewport) {
    var __super = (...args) => {
      super(...args);
    };
    if (Array.isArray(props)) {
      if (props[0].x !== props.at(-1)?.x || props[0].y !== props.at(-1)?.y) {
        props.push(new Vector3().copy(props[0]));
      }
      __super(props, type, viewport);
      this.fill = new Mesh();
    } else {
      if (props.points[0].x !== props.points.at(-1)?.x || props.points[0].y !== props.points.at(-1)?.y) {
        props.points.push({ x: props.points[0].x, y: props.points[0].y, z: props.points[0].z });
      }
      __super(props, type, viewport);
      this.fill = new Mesh();
      this.fill.material.color = props.fillColor ? new Color(props.fillColor) : new Color("red");
      this.fill.material.opacity = props.fillOpacity ? props.fillOpacity : 1;
    }
    this.fill.material.transparent = true;
    this.fill.layers = this.object3d.layers;
    this.object3d.add(this.fill);
  }
  update() {
    super.update();
    this.updateFillGeometry();
  }
  updateFillGeometry() {
    const { points, indices } = triangulate(this.points.map((point) => new Vector2(point.x, point.y)));
    const position = new Float32BufferAttribute(points, 3);
    this.fill.geometry.setAttribute("position", position).setIndex(indices);
    position.needsUpdate = true;
    this.fill.geometry.getIndex().needsUpdate = true;
  }
  showFill() {
    this.object3d.add(this.fill);
  }
  hideFill() {
    this.object3d.remove(this.fill);
  }
}

class Highlighter {
  constructor(entity) {
    this.entity = entity;
  }
}

class ColorHighlighter extends Highlighter {
  constructor(object3d, color = 16711680) {
    super(object3d);
    this.material = /* @__PURE__ */ new Map();
    this.color = color;
  }
  highlight() {
    this.saveColor();
    this.setColor(this.color);
  }
  unHighlight() {
    this.restoreColor();
  }
  setColor(color) {
    if (this.entity.object3d instanceof Mesh) {
      this.makeSetColor(this.entity.object3d.material, color);
    } else if (this.entity.object3d instanceof Object3D) {
      this.entity.object3d.traverse((obj) => {
        if (obj.material)
          this.makeSetColor(obj.material, color);
      });
    }
  }
  saveColor() {
    if (this.entity.object3d instanceof Mesh) {
      this.makeSaveColor(this.entity.object3d.material, this.entity);
    } else if (this.entity.object3d instanceof Object3D) {
      this.entity.object3d.traverse((obj) => {
        if (obj.material)
          this.makeSaveColor(obj.material, obj);
      });
    }
  }
  restoreColor() {
    if (this.entity.object3d instanceof Mesh) {
      this.makeRestoreColor(this.entity.object3d.material, this.material.get(this.entity));
    } else if (this.entity.object3d instanceof Object3D) {
      this.entity.object3d.traverse((obj) => {
        if (obj.material)
          this.makeRestoreColor(obj.material, this.material.get(obj));
      });
    }
  }
  makeSetColor(mat, color) {
    const material = mat;
    if (material instanceof ShaderMaterial) {
      material.uniforms.diffuse?.value?.set(color);
      material.uniforms.specular?.value?.set(color);
      material.uniforms.emissive?.value?.set(color);
    } else {
      material.color?.set(color);
      material.specular?.set(color);
      material.emissive?.set(color);
    }
  }
  makeSaveColor(mat, obj) {
    const material = mat;
    if (material instanceof ShaderMaterial) {
      this.material.set(obj, {
        color: material.uniforms.diffuse?.value?.toArray(),
        specular: material.uniforms.specular?.value?.toArray(),
        emissive: material.uniforms.emissive?.value?.toArray()
      });
    } else {
      this.material.set(obj, {
        color: material.color?.toArray(),
        specular: material.specular?.toArray(),
        emissive: material.emissive?.toArray()
      });
    }
  }
  makeRestoreColor(mat, data) {
    const material = mat;
    if (data) {
      if (material instanceof ShaderMaterial) {
        material.uniforms.diffuse?.value?.fromArray(data.color);
        material.uniforms.specular?.value?.fromArray(data.specular);
        material.uniforms.emissive?.value?.fromArray(data.emissive);
      } else {
        material.color?.fromArray(data.color);
        material.specular?.fromArray(data.specular);
        material.emissive?.fromArray(data.emissive);
      }
    }
  }
}

class HoverComponent extends Component {
  constructor(entity, highlighter = new ColorHighlighter(entity, void 0)) {
    super(entity);
    this.hovered = false;
    this.highlighter = highlighter;
  }
  hover() {
    this.hovered = true;
    this.highlighter.highlight();
  }
  unhover() {
    this.hovered = false;
    this.highlighter.unHighlight();
  }
  isHovered() {
    return this.hovered;
  }
}

class MaskHighlighter extends Highlighter {
  constructor(entity) {
    super(entity);
    if (!(entity instanceof Mask)) {
      throw new Error("Mask-only component");
    }
  }
  highlight() {
    this.entity.hideFill();
  }
  unHighlight() {
    this.entity.showFill();
  }
}

class MaskRaycastComponent extends Raycast {
  constructor(entity) {
    super(entity);
    if (!(entity instanceof Mask)) {
      throw new Error("Mask-only component");
    }
  }
  *intersect(raycaster) {
    const point = raycaster.ray.origin;
    const activeViewport = App.Instance().getActiveView().getActiveViewport();
    if (!activeViewport?.camera.layers.test(this.entity.object3d.layers)) {
      return;
    }
    const isIntersect = isPointInsidePolygon(point, this.entity.getPoints());
    if (isIntersect) {
      yield {
        raycast: this,
        intersections: [
          {
            point: point.clone(),
            distance: point.z,
            object: this.entity.object3d
          }
        ]
      };
    }
  }
}

class Mask extends Polygon {
  constructor(props, viewport) {
    super(props, Line2, viewport);
    if (!Array.isArray(props)) {
      this.id = props.id;
    }
    this.object3d.material.transparent = true;
    this.setComponents();
    this.update();
  }
  setComponents() {
    this.setComponent(MaskRaycastComponent);
    this.setComponent(HoverComponent, new HoverComponent(this, new MaskHighlighter(this)));
  }
}

class Wrapper extends Entity {
  constructor(entities, id) {
    super(new Group());
    this.id = id;
    this.add(entities);
  }
  add(children) {
    this.container.add(children);
  }
  delete(children) {
    this.container.delete(children);
  }
  toJson() {
    throw new Error("Method not implemented.");
  }
}

const getImageId = (image) => {
  return image.getComponent(ViewportComponent)?.viewports.values().next()?.value.name || image.uuid;
};
const getImageById = (id) => {
  const images = Viewer2D.view.getLayer("image")?.getObjects();
  if (!images)
    return;
  const viewport = Viewer2D.view.getViewport(id);
  if (!viewport)
    return;
  return images.find((image) => image.getComponent(ViewportComponent)?.viewports.has(viewport));
};

class ImageLoader {
  constructor() {
    this.images = {};
    this.promises = {};
    this.loadingManager = new LoadingManager();
    this.loader = new TextureLoader(this.loadingManager);
  }
  async get(url, cb) {
    if (Array.isArray(url)) {
      return Promise.all(url.map((url2) => this.getOneImage(url2, cb)));
    } else {
      return this.getOneImage(url, cb);
    }
  }
  setWithCredentials(value) {
    this.loader.setCrossOrigin(value ? "use-credentials" : "anonymous");
    this.loader.setWithCredentials(value);
  }
  async getOneImage(url, cb) {
    let image;
    let promise = this.promises[url];
    if (promise) {
      image = await promise;
    } else {
      image = this.images[url];
      if (!image) {
        promise = this.loader.loadAsync(url);
        this.promises[url] = promise;
        image = await promise;
        delete this.promises[url];
        this.images[url] = image;
      }
    }
    if (cb) {
      cb(url, image);
    }
    return image;
  }
}
var ImageLoader$1 = new ImageLoader();

const parseDicom = (byteArray) => {
  const dataSet = dicomParser.parseDicom(byteArray);
  const pixelData = dataSet.elements.x7fe00010;
  const width = dataSet.int16("x00280011");
  const height = dataSet.int16("x00280010");
  const wc = dataSet.floatString("x00281050");
  const ww = dataSet.floatString("x00281051");
  const pixelSpacing = dataSet.string("x00280030")?.split("\\").map(parseFloat);
  let data;
  if (width * height === pixelData.length) {
    const x = new Uint8Array(dataSet.byteArray.buffer, pixelData.dataOffset, pixelData.length);
    data = new Int16Array(x);
  } else {
    data = new Int16Array(dataSet.byteArray.buffer, pixelData.dataOffset, pixelData.length / 2);
  }
  return { width, height, ww, wc, pixelSpacing, data };
};

class DicomTexture extends DataTexture {
  constructor(data, width, height, ww, wc) {
    super(data, width, height, RedFormat, FloatType);
    this.ww = ww;
    this.wc = wc;
    this.magFilter = LinearFilter;
    this.minFilter = LinearFilter;
    this.flipY = true;
  }
  get width() {
    return this.image.width;
  }
  get height() {
    return this.image.height;
  }
}

class DicomLoader {
  constructor() {
    this.dataTextures = {};
    this.promises = {};
    this.credentials = "same-origin";
  }
  async get(url, cb) {
    if (Array.isArray(url)) {
      return Promise.all(url.map((url2) => this.getOneImage(url2, cb)));
    } else {
      return this.getOneImage(url, cb);
    }
  }
  setWithCredentials(value) {
    this.credentials = value ? "include" : "same-origin";
  }
  async getOneImage(url, cb) {
    let dataTexture;
    let promise = this.promises[url];
    if (promise) {
      dataTexture = await promise;
    } else {
      dataTexture = this.dataTextures[url];
      if (!dataTexture) {
        promise = new Promise(async (resolve) => {
          const response = await fetch(url, { credentials: this.credentials });
          const arrayBuffer = await response.arrayBuffer();
          const byteArray = new Uint8Array(arrayBuffer);
          const dicom = parseDicom(byteArray);
          const { width, height, ww, wc, data } = dicom;
          dataTexture = new DicomTexture(new Float32Array(data), width, height, ww, wc);
          dataTexture.needsUpdate = true;
          resolve(dataTexture);
        });
        this.promises[url] = promise;
        dataTexture = await promise;
        this.dataTextures[url] = dataTexture;
      }
    }
    if (cb) {
      cb(url, dataTexture);
    }
    return dataTexture;
  }
}
var DicomLoader$1 = new DicomLoader();

let REPORT_ID;
let STATUS;
class API {
  constructor(launcher) {
    this.domRefsMap = /* @__PURE__ */ new Map();
    STATUS = "pending";
    this.launcher = launcher;
    this.domRefsMap.set("main", {
      viewports: /* @__PURE__ */ new Map()
    });
  }
  run(reportId, preserveDrawingBuffer) {
    if (REPORT_ID && reportId !== REPORT_ID) {
      if (STATUS !== "pending") {
        this.launcher.shutdown();
        STATUS = "pending";
      }
      REPORT_ID = reportId;
    }
    if (this.isRunning() || !this.isReady())
      return false;
    const main = this.getDomElements("main");
    if (this.isSuspended()) {
      this.launcher.init(main, preserveDrawingBuffer);
    } else {
      this.launcher.launch(main, preserveDrawingBuffer);
    }
    REPORT_ID = reportId;
    STATUS = "running";
    return true;
  }
  suspend() {
    if (this.isRunning()) {
      App.Instance().view.viewports.forEach((viewport) => {
        setTimeout(() => this.domRefsMap.get("main")?.viewports.get(viewport.name)?.revoke);
        this.domRefsMap.get("main")?.viewports.delete(viewport.name);
      });
      this.launcher.dispose();
      STATUS = "suspended";
    }
  }
  resetUndoStack() {
    App.Instance().undoStack.undoAll();
  }
  activateMode(mode) {
    App.Instance().view.activateMode(mode);
    App.Instance().dispatchEvent({ type: "render" });
  }
  isRunning() {
    return STATUS === "running";
  }
  isSuspended() {
    return STATUS === "suspended";
  }
  getCurrentMode() {
    return App.Instance().view.toolController.getModeName();
  }
  setCredentials(credentials) {
    const value = credentials === "include";
    ImageLoader$1.setWithCredentials(value);
    DicomLoader$1.setWithCredentials(value);
  }
  getRefs(viewName) {
    return this.domRefsMap.get(viewName);
  }
  getViewportRef(viewportName, viewName) {
    return this.domRefsMap.get(viewName)?.viewports.get(viewportName)?.proxy;
  }
  addView(viewName) {
    if (!this.domRefsMap.has(viewName)) {
      this.domRefsMap.set(viewName, {
        viewports: /* @__PURE__ */ new Map()
      });
    }
  }
  setViewRef(ref, viewName = "main") {
    const refs = this.domRefsMap.get(viewName);
    if (!refs)
      return;
    if (refs.view !== ref) {
      refs.view = ref;
    }
  }
  setCanvasRef(ref, viewName = "main") {
    const refs = this.domRefsMap.get(viewName);
    if (!refs)
      return;
    if (refs.canvas !== ref) {
      refs.canvas = ref;
    }
  }
  setViewportRef(ref, viewportName, viewName = "main") {
    const refs = this.domRefsMap.get(viewName);
    if (!refs)
      return;
    const set = (target, prop, value) => {
      if (prop === "current") {
        if (value && target.current !== value) {
          let viewport;
          if (viewName == "main") {
            viewport = App.Instance().view.getViewport(viewportName);
          } else {
            viewport = App.Instance().views[viewName]?.getViewport(viewportName);
          }
          if (viewport && viewport.isEnabled()) {
            viewport.setDomElement(value);
            App.Instance().dispatchEvent({ type: "render" });
          }
        }
        return Reflect.set(target, prop, value);
      }
      return false;
    };
    const revocable = Proxy.revocable(ref, { set });
    if (!refs.viewports.get(viewportName)) {
      refs.viewports.set(viewportName, { object: ref, proxy: revocable.proxy, revoke: revocable.revoke });
    }
  }
  isReady() {
    return this.domRefsMap.has("main") && [...this.domRefsMap.keys()].every((viewport) => {
      const refs = this.domRefsMap.get(viewport);
      return refs && refs.view.current && refs.canvas.current && refs.viewports.size && [...refs.viewports.values()].every((ref) => ref.object.current);
    });
  }
  getDomElements(viewName) {
    const refs = this.domRefsMap.get(viewName);
    if (!refs) {
      return;
    }
    const result = {
      view: refs.view.current,
      canvas: refs.canvas.current,
      viewports: {}
    };
    refs.viewports.forEach((ref, id) => {
      result.viewports[id] = ref.object.current;
    });
    return result;
  }
  addEventListener(type, callback, priority) {
    return App.Instance().addEventListener(type, callback, priority);
  }
  removeEventListener(type, callback, force) {
    App.Instance().removeEventListener(type, callback, force);
  }
}

class ImageViewerAPI extends API {
  deleteMasks(data) {
    const layer = Viewer2D.view.getLayer("mask");
    const objects = layer.getObjects();
    const deleteMasks = (masks, list) => {
      if (list.groupID) {
        layer.delete(masks.filter((mask) => mask instanceof Wrapper && list.groupID.includes(mask.id)));
      }
      if (list.maskIDs) {
        layer.delete(masks.filter((mask) => mask instanceof Mask && list.maskIDs.includes(mask.id)));
        masks.filter((mask) => mask instanceof Wrapper).forEach((wrapper) => {
          deleteMasks(wrapper.children, list);
          if (!wrapper.children.length) {
            layer.delete(wrapper);
          }
        });
      }
      if (!list.groupID && !list.maskIDs) {
        layer.delete(masks);
      }
    };
    if (!data) {
      layer.clear();
      Viewer2D.dispatchEvent({ type: "render" });
      return;
    }
    if (Array.isArray(data)) {
      data.forEach((item) => {
        const viewport = Viewer2D.view.getViewport(item.imageID);
        if (viewport) {
          const objectsForDelete = objects.filter((obj) => obj.getComponent(ViewportComponent)?.viewports.has(viewport));
          deleteMasks(objectsForDelete, item);
        }
      });
      Viewer2D.dispatchEvent({ type: "render" });
      return;
    }
    deleteMasks(objects, data);
    Viewer2D.dispatchEvent({ type: "render" });
  }
  lookAtBox(box, viewportName) {
    Viewer2D.view.getViewport(viewportName)?.dispatchEvent({ type: "setBox", box });
  }
  addConditionBoxes(data) {
    data.forEach((item) => {
      const viewport = Viewer2D.view.getViewport(item.imageID);
      viewport.dispatchEvent({ type: "addConditionBoxes", props: item.boxes });
    });
    Viewer2D.dispatchEvent({ type: "render" });
  }
  deleteConditionBoxes() {
    Viewer2D.view.getLayer("conditionBoxes")?.clear();
    Viewer2D.dispatchEvent({ type: "render" });
  }
  generateFilter(data) {
    const viewport = Viewer2D.view.getViewport(data.imageID);
    if (viewport) {
      viewport.dispatchEvent({ type: "generateFilter", ...data });
    }
  }
  applyFilter(filterId, imageId) {
    if (imageId) {
      const viewport = Viewer2D.view.getViewport(imageId);
      if (viewport) {
        viewport.dispatchEvent({ type: "applyFilter", imageId, filterId });
      }
    } else {
      Viewer2D.view.viewports.forEach(
        (viewport) => viewport.dispatchEvent({ type: "applyFilter", imageId, filterId })
      );
    }
  }
  removeFilters(imageId) {
    if (imageId) {
      const viewport = Viewer2D.view.getViewport(imageId);
      if (viewport) {
        viewport.dispatchEvent({ type: "removeFilter" });
      }
    } else {
      Viewer2D.view.viewports.forEach(
        (viewport) => viewport.dispatchEvent({ type: "removeFilter" })
      );
    }
  }
  deleteFilterFromCache(filterId, imageId) {
    const image = getImageById(imageId);
    if (image) {
      return image.deleteFilterFromCache(filterId);
    }
    return false;
  }
  setTextureMasks(data) {
    data.forEach((image) => {
      const viewport = Viewer2D.view.getViewport(image.imageID);
      viewport.dispatchEvent({ type: "setMasks", masks: image.masks });
    });
  }
  setBox(box, viewportName) {
    this.lookAtBox(box, viewportName);
  }
}

class Tool {
  constructor(viewport) {
    this.removeEventsCallbacks = [];
    this.hostObject = viewport;
    this.enable = false;
    this.onConstructor();
  }
  onConstructor() {
  }
  unsubscribeAll() {
    this.removeEventsCallbacks.forEach((cb) => cb());
    this.removeEventsCallbacks.splice(0);
  }
  init() {
    if (!this.enable && this.hostObject) {
      this.activate();
      this.enable = true;
    } else {
      console.error("tool is not initialized", this.enable, typeof this.hostObject === "undefined");
    }
  }
  dispose() {
    if (this.enable) {
      this.deactivate();
      this.enable = false;
    }
  }
  getHostObject() {
    return this.hostObject;
  }
  on(type, callback, priority) {
    const removeCallback = App.Instance().addEventListener(type, callback, priority);
    this.removeEventsCallbacks?.push(removeCallback);
    return removeCallback;
  }
  off(type, callback, force) {
    return App.Instance().removeEventListener(type, callback, force);
  }
  deactivate() {
    this.unsubscribeAll();
  }
}
class ViewportTool extends Tool {
  constructor(viewport) {
    super(viewport);
  }
  getHostObject() {
    return this.hostObject;
  }
}
class ViewTool extends Tool {
  constructor(view) {
    super(view);
  }
  getHostObject() {
    return this.hostObject;
  }
}

var vertexShader$5 = "#version 300 es\n#define GLSLIFY 1\nin vec3 position;uniform mat4 modelViewMatrix;uniform mat4 projectionMatrix;uniform mat3 uvMatrix;in vec2 uv;out vec2 out_uv;out vec2 out_transformed_uv;void main(){gl_Position=projectionMatrix*modelViewMatrix*vec4(position,1.0);out_transformed_uv=(uvMatrix*vec3(uv,1)).xy;out_uv=uv;}"; // eslint-disable-line

var fragmentShader$6 = "#version 300 es\nprecision highp float;\n#define GLSLIFY 1\nuniform sampler2D dataTexture;uniform sampler2D transparentFilter;uniform sampler2D opaqueFilter;uniform vec2 resolution;uniform float sharpness;uniform float brightness;uniform float contrast;in vec2 out_uv;in vec2 out_transformed_uv;out vec4 out_color;const vec2 offsets[9]=vec2[](vec2(-1.0f,-1.0f),vec2(0.0f,-1.0f),vec2(1.0f,-1.0f),vec2(-1.0f,0.0f),vec2(0.0f,0.0f),vec2(1.0f,0.0f),vec2(-1.0f,1.0f),vec2(0.0f,1.0f),vec2(1.0f,1.0f));const float kernel[9]=float[](-1.0f,-1.0f,-1.0f,-1.0f,9.0f,-1.0f,-1.0f,-1.0f,-1.0f);void main(){vec4 color=vec4(0.0f);for(int i=0;i<9;i++){color+=texture(dataTexture,out_transformed_uv+offsets[i]/resolution)*kernel[i];}color=mix(texture(dataTexture,out_transformed_uv),color,sharpness);color.rgb+=brightness;if(contrast>0.0f){color.rgb=(color.rgb-0.5f)/(1.0f-contrast)+0.5f;}else{color.rgb=(color.rgb-0.5f)*(1.0f+contrast)+0.5f;}vec4 opacFilterColor=texture(opaqueFilter,out_uv);if(length(opacFilterColor)>0.0f){color*=opacFilterColor;}vec4 transparentFilterColor=texture(transparentFilter,out_uv);color=transparentFilterColor*transparentFilterColor.a+color*(1.0f-transparentFilterColor.a);out_color=color;}"; // eslint-disable-line

class RasterImageMaterial extends RawShaderMaterial {
  constructor(texture, width, height, uvMatrix) {
    super({
      vertexShader: vertexShader$5,
      fragmentShader: fragmentShader$6,
      uniforms: {
        dataTexture: { value: texture },
        uvMatrix: { value: uvMatrix },
        brightness: { value: 0 },
        contrast: { value: 0 },
        sharpness: { value: 0 },
        transparentFilter: { value: null },
        opaqueFilter: { value: null },
        resolution: { value: new Vector2(width, height) }
      }
    });
  }
  dispose() {
    super.dispose();
    this.uniforms.dataTexture.value.dispose();
    this.uniforms.transparentFilter.value?.dispose();
    this.uniforms.opaqueFilter.value?.dispose();
  }
}

var vertexShader$4 = "#version 300 es\n#define GLSLIFY 1\nin vec3 position;uniform mat4 modelViewMatrix;uniform mat4 projectionMatrix;uniform mat3 uvMatrix;in vec2 uv;out vec2 out_uv;out vec2 out_transformed_uv;void main(){gl_Position=projectionMatrix*modelViewMatrix*vec4(position,1.0);out_transformed_uv=(uvMatrix*vec3(uv,1)).xy;out_uv=uv;}"; // eslint-disable-line

var fragmentShader$5 = "#version 300 es\nprecision highp float;\n#define GLSLIFY 1\nuniform sampler2D dataTexture;uniform sampler2D transparentFilter;uniform sampler2D opaqueFilter;uniform vec2 resolution;uniform float sharpness;uniform float brightness;uniform float contrast;uniform float ww;uniform float wmin;in vec2 out_uv;in vec2 out_transformed_uv;out vec4 out_color;const vec2 offsets[9]=vec2[](vec2(-1.0f,-1.0f),vec2(0.0f,-1.0f),vec2(1.0f,-1.0f),vec2(-1.0f,0.0f),vec2(0.0f,0.0f),vec2(1.0f,0.0f),vec2(-1.0f,1.0f),vec2(0.0f,1.0f),vec2(1.0f,1.0f));const float kernel[9]=float[](-1.0f,-1.0f,-1.0f,-1.0f,9.0f,-1.0f,-1.0f,-1.0f,-1.0f);void main(){float value=0.0f;for(int i=0;i<9;i++){value+=texture(dataTexture,out_transformed_uv+offsets[i]/resolution).r*kernel[i];}value=mix(texture(dataTexture,out_transformed_uv).r,value,sharpness);value=(value-wmin)/ww;value+=brightness;if(contrast>0.0f){value=(value-0.5f)/(1.0f-contrast)+0.5f;}else{value=(value-0.5f)*(1.0f+contrast)+0.5f;}vec4 color=vec4(value,value,value,1.0f);vec4 opacFilterColor=texture(opaqueFilter,out_uv);if(length(opacFilterColor)>0.0f){color*=opacFilterColor;}vec4 transparentFilterColor=texture(transparentFilter,out_uv);color=transparentFilterColor*transparentFilterColor.a+color*(1.0f-transparentFilterColor.a);out_color=color;}"; // eslint-disable-line

class DicomImageMaterial extends RawShaderMaterial {
  constructor(texture, width, height, uvMatrix) {
    const { ww, wc } = texture;
    super({
      vertexShader: vertexShader$4,
      fragmentShader: fragmentShader$5,
      uniforms: {
        dataTexture: { value: texture },
        uvMatrix: { value: uvMatrix },
        brightness: { value: 0 },
        contrast: { value: 0 },
        sharpness: { value: 0 },
        transparentFilter: { value: null },
        opaqueFilter: { value: null },
        resolution: { value: new Vector2(width, height) },
        ww: { value: ww },
        wmin: { value: wc - ww / 2 }
      }
    });
  }
  dispose() {
    super.dispose();
    this.uniforms.dataTexture.value.dispose();
    this.uniforms.transparentFilter.value?.dispose();
    this.uniforms.opaqueFilter.value?.dispose();
  }
}

class ImageEntity extends Entity {
  constructor(texture, x0 = 0, y0 = 0, width = texture.image.width, height = texture.image.height) {
    super(new Mesh(new PlaneGeometry()));
    this.setTexture(texture);
    this.setSize(width, height, x0, y0);
    this.setComponent(ViewportComponent);
  }
  setTexture(texture) {
    this.texture = texture;
    this.texture.wrapS = RepeatWrapping;
    this.texture.wrapT = RepeatWrapping;
    this.object3d.material.needsUpdate = true;
  }
  getTexture() {
    return this.texture;
  }
  set brightness(brightness) {
    this.object3d.material.uniforms.brightness.value = brightness;
  }
  set contrast(contrast) {
    this.object3d.material.uniforms.contrast.value = contrast;
  }
  set sharpness(sharpness) {
    this.object3d.material.uniforms.sharpness.value = sharpness;
  }
  get brightness() {
    return this.object3d.material.uniforms.brightness.value;
  }
  get contrast() {
    return this.object3d.material.uniforms.contrast.value;
  }
  get sharpness() {
    return this.object3d.material.uniforms.sharpness.value;
  }
  setSize(width, height, x0, y0) {
    this.width = width;
    this.height = height;
    this.object3d.scale.set(this.width, this.height, 1);
    this.object3d.updateMatrix();
    this.object3d.material = this.texture instanceof DicomTexture ? new DicomImageMaterial(this.texture, width, height, new Matrix3()) : new RasterImageMaterial(this.texture, width, height, new Matrix3());
  }
  computeUvMatrix(texture, x0 = 0, y0 = 0, cropWidth = texture.image.width - x0, cropHeight = texture.image.height - y0, offset) {
    const actualWidth = texture.image.width;
    const actualHeight = texture.image.height;
    y0 = actualHeight - this.height - y0;
    if (offset) {
      x0 -= offset.x;
      y0 += offset.y;
    }
    const xFactor = 1 / this.width;
    const yFactor = 1 / this.height;
    const offsetX = offset ? offset.x : 0;
    const offsetY = offset ? this.height - (offset.y + cropHeight) : this.height - cropHeight;
    const w = cropWidth * xFactor;
    const h = cropHeight * yFactor;
    const minX = offsetX * xFactor;
    const minY = offsetY * yFactor;
    const cropMatrix = new Matrix3().set(1 / w, 0, -minX / w, 0, 1 / h, -minY / h, 0, 0, 1);
    const uvMatrix = new Matrix3().setUvTransform(x0 / actualWidth, y0 / actualHeight, this.width / actualWidth, this.height / actualHeight, 0, 0, 0);
    return { uvMatrix, cropMatrix };
  }
  getWidth() {
    return this.width;
  }
  getHeight() {
    return this.height;
  }
  toJson() {
    throw new Error("Method not implemented.");
  }
}

var vertexShader$3 = "#version 300 es\n#define GLSLIFY 1\nin vec3 position;uniform mat4 modelViewMatrix;uniform mat4 projectionMatrix;uniform mat3 uvMatrix;uniform mat3 cropMatrix;in vec2 uv;out vec2 out_uv;out vec2 out_transformed_uv;out vec2 out_crop_uv;void main(){gl_Position=projectionMatrix*modelViewMatrix*vec4(position,1.0);out_uv=uv;out_transformed_uv=(uvMatrix*vec3(uv,1)).xy;out_crop_uv=(cropMatrix*vec3(uv,1)).xy;}"; // eslint-disable-line

var fragmentShader$4 = "#version 300 es\nprecision highp float;\n#define GLSLIFY 1\nuniform sampler2D target;uniform sampler2D mask;uniform vec4 color;uniform bool blending;in vec2 out_uv;in vec2 out_transformed_uv;in vec2 out_crop_uv;out vec4 out_color;void main(){vec4 targetColor=texture(target,out_uv);vec2 inBounds=step(vec2(0.0f),out_crop_uv)*step(out_crop_uv,vec2(1.0f));if(inBounds.x*inBounds.y>=1.0f){vec4 maskColor=texture(mask,out_transformed_uv);if(blending){float alpha=targetColor.a;if(maskColor.r==1.0f){if(maskColor.a==1.0f&&targetColor.a==1.0f){targetColor*=color;}else{float targetAlpha=(1.0f-color.a)*targetColor.a;alpha=targetAlpha+color.a;targetColor=vec4(((targetAlpha*targetColor+color.a*color)/alpha).rgb,alpha);}}}else{if(maskColor.r==1.0f){targetColor=color;}}}out_color=targetColor;}"; // eslint-disable-line

class MaskMergingMaterial extends RawShaderMaterial {
  constructor(texture, color, uvMatrix, cropMatrix, blending = true, target) {
    super({
      vertexShader: vertexShader$3,
      fragmentShader: fragmentShader$4,
      uniforms: {
        target: { value: texture },
        mask: { value: texture },
        color: { value: color },
        uvMatrix: { value: uvMatrix },
        cropMatrix: { value: cropMatrix },
        blending: { value: blending }
      }
    });
  }
}

class MaskMergingPass extends ShaderPass {
  constructor(mask, color, opacity, uvMatrix, cropMatrix, blending) {
    super(new MaskMergingMaterial(mask, new Vector4(color.r, color.g, color.b, opacity), uvMatrix, cropMatrix, blending), "target");
    this.renderToScreen = false;
  }
}

const renderFilter = (width, height, data, clearPassProps = { color: "black", opacity: 0, blending: true }) => {
  const renderer = App.Instance().view.getRenderer();
  const size = renderer.getSize(new Vector2());
  const composer = new EffectComposer(renderer);
  renderer.setSize(width, height, false);
  composer.setSize(width, height);
  composer.setPixelRatio(1);
  composer.renderToScreen = false;
  composer.readBuffer.texture.magFilter = NearestFilter;
  composer.readBuffer.texture.minFilter = NearestFilter;
  composer.readBuffer.texture.generateMipmaps = false;
  composer.addPass(new ClearPass(clearPassProps.color, clearPassProps.opacity));
  data.forEach((item) => {
    item.mask.magFilter = NearestFilter;
    item.mask.minFilter = NearestFilter;
    item.mask.generateMipmaps = false;
    composer.addPass(
      new MaskMergingPass(
        item.mask,
        new Color(item.color),
        item.opacity,
        item.uvMatrix,
        item.cropMatrix,
        clearPassProps.blending
      )
    );
  });
  composer.render();
  renderer.setSize(size.x, size.y, false);
  if (clearPassProps.buffer) {
    renderer.readRenderTargetPixels(composer.readBuffer, 0, 0, width, height, clearPassProps.buffer);
  }
  return composer.readBuffer.texture;
};

class XRayImage extends ImageEntity {
  constructor(texture, maskProps = [], x0, y0, width, height) {
    super(texture, x0, y0, width, height);
    this.masks = {};
    this.filters = {};
    this.setMasks(maskProps);
  }
  applyFilter(name) {
    const filter = this.filters[name];
    if (!filter)
      return;
    if (filter.transparent) {
      this.object3d.material.uniforms.transparentFilter.value = filter.transparent;
    }
    if (filter.opaque) {
      this.object3d.material.uniforms.opaqueFilter.value = filter.opaque;
    }
  }
  setMasks(maskProps) {
    maskProps.forEach((prop) => {
      this.masks[prop.id] = prop;
    });
  }
  removeFilter() {
    this.object3d.material.uniforms.transparentFilter.value = null;
    this.object3d.material.uniforms.opaqueFilter.value = null;
  }
  deleteFilterFromCache(id) {
    if (this.filters[id]) {
      this.filters[id].opaque?.dispose();
      this.filters[id].transparent?.dispose();
      delete this.filters[id];
      return true;
    }
    return false;
  }
  async prepareDataForFilter(config) {
    const data = [];
    const maskTextures = await ImageLoader$1.get(config.map((item) => this.masks[item.maskID].url));
    for (let i = 0; i < config.length; i++) {
      const maskId = config[i].maskID;
      const color = config[i].color;
      const opacity = config[i].opacity || 1;
      const maskProps = this.masks[maskId];
      if (maskProps) {
        const { x0, y0, width, height, offset } = maskProps;
        const mask = maskTextures[i];
        const { uvMatrix, cropMatrix } = this.computeUvMatrix(mask, x0, y0, width, height, offset);
        data.push({ mask, color, uvMatrix, cropMatrix, opacity });
      }
    }
    return data;
  }
  async makeOpaqueAndTransparentFilter(filterID, config) {
    if (this.filters[filterID]) {
      return this.filters[filterID];
    }
    const data = await this.prepareDataForFilter(config);
    if (data.length) {
      const transparentMasksConfig = data.filter((item) => item.opacity && item.opacity !== 1);
      const opaqueMasksConfig = data.filter((item) => !item.opacity || item.opacity === 1);
      const transparent = renderFilter(this.width, this.height, transparentMasksConfig);
      const opaque = renderFilter(this.width, this.height, opaqueMasksConfig, { color: "white", opacity: 1 });
      const filter = { transparent, opaque };
      this.filters[filterID] = filter;
      return filter;
    }
  }
  async makeTransparentNonBlendedFilter(filterID, config) {
    if (this.filters[filterID]) {
      return this.filters[filterID];
    }
    const data = await this.prepareDataForFilter(config);
    if (data.length) {
      const transparent = renderFilter(this.width, this.height, data, { color: "black", opacity: 0, blending: false });
      const filter = { transparent };
      this.filters[filterID] = filter;
      return filter;
    }
  }
}

class XRayImageSpawner extends ViewportTool {
  constructor(viewport) {
    super(viewport);
    this.generateFilter = (() => {
      const onImage = [];
      Observer.observe(this, "image", () => {
        onImage.forEach((cb) => cb());
        onImage.length = 0;
      });
      return async (event) => {
        if (!this.image) {
          onImage.push(() => this.generateFilter(event));
          return;
        }
        const filter = await this.image.makeTransparentNonBlendedFilter(event.filterID, event.config);
        if (!event.callback)
          return;
        if (filter) {
          event.callback({ imageID: this.imageProps.id, filterID: event.filterID });
        } else {
          event.callback();
        }
      };
    })();
    this.loadingManager = new LoadingManager();
    this.loader = new TextureLoader(this.loadingManager);
    this.setImageData = this.setImageData.bind(this);
    this.generateFilter = this.generateFilter.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
    this.removeFilter = this.removeFilter.bind(this);
    this.setMasks = this.setMasks.bind(this);
  }
  activate() {
    this.hostObject.addEventListener("generateFilter", this.generateFilter);
    this.hostObject.addEventListener("applyFilter", this.applyFilter);
    this.hostObject.addEventListener("removeFilter", this.removeFilter);
    this.hostObject.addEventListener("setMasks", this.setMasks);
    this.image = getImageById(this.hostObject.name);
    if (!this.image) {
      this.hostObject.addEventListenerOnce("setImage", this.setImageData);
    }
  }
  deactivate() {
    this.hostObject.removeEventListener("setImage", this.setImageData);
    this.hostObject.removeEventListener("generateFilter", this.generateFilter);
    this.hostObject.removeEventListener("applyFilter", this.applyFilter);
    this.hostObject.removeEventListener("removeFilter", this.removeFilter);
    this.hostObject.removeEventListener("setMasks", this.setMasks);
    this.image = void 0;
  }
  async setImageData(event) {
    const preview = await this.addPreview(event.props);
    if (preview) {
      this.hostObject.dispatchEvent({ type: "imageAdded", imageID: event.props.id, image: preview });
      this.image = preview;
      this.hostObject.camera.position.set(0, 0, 1e3);
      if (event.callback) {
        event.callback(event.props.id);
      }
      this.add(event.props);
      return;
    }
    const image = await this.add(event.props);
    this.hostObject.dispatchEvent({ type: "imageAdded", imageID: event.props.id, image });
    this.image = image;
    this.hostObject.camera.position.set(0, 0, 1e3);
    if (event.callback) {
      event.callback(event.props.id);
    }
  }
  setMasks(event) {
    if (this.image) {
      this.image.setMasks(event.masks);
      return;
    }
    if (this.imageProps) {
      this.imageProps.masks = event.masks;
      return;
    }
    this.hostObject.addEventListenerOnce("setImage", (e) => {
      e.props.masks = event.masks;
    }, 1);
  }
  applyFilter(event) {
    this.image?.applyFilter(event.filterId);
    App.Instance().dispatchEvent({ type: "render" });
  }
  removeFilter() {
    this.image?.removeFilter();
    App.Instance().dispatchEvent({ type: "render" });
  }
  async addPreview(imageProps) {
    if (this.image)
      return;
    this.imageProps = imageProps;
    let { url, previewURL, width, height, x0, y0, masks, flipY } = imageProps;
    let texture;
    if (previewURL && width && height) {
      texture = await ImageLoader$1.get(previewURL);
      if (typeof flipY === "boolean")
        texture.flipY = flipY;
      const image = new XRayImage(texture, masks, x0, y0, width, height);
      this.insert(image, imageProps);
      return image;
    }
  }
  async add(imageProps) {
    this.imageProps = imageProps;
    let { url, width, height, x0, y0, masks, ww, wc, flipY } = imageProps;
    let texture;
    if (imageProps.kind === "dicom") {
      texture = await DicomLoader$1.get(url);
      if (ww)
        texture.ww = ww;
      if (wc)
        texture.wc = wc;
    } else {
      texture = await ImageLoader$1.get(url);
    }
    if (typeof flipY === "boolean")
      texture.flipY = flipY;
    if (this.image) {
      this.image.object3d.material.uniforms.dataTexture.value = texture;
      App.Instance().dispatchEvent({ type: "render" });
      return this.image;
    }
    const image = new XRayImage(texture, masks, x0, y0, width, height);
    this.insert(image, imageProps);
    return image;
  }
  insert(image, imageProps) {
    let { brightness, contrast, sharpness, angle } = imageProps;
    image.brightness = brightness || 0;
    image.contrast = contrast || 0;
    image.sharpness = sharpness || 0;
    if (angle) {
      this.hostObject.camera.rotation.set(0, 0, angle);
    }
    image.getComponent(ViewportComponent)?.addViewport(this.hostObject);
    const layer = this.hostObject.view.getLayer("image");
    layer.add(image);
    App.Instance().dispatchEvent({ type: "render" });
    this.hostObject.removeEventListener("setImage", this.setImageData);
    return image;
  }
}
XRayImageSpawner.screenProps = {
  brightness: 0,
  contrast: 0,
  sharpness: 0
};

const setImages = (data) => {
  if (data.screenProps) {
    XRayImageSpawner.screenProps = data.screenProps;
  }
  const promises = [];
  data.props.forEach((imageData) => {
    promises.push(
      setImage(imageData, data.callback)
    );
  });
  return promises;
};
const setImage = (imageData, callback, viewportName) => {
  const viewport = Viewer2D.view.getViewport(viewportName || imageData.id);
  return new Promise((resolve) => {
    const cb = (imageId) => {
      callback && callback(imageId);
      resolve();
    };
    viewport.dispatchEvent({ type: "setImage", props: imageData, callback: cb });
  });
};

const coreEventNames = [
  "transformCameraStart",
  "transformCameraEnd",
  "transformCamera",
  "WillReunload",
  "resize",
  "add",
  "delete",
  "addLayer",
  "deleteLayer",
  "ActiveViewportWillChange",
  "ActiveViewportChanged",
  "hover",
  "unhover",
  "select",
  "deselect",
  "render",
  "transform",
  "transformStart",
  "transformEnd",
  "resourceAdded",
  "contextMenuOpen",
  "contextMenuClose",
  "modeChanged",
  "visibility",
  "viewportSchemeChanged",
  "geometryChanged",
  "materialChanged",
  "setRenderingStyle",
  "fatal",
  "makeScreenshot",
  "applicationProgress"
];

const viewer2DUIEventNames = [
  "brightnessContrastChanged",
  "sharpnessChanged",
  "hoverMask"
];
const viewer2DEventNames = [
  ...coreEventNames,
  ...viewer2DUIEventNames
];
const viewer2DViewportEventNames = [
  ...viewportEventNames,
  "setImage",
  "setMasks",
  "imageAdded",
  "addPBL",
  "addConditionBoxes",
  "addMasks",
  "generateFilter",
  "applyFilter",
  "removeFilter",
  "setBox"
];

const ioXRayUIEventNames = [
  ...viewer2DUIEventNames,
  "screenBrightnessContrastChanged",
  "screenSharpnessChanged"
];
const ioXRayEventNames = [
  ...viewer2DEventNames,
  ...ioXRayUIEventNames
];
const ioXRayViewportEventNames = [
  ...viewer2DViewportEventNames
];

class Mode {
  constructor(options) {
    if (Array.isArray(options)) {
      this.options = { tools: { toolList: options, include: true } };
    } else {
      this.options = options;
    }
    this.toolConstructors = /* @__PURE__ */ new Set();
    this.init = this.init.bind(this);
    this.name = "";
    this.enable = false;
  }
  setHostObject(hostObject, modeName) {
    this.hostObject = hostObject;
    this.name = modeName;
    const rootTools = this.hostObject.toolController.getTools();
    if (!rootTools) {
      console.error("Tools are not defined!");
      return;
    }
    if (!this.options) {
      Array.from(rootTools.keys()).forEach((item) => this.toolConstructors.add(item));
    } else {
      if (this.options.tools) {
        if (this.options.tools.include) {
          this.options.tools.toolList.forEach((item) => this.toolConstructors.add(item));
        } else {
          Array.from(rootTools.keys()).forEach((item) => this.toolConstructors.add(item));
          this.options.tools.toolList.forEach((toolName) => this.toolConstructors.delete(toolName));
        }
      } else {
        Array.from(rootTools.keys()).forEach((item) => this.toolConstructors.add(item));
      }
    }
  }
  init() {
    if (!this.hostObject) {
      console.error("Mode hostObject is not defined before init");
      return;
    }
    const prevMode = this.hostObject.toolController.getMode();
    if (prevMode && prevMode.enable) {
      prevMode.toolConstructors.forEach((toolName) => {
        if (!this.toolConstructors.has(toolName)) {
          this.hostObject.toolController.getTool(toolName)?.dispose();
        }
      });
      this.toolConstructors.forEach((toolName) => {
        if (!prevMode.toolConstructors.has(toolName)) {
          this.hostObject.toolController.getTool(toolName)?.init();
        }
      });
    } else {
      this.toolConstructors.forEach((toolName) => {
        this.hostObject.toolController.getTool(toolName)?.init();
      });
    }
    this.enable = true;
  }
  dispose() {
    if (!this.hostObject) {
      console.error("Mode hostObject is not defined while disposing");
      return;
    }
    this.toolConstructors.forEach((toolName) => {
      this.hostObject.toolController.getTool(toolName)?.dispose();
    });
    this.enable = false;
  }
  getName() {
    return this.name;
  }
  getToolNames() {
    return this.toolConstructors;
  }
}

const tuple = (...args) => args;

class UndoRedo extends ViewTool {
  constructor(view) {
    super(view);
    this.onKeyDown = this.onKeyDown.bind(this);
  }
  activate() {
    App.Instance().keyboardController.addListener("keydown", this.onKeyDown, "KeyZ");
  }
  deactivate() {
    App.Instance().keyboardController.removeListener("keydown", this.onKeyDown, "KeyZ");
  }
  onKeyDown(event) {
    if (event.ctrlKey || event.metaKey) {
      if (event.shiftKey) {
        App.Instance().undoStack.redo();
      } else {
        App.Instance().undoStack.undo();
      }
    }
  }
}

class ActiveViewSwitcher2 extends ViewTool {
  constructor(view) {
    super(view);
    this.onMouseOver = this.onMouseOver.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onTransformStart = this.onTransformStart.bind(this);
    this.onTransformEnd = this.onTransformEnd.bind(this);
    this.onMouseOverWhileTransform = this.onMouseOverWhileTransform.bind(this);
  }
  activate() {
    this.hostObject.viewports.forEach((viewport) => {
      App.Instance().mouseController.addListener("pointermove", this.onMouseMove, viewport.getDomElement());
    });
    App.Instance().addEventListener("transformStart", this.onTransformStart);
  }
  deactivate() {
    this.hostObject.viewports.forEach((viewport) => {
      App.Instance().mouseController.removeListener("pointerover", this.onMouseOver, viewport.getDomElement());
      App.Instance().mouseController.removeListener("pointermove", this.onMouseMove, viewport.getDomElement());
      App.Instance().mouseController.removeListener("pointerleave", this.onMouseLeave, viewport.getDomElement());
    });
    App.Instance().removeEventListener("transformStart", this.onTransformStart);
    App.Instance().removeEventListener("transformEnd", this.onTransformEnd);
  }
  onTransformStart() {
    this.event = void 0;
    this.hostObject.viewports.forEach((viewport) => {
      App.Instance().mouseController.removeListener("pointerover", this.onMouseOver, viewport.getDomElement());
      App.Instance().mouseController.addListener("pointerover", this.onMouseOverWhileTransform, viewport.getDomElement());
    });
    App.Instance().addEventListener("transformEnd", this.onTransformEnd);
  }
  onTransformEnd() {
    this.hostObject.viewports.forEach((viewport) => {
      App.Instance().mouseController.removeListener("pointerover", this.onMouseOverWhileTransform, viewport.getDomElement());
    });
    App.Instance().removeEventListener("transformEnd", this.onTransformEnd);
    if (this.event) {
      this.onMouseOver(this.event);
    }
  }
  onMouseOverWhileTransform(event) {
    this.event = event;
  }
  onMouseOver(event) {
    for (let [name, viewport] of this.hostObject.viewports) {
      if (viewport.getDomElement() === event.target) {
        App.Instance().setActiveView(this.hostObject);
        this.hostObject.setActiveViewport(name);
        this.hostObject.viewports.forEach((viewport2) => {
          App.Instance().mouseController.removeListener("pointerover", this.onMouseOver, viewport2.getDomElement());
          App.Instance().mouseController.addListener("pointerleave", this.onMouseLeave, viewport2.getDomElement());
        });
        return true;
      }
    }
    this.hostObject.viewports.forEach((viewport) => {
      App.Instance().mouseController.removeListener("pointerleave", this.onMouseLeave, viewport.getDomElement());
      App.Instance().mouseController.addListener("pointerover", this.onMouseOver, viewport.getDomElement());
    });
    return false;
  }
  onMouseLeave(event) {
    for (let [name, viewport] of this.hostObject.viewports) {
      if (viewport.getDomElement() === event.relatedTarget) {
        App.Instance().setActiveView(this.hostObject);
        this.hostObject.setActiveViewport(name);
        return true;
      }
    }
    this.hostObject.viewports.forEach((viewport) => {
      App.Instance().mouseController.removeListener("pointerleave", this.onMouseLeave, viewport.getDomElement());
      App.Instance().mouseController.addListener("pointerover", this.onMouseOver, viewport.getDomElement());
    });
    this.hostObject.setActiveViewport(void 0);
    return false;
  }
  onMouseMove(event) {
    if (this.onMouseOver(event)) {
      this.hostObject.viewports.forEach((viewport) => {
        App.Instance().mouseController.removeListener("pointermove", this.onMouseMove, viewport.getDomElement());
      });
    }
  }
}

class HoverMask extends ViewTool {
  constructor(view) {
    super(view);
    this.onHover = this.onHover.bind(this);
    this.onUnhover = this.onUnhover.bind(this);
  }
  activate() {
    Viewer2D.addEventListener("hover", this.onHover);
    Viewer2D.addEventListener("unhover", this.onUnhover);
  }
  deactivate() {
    Viewer2D.removeEventListener("hover", this.onHover);
    Viewer2D.removeEventListener("unhover", this.onUnhover);
  }
  onHover(event) {
    if (event.entity instanceof Mask) {
      Viewer2D.dispatchEvent({ type: "hoverMask", maskId: event.entity.id });
    }
  }
  onUnhover(event) {
    if (event.entity instanceof Mask) {
      Viewer2D.dispatchEvent({ type: "hoverMask", maskId: void 0 });
    }
  }
}

const viewer2DViewTools = tuple(ActiveViewSwitcher2, UndoRedo, HoverMask);
const _Viewer2DViewMode = class extends Mode {
};
let Viewer2DViewMode = _Viewer2DViewMode;
Viewer2DViewMode.except = (tools) => new _Viewer2DViewMode({ tools: { toolList: tools, include: false } });
({
  mainMode: new Viewer2DViewMode(),
  contrastBrightnessMode: Viewer2DViewMode.except([HoverMask]),
  sharpnessMode: Viewer2DViewMode.except([HoverMask])
});
const viewer2DView = Viewer2D.view;

const IOXRayViewTools = tuple(...viewer2DViewTools);
const _IOXRayViewMode = class extends Mode {
};
let IOXRayViewMode = _IOXRayViewMode;
IOXRayViewMode.except = (tools) => new _IOXRayViewMode({ tools: { toolList: tools, include: false } });
const IOXRayViewModes = {
  mainMode: new IOXRayViewMode(),
  panZoomMode: new IOXRayViewMode(),
  contrastBrightnessMode: IOXRayViewMode.except([HoverMask]),
  sharpnessMode: IOXRayViewMode.except([HoverMask]),
  contrastBrightnessMultipleMode: IOXRayViewMode.except([HoverMask]),
  sharpnessMultipleMode: IOXRayViewMode.except([HoverMask])
};

class Zoom2D extends ViewportTool {
  constructor(viewport) {
    super(viewport);
    this.zoomSpeed = Math.pow(0.95, 0.4);
    this.maxZoom = Infinity;
    this.minZoom = 0;
    this.vector = new Vector3();
    this.handleMouseWheel = this.handleMouseWheel.bind(this);
    this.updateZoomType = this.updateZoomType.bind(this);
  }
  activate() {
    App.Instance().mouseController.addListener("wheel", this.handleMouseWheel, this.hostObject.domElement);
    this.hostObject.addEventListener("cameraChanged", this.updateZoomType);
    this.updateZoomType();
  }
  deactivate() {
    App.Instance().mouseController.removeListener("wheel", this.handleMouseWheel, this.hostObject.domElement);
    this.hostObject.removeEventListener("cameraChanged", this.updateZoomType);
  }
  get camera() {
    return this.hostObject.camera;
  }
  handleMouseWheel(event) {
    event.preventDefault();
    if (event.deltaY < 0) {
      this.zoom(this.zoomSpeed);
    } else if (event.deltaY > 0) {
      this.zoom(1 / this.zoomSpeed);
    }
    App.Instance().dispatchEvent({ type: "transformCamera", viewport: this.hostObject });
  }
  updateZoomType() {
    if (this.camera instanceof OrthographicCamera) {
      this.zoom = this.zoomForOrthographicCamera;
    } else {
      this.zoom = this.zoomForPerspectiveCamera;
    }
  }
  zoomForOrthographicCamera(scale) {
    this.camera.zoom = Math.max(this.minZoom, Math.min(this.maxZoom, this.camera.zoom / scale));
    this.camera.updateProjectionMatrix();
  }
  zoomForPerspectiveCamera(scale) {
    const pp = this.hostObject.cameraController.getPivotPoint();
    this.vector.copy(this.camera.position);
    this.camera.position.copy(pp).add(this.vector.sub(pp).multiplyScalar(scale));
    this.hostObject.cameraController.orthographicCameraHelper.updateDistanceFactor();
  }
}

class Pan2D extends ViewportTool {
  constructor(viewport) {
    super(viewport);
    this.panStart = new Vector2();
    this.panEnd = new Vector2();
    this.panDelta = new Vector2();
    this.panOffset = new Vector3();
    this.vector = new Vector3();
    this.handleLeftMouseButtonDown = this.handleLeftMouseButtonDown.bind(this);
    this.handleMiddleMouseButtonDown = this.handleMiddleMouseButtonDown.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseMoveOnce = this.handleMouseMoveOnce.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.updatePanType = this.updatePanType.bind(this);
  }
  get cameraController() {
    return this.hostObject.cameraController;
  }
  get camera() {
    return this.hostObject.camera;
  }
  get cameraX() {
    return this.hostObject.cameraController.xDir;
  }
  get cameraY() {
    return this.hostObject.cameraController.yDir;
  }
  get distanceFactor() {
    return this.hostObject.cameraController.getDistanceFactor();
  }
  activate() {
    App.Instance().mouseController.addListener("pointerdown", this.handleLeftMouseButtonDown, this.hostObject.getDomElement(), "left");
    App.Instance().mouseController.addListener("pointerdown", this.handleMiddleMouseButtonDown, this.hostObject.getDomElement(), "middle");
    this.updatePanType();
    this.hostObject.addEventListener("cameraChanged", this.updatePanType);
  }
  deactivate() {
    App.Instance().mouseController.removeListener("pointerdown", this.handleLeftMouseButtonDown, this.hostObject.getDomElement(), "left");
    App.Instance().mouseController.removeListener("pointerdown", this.handleMiddleMouseButtonDown, this.hostObject.getDomElement(), "middle");
    App.Instance().mouseController.removeListener("pointermove", this.handleMouseMoveOnce, document);
    this.hostObject?.removeEventListener("cameraChanged", this.updatePanType);
  }
  updatePanType() {
    if (this.camera instanceof OrthographicCamera) {
      this.panType = this.panForOrthographicCamera;
    } else {
      this.panType = this.panForPerspectiveCamera;
    }
  }
  pan(deltaX, deltaY) {
    this.panType(deltaX, deltaY);
    this.hostObject.camera.position.add(this.panOffset);
    this.panOffset.set(0, 0, 0);
    App.Instance().dispatchEvent({ type: "transformCamera", viewport: this.hostObject });
  }
  panForOrthographicCamera(deltaX, deltaY) {
    const sizeRatio = this.cameraController.orthographicCameraHelper.getSizeRatio();
    this.panOffset.copy(this.cameraX).multiplyScalar(-(deltaX * sizeRatio) / this.camera.zoom);
    this.panOffset.add(this.vector.copy(this.cameraY).multiplyScalar(deltaY * sizeRatio / this.camera.zoom));
  }
  panForPerspectiveCamera(deltaX, deltaY) {
    this.cameraController.perspectiveCameraHelper.updateDistanceFactor();
    this.panOffset.copy(this.cameraX).multiplyScalar(-deltaX * this.distanceFactor);
    this.panOffset.add(this.vector.copy(this.cameraY).multiplyScalar(deltaY * this.distanceFactor));
  }
  handleMouseMoveOnce(event) {
    this.panStart.set(event.clientX, event.clientY);
    App.Instance().mouseController.addListener("pointermove", this.handleMouseMove, document);
    App.Instance().mouseController.removeListener("pointermove", this.handleMouseMoveOnce, document);
  }
  handleMouseDown(event) {
    event.preventDefault();
    App.Instance().mouseController.addListener("pointermove", this.handleMouseMove, document);
    App.Instance().mouseController.addListener("pointerup", this.handleMouseUp, document, "left");
    App.Instance().mouseController.addListener("pointerup", this.handleMouseUp, document, "middle");
    this.panStart.set(event.clientX, event.clientY);
    App.Instance().dispatchEvent({ type: "transformCameraStart", viewport: this.hostObject });
  }
  handleLeftMouseButtonDown(event) {
    if (!event.shiftKey && this.enable) {
      this.handleMouseDown(event);
    }
  }
  handleMiddleMouseButtonDown(event) {
    if (!event.shiftKey && this.enable) {
      this.handleMouseDown(event);
    }
  }
  handleMouseMove(event) {
    this.panEnd.set(event.clientX, event.clientY);
    this.panDelta.subVectors(this.panEnd, this.panStart);
    this.pan(this.panDelta.x, this.panDelta.y);
    this.panStart.copy(this.panEnd);
  }
  handleMouseUp(event) {
    App.Instance().mouseController.removeListener("pointermove", this.handleMouseMove, document);
    App.Instance().mouseController.removeListener("pointerup", this.handleMouseUp, document, "left");
    App.Instance().mouseController.removeListener("pointerup", this.handleMouseUp, document, "middle");
    this.panStart.set(event.clientX, event.clientY);
    App.Instance().dispatchEvent({ type: "transformCameraEnd", viewport: this.hostObject });
  }
}

class Render extends ViewportTool {
  constructor(viewport) {
    super(viewport);
    this.render = this.render.bind(this);
  }
  activate() {
    App.Instance().addEventListener("resize", this.render);
    App.Instance().addEventListener("transformCamera", this.render);
    App.Instance().addEventListener("select", this.render);
    App.Instance().addEventListener("deselect", this.render);
    App.Instance().addEventListener("transform", this.render);
    App.Instance().addEventListener("viewportSchemeChanged", this.render);
    App.Instance().addEventListener("render", this.render);
  }
  deactivate() {
    App.Instance().removeEventListener("resize", this.render);
    App.Instance().removeEventListener("transformCamera", this.render);
    App.Instance().removeEventListener("select", this.render);
    App.Instance().removeEventListener("deselect", this.render);
    App.Instance().removeEventListener("transform", this.render);
    App.Instance().removeEventListener("viewportSchemeChanged", this.render);
    App.Instance().removeEventListener("render", this.render);
  }
  render() {
    this.hostObject.render();
  }
}

class Command {
}

class ContrastBrightnessCommand extends Command {
  constructor(initialBrightness, initialContrast, brightness, contrast, image, id) {
    super();
    this.initialBrightness = initialBrightness;
    this.initialContrast = initialContrast;
    this.brightness = brightness;
    this.contrast = contrast;
    this.image = image;
    this.id = id || getImageId(image);
  }
  execute() {
    this.image.brightness = this.brightness;
    this.image.contrast = this.contrast;
    Viewer2D.dispatchEvent({ type: "render" });
    Viewer2D.dispatchEvent({ type: "brightnessContrastChanged", id: this.id, brightness: this.brightness, contrast: this.contrast });
    return true;
  }
  undo() {
    this.image.brightness = this.initialBrightness;
    this.image.contrast = this.initialContrast;
    Viewer2D.dispatchEvent({ type: "render" });
    Viewer2D.dispatchEvent({ type: "brightnessContrastChanged", id: this.id, brightness: this.initialBrightness, contrast: this.initialContrast });
    return true;
  }
}

class ImageContrastBrightness extends ViewportTool {
  constructor(viewport) {
    super(viewport);
    this.initialCursor = "";
    this.start = new Vector2();
    this.delta = new Vector2();
    this.speed = 1.5;
    this.mouseDown = this.mouseDown.bind(this);
    this.mouseMove = this.mouseMove.bind(this);
    this.mouseUp = this.mouseUp.bind(this);
  }
  activate() {
    App.Instance().mouseController.addListener("pointerdown", this.mouseDown, this.hostObject.getDomElement(), "left", 20);
    this.initialCursor = this.hostObject.getDomElement().style.cursor;
    this.hostObject.getDomElement().style.cursor = "all-scroll";
  }
  deactivate() {
    App.Instance().mouseController.removeListener("pointerdown", this.mouseDown, this.hostObject.getDomElement(), "left");
    App.Instance().mouseController.removeListener("pointermove", this.mouseMove, this.hostObject.getDomElement());
    App.Instance().mouseController.removeListener("pointerup", this.mouseUp, this.hostObject.getDomElement(), "left");
    this.hostObject.getDomElement().style.cursor = this.initialCursor;
  }
  mouseDown(event) {
    if (event.shiftKey || event.ctrlKey || event.metaKey)
      return;
    this.start.x = event.clientX;
    this.start.y = event.clientY;
    const image = this.image || viewer2DView.getLayer("image")?.getObjects().find((image2) => image2.getComponent(ViewportComponent)?.viewports.has(this.hostObject));
    if (image instanceof XRayImage) {
      this.image = image;
      this.initialBrightness = this.image.brightness;
      this.initialContrast = this.image.contrast;
      App.Instance().mouseController.addListener("pointermove", this.mouseMove, document, 9);
      App.Instance().mouseController.addListener("pointerup", this.mouseUp, document, "left", 20);
    }
  }
  mouseMove(event) {
    event.preventDefault();
    this.delta.x = event.clientX - this.start.x;
    this.delta.y = -(event.clientY - this.start.y);
    this.delta.multiplyScalar(this.speed);
    this.image.brightness = MathUtils.clamp(this.image.brightness + this.delta.y / this.hostObject.rect.height, -1, 1);
    this.image.contrast = MathUtils.clamp(this.image.contrast + this.delta.x / this.hostObject.rect.width, -1, 1);
    this.start.x = event.clientX;
    this.start.y = event.clientY;
    App.Instance().dispatchEvent({ type: "render" });
  }
  mouseUp() {
    App.Instance().mouseController.removeListener("pointermove", this.mouseMove, document);
    App.Instance().mouseController.removeListener("pointerup", this.mouseUp, document, "left");
    const command = new ContrastBrightnessCommand(this.initialBrightness, this.initialContrast, this.image.brightness, this.image.contrast, this.image, this.hostObject.name);
    App.Instance().executeCommand(command, false);
    Viewer2D.dispatchEvent({ type: "brightnessContrastChanged", id: this.hostObject.name, brightness: this.image.brightness, contrast: this.image.contrast });
  }
}

class SharpnessCommand extends Command {
  constructor(initialSharpness, sharpness, image, id) {
    super();
    this.initialSharpness = initialSharpness;
    this.sharpness = sharpness;
    this.image = image;
    this.id = id || getImageId(image);
  }
  execute() {
    this.image.sharpness = this.sharpness;
    Viewer2D.dispatchEvent({ type: "render" });
    Viewer2D.dispatchEvent({ type: "sharpnessChanged", id: this.id, sharpness: this.sharpness });
    return true;
  }
  undo() {
    this.image.sharpness = this.initialSharpness;
    App.Instance().dispatchEvent({ type: "render" });
    Viewer2D.dispatchEvent({ type: "sharpnessChanged", id: this.id, sharpness: this.initialSharpness });
    return true;
  }
}

class ImageSharpness extends ViewportTool {
  constructor(viewport) {
    super(viewport);
    this.initialCursor = "";
    this.start = new Vector2();
    this.delta = new Vector2();
    this.speed = 5;
    this.mouseDown = this.mouseDown.bind(this);
    this.mouseMove = this.mouseMove.bind(this);
    this.mouseUp = this.mouseUp.bind(this);
  }
  activate() {
    App.Instance().mouseController.addListener("pointerdown", this.mouseDown, this.hostObject.getDomElement(), "left", 20);
    this.initialCursor = this.hostObject.getDomElement().style.cursor;
    this.hostObject.getDomElement().style.cursor = "all-scroll";
  }
  deactivate() {
    App.Instance().mouseController.removeListener("pointerdown", this.mouseDown, this.hostObject.getDomElement(), "left");
    App.Instance().mouseController.removeListener("pointermove", this.mouseMove, this.hostObject.getDomElement());
    App.Instance().mouseController.removeListener("pointerup", this.mouseUp, this.hostObject.getDomElement(), "left");
    this.hostObject.getDomElement().style.cursor = this.initialCursor;
  }
  mouseDown(event) {
    if (event.shiftKey || event.ctrlKey || event.metaKey)
      return;
    this.start.x = event.clientX;
    this.start.y = event.clientY;
    const image = this.image || viewer2DView.getLayer("image")?.getObjects().find((image2) => image2.getComponent(ViewportComponent)?.viewports.has(this.hostObject));
    if (image instanceof XRayImage) {
      this.image = image;
      this.initialSharpness = this.image.sharpness;
      App.Instance().mouseController.addListener("pointermove", this.mouseMove, document, 9);
      App.Instance().mouseController.addListener("pointerup", this.mouseUp, document, "left", 20);
    }
  }
  mouseMove(event) {
    event.preventDefault();
    this.delta.x = event.clientX - this.start.x;
    this.delta.y = -(event.clientY - this.start.y);
    this.delta.multiplyScalar(this.speed);
    this.image.sharpness = MathUtils.clamp(this.image.sharpness + this.delta.y / this.hostObject.rect.height, 0, 3);
    this.start.x = event.clientX;
    this.start.y = event.clientY;
    App.Instance().dispatchEvent({ type: "render" });
  }
  mouseUp() {
    App.Instance().mouseController.removeListener("pointermove", this.mouseMove, document);
    App.Instance().mouseController.removeListener("pointerup", this.mouseUp, document, "left");
    const command = new SharpnessCommand(this.initialSharpness, this.image.sharpness, this.image, this.hostObject.name);
    App.Instance().executeCommand(command, false);
    Viewer2D.dispatchEvent({ type: "sharpnessChanged", id: this.hostObject.name, sharpness: this.image.sharpness });
  }
}

class FitCameraToImage extends ViewportTool {
  constructor(viewport) {
    super(viewport);
    this.fit = this.fit.bind(this);
    this.setBox = this.setBox.bind(this);
    this.onAdd = this.onAdd.bind(this);
  }
  activate() {
    this.hostObject.addEventListener("setBox", this.setBox);
    if (this.image) {
      this.hostObject.addEventListener("resize", this.fit);
      return;
    }
    const image = getImageById(this.hostObject.name);
    if (image instanceof ImageEntity) {
      this.image = image;
      this.fit();
      this.hostObject.addEventListener("resize", this.fit);
      Viewer2D.dispatchEvent({ type: "render" });
    } else {
      this.hostObject.addEventListenerOnce("imageAdded", this.onAdd);
    }
  }
  deactivate() {
    this.hostObject.removeEventListener("resize", this.fit);
    this.hostObject.removeEventListener("setBox", this.setBox);
    this.hostObject.removeEventListener("imageAdded", this.onAdd);
    this.box = void 0;
  }
  onAdd(event) {
    this.image = event.image;
    this.fit();
    this.hostObject.addEventListener("resize", this.fit);
    Viewer2D.dispatchEvent({ type: "render" });
  }
  fit() {
    const camera = this.hostObject.cameraController.getOrthographicCamera();
    camera.updateMatrixWorld();
    const size = new Vector3();
    if (this.box) {
      const rotation = new Matrix4().makeRotationZ(camera.rotation.z);
      const box = this.box.clone().applyMatrix4(rotation);
      size.subVectors(box.max, box.min);
      camera.position.x = (this.box.min.x + this.box.max.x) / 2;
      camera.position.y = (this.box.min.y + this.box.max.y) / 2;
    } else {
      const x = new Vector3().setFromMatrixColumn(camera.matrixWorld, 0).multiplyScalar(this.image.getWidth());
      const y = new Vector3().setFromMatrixColumn(camera.matrixWorld, 1).multiplyScalar(this.image.getHeight());
      const diagonalA = x.clone().add(y);
      const diagonalB = x.clone().sub(y);
      size.set(Math.max(Math.abs(diagonalA.x), Math.abs(diagonalB.x)), Math.max(Math.abs(diagonalA.y), Math.abs(diagonalB.y)), 0);
      camera.position.x = 0;
      camera.position.y = 0;
    }
    const cameraWidth = camera.right - camera.left;
    const cameraHeight = camera.top - camera.bottom;
    camera.zoom = Math.min(cameraHeight / size.y, cameraWidth / size.x);
    camera.updateMatrix();
    camera.updateProjectionMatrix();
    Viewer2D.dispatchEvent({ type: "transformCamera", viewport: this.hostObject });
  }
  setBox(event) {
    const box = event.box;
    if (box) {
      const actualHeight = this.image.getHeight();
      const actualWidth = this.image.getWidth();
      const halfActualHeight = actualHeight / 2;
      const halfActualWidth = actualWidth / 2;
      const minY = halfActualHeight - box.max.y;
      const maxY = halfActualHeight - box.min.y;
      this.box = new Box3(new Vector3(box.min.x - halfActualWidth, minY, 0), new Vector3(box.max.x - halfActualWidth, maxY, 0));
    } else {
      this.box = box;
    }
    this.fit();
  }
}

const imageSpaceToGlobalSpace = (point, width, height) => {
  const x = (point.x || 0) - width / 2;
  const y = height / 2 - (point.y || 0);
  return { x, y };
};

class BBox extends Polyline {
  constructor(min, max, type, viewport) {
    var __super = (...args) => {
      super(...args);
    };
    if (min instanceof Vector3) {
      __super([min, new Vector3(), max, new Vector3(), min], type, viewport);
      this.min = min;
      this.max = max;
    } else {
      __super({ ...min, points: [min.min, { x: 0, y: 0, z: 0 }, min.max, { x: 0, y: 0, z: 0 }, min.min] }, max, type);
      this.min = this.points[0];
      this.max = this.points[2];
    }
    this.object3d.renderOrder = 20;
    this.update();
  }
  update() {
    this.updatePoints();
    super.update();
  }
  updatePoints() {
    this.points[1].x = this.max.x;
    this.points[1].y = this.min.y;
    this.points[3].x = this.min.x;
    this.points[3].y = this.max.y;
  }
}

class ConditionBoxesSpawner extends ViewportTool {
  constructor(viewport) {
    super(viewport);
    this.onAddBBoxes = this.onAddBBoxes.bind(this);
  }
  activate() {
    this.hostObject.addEventListener("addConditionBoxes", this.onAddBBoxes);
    this.image = getImageById(this.hostObject.name);
    if (!this.image) {
      this.hostObject.addEventListenerOnce("imageAdded", (e) => {
        this.image = e.image;
      }, 1);
    }
  }
  deactivate() {
    this.hostObject.removeEventListener("addConditionBoxes", this.onAddBBoxes);
    this.image = void 0;
  }
  onAddBBoxes(event) {
    if (this.image) {
      this.addBBoxes(event.props);
    } else {
      const unsubscribe = this.hostObject.addEventListenerOnce("imageAdded", () => {
        this.addBBoxes(event.props);
      });
      const dispose = Viewer2D.addEventListener("delete", (event2) => {
        if (event2.container === Viewer2D.view.getLayer("conditionBoxes")) {
          unsubscribe();
          dispose();
        }
      });
    }
  }
  addBBoxes(props) {
    const width = this.image.getWidth();
    const height = this.image.getHeight();
    const boxes = props.map((data) => {
      data.min = imageSpaceToGlobalSpace(data.min, width, height);
      data.max = imageSpaceToGlobalSpace(data.max, width, height);
      return new BBox(data, Line2, this.hostObject);
    });
    Viewer2D.view.getLayer("conditionBoxes")?.add(boxes);
  }
}

class MaskSpawner extends ViewportTool {
  constructor(viewport) {
    super(viewport);
    this.maskStorage = /* @__PURE__ */ new Map();
    this.onAddMasks = this.onAddMasks.bind(this);
  }
  activate() {
    this.hostObject.addEventListener("addMasks", this.onAddMasks);
    this.image = getImageById(this.hostObject.name);
    if (!this.image) {
      this.hostObject.addEventListenerOnce("imageAdded", (e) => {
        this.image = e.image;
      }, 1);
    }
  }
  deactivate() {
    this.hostObject.removeEventListener("addMasks", this.onAddMasks);
    this.image = void 0;
  }
  onAddMasks(event) {
    if (this.image) {
      this.addMasks(event.props, event.groupID);
    } else {
      this.hostObject.addEventListenerOnce("imageAdded", () => {
        this.addMasks(event.props, event.groupID);
      });
    }
  }
  smooth(points, times = 1) {
    for (let i = 0; i < times; i++) {
      points = points.map((point, i2, arr) => {
        const nextPoint = arr[(i2 + 1) % points.length];
        return { x: ((point.x || 0) + (nextPoint.x || 0)) / 2, y: ((point.y || 0) + (nextPoint.y || 0)) / 2 };
      });
    }
    return points;
  }
  addMasks(props, groupID) {
    const width = this.image.getWidth();
    const height = this.image.getHeight();
    const masks = props.map((maskProp) => {
      maskProp.points = this.smooth(maskProp.points, 4).map((item) => imageSpaceToGlobalSpace(item, width, height));
      return new Mask(maskProp, this.hostObject);
    });
    if (groupID) {
      let group = Viewer2D.view.getLayer("mask")?.getObjects().find((entity) => entity instanceof Wrapper && entity.id === groupID);
      if (group) {
        group.add(masks);
      } else {
        group = new Wrapper(masks, groupID);
        group.setComponent(RaycastChildrenOnly);
        Viewer2D.view.getLayer("mask")?.add(group);
      }
    } else {
      Viewer2D.view.getLayer("mask")?.add(masks);
    }
  }
}

class HoverTextureMask extends ViewportTool {
  constructor(viewport) {
    super(viewport);
    this.handleMouseMove = (() => {
      const quatInvert = new Quaternion();
      if (!this.image) {
        const dispose = Viewer2D.addEventListener("add", () => {
          if (this.image) {
            quatInvert.copy(this.image.object3d.quaternion).invert();
            Observer.observe(this.image.object3d.rotation, "z", () => {
              quatInvert.copy(this.image.object3d.quaternion).invert();
            });
            dispose();
          }
        });
      }
      return () => {
        const cursorPosition = Viewer2D.cursorController.position;
        this.position.set(cursorPosition.x, cursorPosition.y, 0.2).unproject(this.hostObject.camera).applyQuaternion(quatInvert);
        const { width, height } = this.idTexture.source.data;
        this.position.x = Math.floor(this.position.x + width / 2);
        this.position.y = Math.floor(this.position.y + height / 2);
        const red = this.position.y * width * 4 + this.position.x * 4;
        const maskId = this.idMap.get(this.idBuffer[red]);
        if (this.hovered !== maskId) {
          this.hovered = maskId;
          Viewer2D.dispatchEvent({ type: "hoverMask", maskId });
        }
      };
    })();
    this.idTextures = /* @__PURE__ */ new Map();
    this.position = new Vector3();
    this.makeIdMap = this.makeIdMap.bind(this);
    this.setIdMap = this.setIdMap.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.removeIdMap = this.removeIdMap.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }
  activate() {
    this.hostObject.addEventListener("generateFilter", this.makeIdMap);
    this.hostObject.addEventListener("applyFilter", this.setIdMap);
    this.hostObject.addEventListener("removeFilter", this.removeIdMap);
    this.hostObject.addEventListenerOnce("imageAdded", this.onAdd, 10);
  }
  deactivate() {
    this.hostObject.removeEventListener("generateFilter", this.makeIdMap);
    this.hostObject.removeEventListener("applyFilter", this.setIdMap);
    this.hostObject.removeEventListener("removeFilter", this.removeIdMap);
    Viewer2D.mouseController.removeListener("pointermove", this.handleMouseMove, this.hostObject.getDomElement());
    Viewer2D.mouseController.removeListener("pointerleave", this.handleMouseLeave, this.hostObject.getDomElement());
    this.hostObject.removeEventListener("resize", this.handleResize);
  }
  setIdMap(event) {
    const data = this.idTextures.get(event.filterId);
    if (data) {
      this.idTexture = data.texture;
      this.idBuffer = data.buffer;
      this.idMap = data.map;
      Viewer2D.mouseController.addListener("pointermove", this.handleMouseMove, this.hostObject.getDomElement());
      Viewer2D.mouseController.addListener("pointerleave", this.handleMouseLeave, this.hostObject.getDomElement());
      this.hostObject.addEventListener("resize", this.handleResize);
    }
  }
  removeIdMap() {
    this.idTexture = void 0;
    this.idBuffer = void 0;
    this.idMap = void 0;
    Viewer2D.mouseController.removeListener("pointermove", this.handleMouseMove, this.hostObject.getDomElement());
    Viewer2D.mouseController.removeListener("pointerleave", this.handleMouseLeave, this.hostObject.getDomElement());
    this.hostObject.removeEventListener("resize", this.handleResize);
  }
  handleMouseLeave() {
    if (this.hovered) {
      this.hovered = void 0;
      Viewer2D.dispatchEvent({ type: "hoverMask", maskId: void 0 });
    }
  }
  handleResize() {
    if (this.hostObject !== Viewer2D.view.getActiveViewport())
      return;
    this.handleMouseMove();
  }
  onAdd(event) {
    this.image = event.image;
  }
  async makeIdMap(event) {
    const image = this.image || getImageById(this.hostObject.name);
    if (!image) {
      this.hostObject.addEventListenerOnce("imageAdded", () => this.makeIdMap(event));
      return;
    }
    const filterId = event.filterID;
    const maskIds = event.config.map((x) => x.maskID);
    const cashed = this.idTextures.get(filterId);
    if (cashed) {
      return;
    }
    const map = /* @__PURE__ */ new Map();
    const data = maskIds.map((maskID, index) => {
      const id = 1 / maskIds.length * (index + 1) + 1e-6;
      const color = new Color(id, id, id);
      map.set(Math.round(id * 255), maskID);
      return { maskID, color };
    });
    this.image = image;
    const config = await image.prepareDataForFilter(data);
    const width = image.getWidth();
    const height = image.getHeight();
    const buffer = new Uint8Array(width * height * 4);
    const texture = renderFilter(width, height, config, { color: "black", opacity: 0, blending: false, buffer });
    this.idTextures.set(filterId, { texture, map, buffer });
  }
}

const _Viewer2DViewportMode = class extends Mode {
};
let Viewer2DViewportMode = _Viewer2DViewportMode;
Viewer2DViewportMode.except = (tools) => new _Viewer2DViewportMode({ tools: { toolList: tools, include: false } });
const viewer2DViewportServiceTools = /* @__PURE__ */ new Set([
  XRayImageSpawner,
  ConditionBoxesSpawner,
  MaskSpawner,
  Render,
  HoverTextureMask
]);
const viewer2DViewportTools = tuple(
  Pan2D,
  Zoom2D,
  ImageContrastBrightness,
  ImageSharpness,
  FitCameraToImage
);

class ImagePropsChangeInterceptor extends ViewportTool {
  constructor(viewport) {
    super(viewport);
    this.onAdd = this.onAdd.bind(this);
    this.onChangeContrastBrightness = this.onChangeContrastBrightness.bind(this);
    this.onChangeSharpness = this.onChangeSharpness.bind(this);
    this.onSetImage = this.onSetImage.bind(this);
  }
  activate() {
    const image = viewer2DView.getLayer("image")?.getObjects().find((image2) => image2.getComponent(ViewportComponent)?.viewports.has(this.hostObject));
    if (image instanceof ImageEntity) {
      this.uniform = image.object3d.material.uniforms;
      this.intercept();
    } else {
      Viewer2D.addEventListener("add", this.onAdd);
    }
    this.hostObject.addEventListener("setImage", this.onSetImage, 10);
    Viewer2D.addEventListener("brightnessContrastChanged", this.onChangeContrastBrightness, 10);
    Viewer2D.addEventListener("sharpnessChanged", this.onChangeSharpness, 10);
  }
  deactivate() {
    Viewer2D.removeEventListener("add", this.onAdd);
    this.hostObject.removeEventListener("setImage", this.onSetImage);
    Viewer2D.removeEventListener("brightnessContrastChanged", this.onChangeContrastBrightness);
    Viewer2D.removeEventListener("sharpnessChanged", this.onChangeSharpness);
    if (this.uniform) {
      this.uniform.brightness = { ...this.uniform.brightness };
      this.uniform.contrast = { ...this.uniform.contrast };
      this.uniform.sharpness = { ...this.uniform.sharpness };
    }
    Observer.stopObserving(XRayImageSpawner.screenProps, "brightness");
    Observer.stopObserving(XRayImageSpawner.screenProps, "contrast");
    Observer.stopObserving(XRayImageSpawner.screenProps, "sharpness");
  }
  onAdd(event) {
    const image = event.entities.find(
      (entity) => entity instanceof ImageEntity && entity.getComponent(ViewportComponent)?.viewports.has(this.hostObject)
    );
    if (image) {
      this.uniform = image.object3d.material.uniforms;
      this.intercept();
    }
  }
  onChangeContrastBrightness(event) {
    if (event.id !== this.hostObject.name)
      return;
    event.brightness -= XRayImageSpawner.screenProps.brightness;
    event.contrast -= XRayImageSpawner.screenProps.contrast;
  }
  onChangeSharpness(event) {
    if (event.id !== this.hostObject.name)
      return;
    event.sharpness -= XRayImageSpawner.screenProps.sharpness;
  }
  onSetImage(event) {
    event.props.brightness = event.props.brightness ?? 0;
    event.props.contrast = event.props.contrast ?? 0;
    event.props.sharpness = event.props.sharpness ?? 0;
    event.props.brightness += XRayImageSpawner.screenProps.brightness;
    event.props.contrast += XRayImageSpawner.screenProps.contrast;
    event.props.sharpness += XRayImageSpawner.screenProps.sharpness;
  }
  intercept() {
    this.uniform.brightness.screenPropsInterceptor = 0;
    this.uniform.contrast.screenPropsInterceptor = 0;
    this.uniform.sharpness.screenPropsInterceptor = 0;
    this.uniform.brightness = this.screenPropsAdditionBehavior(this.uniform.brightness, [-1, 1], "brightness");
    this.uniform.contrast = this.screenPropsAdditionBehavior(this.uniform.contrast, [-1, 1], "contrast");
    this.uniform.sharpness = this.screenPropsAdditionBehavior(this.uniform.sharpness, [-3, 3], "sharpness");
    Observer.observe(XRayImageSpawner.screenProps, "brightness", (val) => this.uniform.brightness.screenPropsInterceptor = val);
    Observer.observe(XRayImageSpawner.screenProps, "contrast", (val) => this.uniform.contrast.screenPropsInterceptor = val);
    Observer.observe(XRayImageSpawner.screenProps, "sharpness", (val) => this.uniform.sharpness.screenPropsInterceptor = val);
  }
  screenPropsAdditionBehavior(object, clampRange, screenProperty) {
    let valueStorage = object.value;
    const set = (target, prop, value) => {
      if (prop === "value") {
        valueStorage = value - XRayImageSpawner.screenProps[screenProperty];
        if (valueStorage <= clampRange[0] || valueStorage >= clampRange[1]) {
          valueStorage = MathUtils.clamp(valueStorage, clampRange[0], clampRange[1]);
          value = valueStorage + XRayImageSpawner.screenProps[screenProperty];
        }
        return Reflect.set(target, prop, MathUtils.clamp(value, clampRange[0], clampRange[1]));
      } else if (prop === "screenPropsInterceptor") {
        return Reflect.set(target, "value", MathUtils.clamp(valueStorage + value, clampRange[0], clampRange[1]));
      }
      return false;
    };
    return new Proxy(object, { set });
  }
  useGlobalPropertyBehavior(object, clampRange, screenProperty) {
    let valueStorage = object.value;
    let globalValue = XRayImageSpawner.screenProps[screenProperty];
    const set = (target, prop, value) => {
      value = MathUtils.clamp(value, clampRange[0], clampRange[1]);
      if (prop === "value") {
        valueStorage = value;
        return Reflect.set(target, prop, MathUtils.clamp(value, clampRange[0], clampRange[1]));
      } else if (prop === "screenPropsInterceptor") {
        if (valueStorage === globalValue) {
          valueStorage = globalValue = value;
        }
        return Reflect.set(target, "value", valueStorage);
      }
      return false;
    };
    return new Proxy(object, { set });
  }
}

class ScreenContrastBrightnessCommand extends Command {
  constructor(initialBrightness, initialContrast, brightness, contrast) {
    super();
    this.initialBrightness = initialBrightness;
    this.initialContrast = initialContrast;
    this.brightness = brightness;
    this.contrast = contrast;
  }
  execute() {
    XRayImageSpawner.screenProps.brightness = this.brightness;
    XRayImageSpawner.screenProps.contrast = this.contrast;
    IOXRay.dispatchEvent({ type: "render" });
    IOXRay.dispatchEvent({ type: "screenBrightnessContrastChanged", brightness: this.brightness, contrast: this.contrast });
    return true;
  }
  undo() {
    XRayImageSpawner.screenProps.brightness = this.initialBrightness;
    XRayImageSpawner.screenProps.contrast = this.initialContrast;
    IOXRay.dispatchEvent({ type: "render" });
    IOXRay.dispatchEvent({ type: "screenBrightnessContrastChanged", brightness: this.initialBrightness, contrast: this.initialContrast });
    return true;
  }
}

class ImageContrastBrightnessMultiple extends ViewportTool {
  constructor(viewport) {
    super(viewport);
    this.initialCursor = "";
    this.start = new Vector2();
    this.delta = new Vector2();
    this.speed = 1.5;
    this.mouseDown = this.mouseDown.bind(this);
    this.mouseMove = this.mouseMove.bind(this);
    this.mouseUp = this.mouseUp.bind(this);
  }
  activate() {
    App.Instance().mouseController.addListener("pointerdown", this.mouseDown, this.hostObject.getDomElement(), "left", 20);
    this.initialCursor = this.hostObject.getDomElement().style.cursor;
    this.hostObject.getDomElement().style.cursor = "all-scroll";
  }
  deactivate() {
    App.Instance().mouseController.removeListener("pointerdown", this.mouseDown, this.hostObject.getDomElement(), "left");
    App.Instance().mouseController.removeListener("pointermove", this.mouseMove, this.hostObject.getDomElement());
    App.Instance().mouseController.removeListener("pointerup", this.mouseUp, this.hostObject.getDomElement(), "left");
    this.hostObject.getDomElement().style.cursor = this.initialCursor;
  }
  set brightness(brightness) {
    XRayImageSpawner.screenProps.brightness = brightness;
  }
  get brightness() {
    return XRayImageSpawner.screenProps.brightness;
  }
  set contrast(contrast) {
    XRayImageSpawner.screenProps.contrast = contrast;
  }
  get contrast() {
    return XRayImageSpawner.screenProps.contrast;
  }
  mouseDown(event) {
    if (event.shiftKey || event.ctrlKey || event.metaKey)
      return;
    this.start.x = event.clientX;
    this.start.y = event.clientY;
    this.initialBrightness = this.brightness;
    this.initialContrast = this.contrast;
    App.Instance().mouseController.addListener("pointermove", this.mouseMove, document, 9);
    App.Instance().mouseController.addListener("pointerup", this.mouseUp, document, "left", 20);
  }
  mouseMove(event) {
    event.preventDefault();
    this.delta.x = event.clientX - this.start.x;
    this.delta.y = -(event.clientY - this.start.y);
    this.delta.multiplyScalar(this.speed);
    this.brightness = MathUtils.clamp(this.brightness + this.delta.y / this.hostObject.rect.height, -1, 1);
    this.contrast = MathUtils.clamp(this.contrast + this.delta.x / this.hostObject.rect.width, -1, 1);
    this.start.x = event.clientX;
    this.start.y = event.clientY;
    App.Instance().dispatchEvent({ type: "render" });
  }
  mouseUp() {
    App.Instance().mouseController.removeListener("pointermove", this.mouseMove, document);
    App.Instance().mouseController.removeListener("pointerup", this.mouseUp, document, "left");
    const command = new ScreenContrastBrightnessCommand(this.initialBrightness, this.initialContrast, this.brightness, this.contrast);
    App.Instance().executeCommand(command, false);
    IOXRay.dispatchEvent({ type: "screenBrightnessContrastChanged", brightness: this.brightness, contrast: this.contrast });
  }
}

class ScreenSharpnessCommand extends Command {
  constructor(initialSharpness, sharpness) {
    super();
    this.initialSharpness = initialSharpness;
    this.sharpness = sharpness;
  }
  execute() {
    XRayImageSpawner.screenProps.sharpness = this.sharpness;
    IOXRay.dispatchEvent({ type: "render" });
    IOXRay.dispatchEvent({ type: "screenSharpnessChanged", sharpness: this.sharpness });
    return true;
  }
  undo() {
    XRayImageSpawner.screenProps.sharpness = this.initialSharpness;
    App.Instance().dispatchEvent({ type: "render" });
    IOXRay.dispatchEvent({ type: "screenSharpnessChanged", sharpness: this.initialSharpness });
    return true;
  }
}

class ImageSharpnessMultiple extends ViewportTool {
  constructor(viewport) {
    super(viewport);
    this.initialCursor = "";
    this.start = new Vector2();
    this.delta = new Vector2();
    this.speed = 5;
    this.mouseDown = this.mouseDown.bind(this);
    this.mouseMove = this.mouseMove.bind(this);
    this.mouseUp = this.mouseUp.bind(this);
  }
  activate() {
    App.Instance().mouseController.addListener("pointerdown", this.mouseDown, this.hostObject.getDomElement(), "left", 20);
    this.initialCursor = this.hostObject.getDomElement().style.cursor;
    this.hostObject.getDomElement().style.cursor = "all-scroll";
  }
  deactivate() {
    App.Instance().mouseController.removeListener("pointerdown", this.mouseDown, this.hostObject.getDomElement(), "left");
    App.Instance().mouseController.removeListener("pointermove", this.mouseMove, this.hostObject.getDomElement());
    App.Instance().mouseController.removeListener("pointerup", this.mouseUp, this.hostObject.getDomElement(), "left");
    this.hostObject.getDomElement().style.cursor = this.initialCursor;
  }
  set sharpness(sharpness) {
    XRayImageSpawner.screenProps.sharpness = sharpness;
  }
  get sharpness() {
    return XRayImageSpawner.screenProps.sharpness;
  }
  mouseDown(event) {
    if (event.shiftKey || event.ctrlKey || event.metaKey)
      return;
    this.start.x = event.clientX;
    this.start.y = event.clientY;
    this.initialSharpness = this.sharpness;
    App.Instance().mouseController.addListener("pointermove", this.mouseMove, document, 9);
    App.Instance().mouseController.addListener("pointerup", this.mouseUp, document, "left", 20);
  }
  mouseMove(event) {
    event.preventDefault();
    this.delta.x = event.clientX - this.start.x;
    this.delta.y = -(event.clientY - this.start.y);
    this.delta.multiplyScalar(this.speed);
    this.sharpness = MathUtils.clamp(this.sharpness + this.delta.y / this.hostObject.rect.height, 0, 3);
    this.start.x = event.clientX;
    this.start.y = event.clientY;
    App.Instance().dispatchEvent({ type: "render" });
  }
  mouseUp() {
    App.Instance().mouseController.removeListener("pointermove", this.mouseMove, document);
    App.Instance().mouseController.removeListener("pointerup", this.mouseUp, document, "left");
    const command = new ScreenSharpnessCommand(this.initialSharpness, this.sharpness);
    App.Instance().executeCommand(command, false);
    IOXRay.dispatchEvent({ type: "screenSharpnessChanged", sharpness: this.sharpness });
  }
}

class StraightLineEntity extends LineLikeEntity {
  constructor(props, end, type, viewport) {
    var __super = (...args) => {
      super(...args);
    };
    let color;
    if (props instanceof Vector3) {
      __super(type, viewport);
      this.start = props;
      this.end = end;
      color = new Color(16777215);
    } else {
      __super(props, end, type);
      this.start = new Vector3(props.start.x, props.start.y, props.start.z);
      this.end = new Vector3(props.end.x, props.end.y, props.end.z);
      color = new Color(props.color || 16777215);
    }
    this.object3d.material.color = color;
  }
  update() {
    if (this.object3d instanceof Line) {
      this.updateLine();
    } else {
      this.updateLine2();
    }
  }
  updateLine() {
    const positions = this.object3d.geometry.getAttribute("position");
    positions.array.set([...this.start.toArray(), ...this.end.toArray()]);
    positions.needsUpdate = true;
  }
  updateLine2() {
    this.object3d.geometry.setPositions([...this.start.toArray(), ...this.end.toArray()]);
  }
}

class TextEntity extends Primitive {
  constructor(props = "", viewport) {
    var __super = (...args) => {
      super(...args);
    };
    if (props instanceof Text) {
      __super(props, viewport);
    } else {
      __super(new Text(), viewport);
    }
    let counter = 0;
    this.object3d.addEventListener("synccomplete", () => {
      counter--;
      if (counter === 0) {
        this.onSyncComplete.forEach((callback) => callback());
      }
    });
    this.object3d.addEventListener("syncstart", () => {
      counter++;
      this.onSyncStart.forEach((callback) => callback());
    });
    this.onSyncStart = /* @__PURE__ */ new Set();
    this.onSyncComplete = /* @__PURE__ */ new Set();
    if (typeof props === "string") {
      this.setProps({ text: props });
    } else if (!(props instanceof Text)) {
      this.setProps(props);
    }
  }
  setProps(props, callback) {
    let key;
    for (key in props) {
      this.object3d[key] = props[key];
    }
    this.object3d.sync(() => {
      callback && callback();
      App.Instance().dispatchEvent({ type: "render" });
    });
  }
  getSize() {
    const bBox = this.object3d.geometry.boundingBox;
    return {
      width: bBox.max.x - bBox.min.x,
      height: bBox.max.y - bBox.min.y
    };
  }
  toJson() {
    return {
      text: this.object3d.text,
      fontSize: this.object3d.fontSize,
      maxWidth: this.object3d.maxWidth,
      color: this.object3d.color,
      font: this.object3d.font,
      outlineBlur: this.object3d.outlineBlur,
      outlineOpacity: this.object3d.outlineOpacity,
      outlineColor: this.object3d.outlineColor,
      outlineWidth: this.object3d.outlineWidth,
      anchorX: this.object3d.anchorX,
      anchorY: this.object3d.anchorY,
      whiteSpace: this.object3d.whiteSpace
    };
  }
}

var fragmentShader$3 = "#define GLSLIFY 1\nposition=uTroikaOrient*position;float factor=viewport.w/600.0;float zoom=1.0/(projectionMatrix[1][1]*300.0)/factor;position.x*=zoom;position.y*=zoom;position.y-=0.1*zoom;"; // eslint-disable-line

const textMaterialZoomless = (base, viewport) => {
  base.onBeforeCompile = (shader) => {
    shader.uniforms.viewport = { value: viewport };
    shader.vertexShader = "uniform vec4 viewport;\n" + shader.vertexShader;
    shader.vertexShader = shader.vertexShader.replace("position = uTroikaOrient * position;", fragmentShader$3);
  };
  return base;
};

class TextEntityZoomless extends TextEntity {
  constructor(props = "", viewport) {
    super(props, viewport);
    this.setBehavior();
    this.setBehavior = this.setBehavior.bind(this);
    Observer.observe(this.object3d.layers, "mask", this.setBehavior);
  }
  setBehavior() {
    const bitCount = this.object3d.layers.mask.toString(2).match(/1/g)?.length;
    const isEntityInOneViewport = this.object3d.layers.mask !== 1 && bitCount === 1;
    if (isEntityInOneViewport) {
      const [firstViewport] = this.getComponent(ViewportComponent).viewports;
      this.object3d.material = new MeshBasicMaterial();
      this.object3d.material = textMaterialZoomless(this.object3d.material, firstViewport.size);
    } else {
      const vector = new Vector4();
      this.object3d.material = new MeshBasicMaterial();
      this.object3d.material = textMaterialZoomless(this.object3d.material, vector);
      this.object3d.onBeforeRender = (renderer, scene, camera, geometry, material, group) => {
        this.object3d.onBeforeRender(renderer, scene, camera, geometry, material, group);
        this.getLayer()?.getView().getRenderer().getViewport(vector);
      };
    }
  }
}

class LeadLine extends StraightLineEntity {
  constructor(start, end, type, text, viewport) {
    var __super = (...args) => {
      super(...args);
    };
    if (start instanceof Vector3) {
      __super(start, end, type, viewport);
      this.text = new TextEntityZoomless(text, viewport);
      this.setAlignment("center");
      this.textIndent = 5;
    } else {
      __super(start, end, type);
      this.text = new TextEntityZoomless(start.textProps, type);
      this.setAlignment(start?.textAliment || "center");
      this.textIndent = start?.textIndent || 5;
    }
    this.add(this.text);
  }
  update() {
    super.update();
    this.align();
  }
  setAlignment(textAliment) {
    this.textAlignment = textAliment;
    this.align = this.textAlignment === "left" ? this.alignLeft : this.textAlignment === "right" ? this.alignRight : this.alignCenter;
    this.align();
  }
  alignLeft() {
    this.text.object3d.position.set(this.start.x + this.textIndent, this.start.y + 50, 0);
  }
  alignRight() {
    this.text.object3d.position.set(this.end.x + this.textIndent, this.end.y + 50, 0);
  }
  alignCenter() {
    this.text.object3d.position.copy(this.start.clone().add(this.end).divideScalar(2));
  }
}

class Handle extends Primitive {
  constructor(point, viewport) {
    super(new Mesh(), viewport);
    this.point = point;
    this.object3d.position.copy(point);
  }
  bind() {
    Observer.observe(this.object3d.position, "x", () => this.point.x = this.object3d.position.x);
    Observer.observe(this.object3d.position, "y", () => this.point.y = this.object3d.position.y);
    Observer.observe(this.object3d.position, "z", () => this.point.z = this.object3d.position.z);
  }
  unBind() {
    Observer.stopObserving(this.object3d.position, "x");
    Observer.stopObserving(this.object3d.position, "y");
    Observer.stopObserving(this.object3d.position, "z");
  }
}

var vertexShader$2 = "#version 300 es\n#define GLSLIFY 1\nuniform mat4 projectionMatrix;uniform mat4 modelViewMatrix;uniform vec4 viewport;in vec3 position;in vec2 uv;out vec2 out_uv;void main(){float factor=viewport.w/600.0f;float zoom=1.0f/(projectionMatrix[1][1]*300.0f)/factor;out_uv=uv-0.5f;vec4 mvPosition=projectionMatrix*modelViewMatrix*(vec4(position,1.0f)*vec4(zoom,zoom,1.0f,1.0f));gl_Position=mvPosition;}"; // eslint-disable-line

var fragmentShader$2 = "#version 300 es\nprecision highp float;\n#define GLSLIFY 1\nuniform vec4 color;uniform vec4 outlineColor;in vec2 out_uv;out vec4 out_color;void main(){float r=0.5f;float outlineRadius=0.4f;float smoothness=0.01f;float distFromCenter=length(out_uv);if(distFromCenter>0.5f){discard;}float col=smoothstep(outlineRadius+smoothness,outlineRadius,distFromCenter);out_color=mix(color,outlineColor,col);}"; // eslint-disable-line

class RoundHandleMaterial extends RawShaderMaterial {
  constructor(color, viewport = new Vector4(), pixelSize = 1) {
    color = new Color(color);
    super({
      vertexShader: vertexShader$2,
      fragmentShader: fragmentShader$2,
      uniforms: {
        color: { value: new Vector4(color.r, color.g, color.b, 1) },
        outlineColor: { value: new Vector4(color.r, color.g, color.b, 1) },
        viewport: { value: viewport },
        pixelSize: { value: pixelSize }
      }
    });
    this.toneMapped = false;
  }
}

class RoundHandle extends Handle {
  constructor(point, viewport, props = { size: 10 }) {
    super(point, viewport);
    this.object3d.geometry = new PlaneGeometry();
    this.object3d.material = new RoundHandleMaterial(props.color || "red", this.getViewportSize());
    this.size = props.size;
  }
  set size(size) {
    this.object3d.scale.set(size, size, 1);
  }
  get size() {
    return this.object3d.scale.x;
  }
  toJson() {
    throw new Error("Method not implemented.");
  }
}

var vertexShader$1 = "#version 300 es\n#define GLSLIFY 1\nuniform mat4 projectionMatrix;uniform mat4 modelViewMatrix;uniform vec4 viewport;uniform vec3 size;in vec3 position;in vec2 uv;out vec2 coords;void main(){coords=uv*size.xy;float factor=viewport.w/600.0f;float zoom=1.0f/(projectionMatrix[1][1]*300.0f)/factor;vec4 mvPosition=projectionMatrix*modelViewMatrix*(vec4(position,1.0f)*vec4(zoom,zoom,1.0f,1.0f));gl_Position=mvPosition;}"; // eslint-disable-line

var fragmentShader$1 = "#version 300 es\nprecision highp float;\n#define GLSLIFY 1\nuniform vec4 color;uniform float radius;uniform vec3 size;in vec2 coords;out vec4 out_color;float sdRoundedBox(in vec2 p,in vec2 b,in vec4 r){r.xy=(p.x>0.0f)? r.xy : r.zw;r.x=(p.y>0.0f)? r.x : r.y;vec2 q=abs(p)-b+r.x;return min(max(q.x,q.y),0.0f)+length(max(q,0.0f))-r.x;}void main(){vec2 p=(2.0f*coords-size.xy)/size.y;vec2 b=vec2(size.x/size.y,1.0f);float box=sdRoundedBox(p,b,vec4(2.0f*radius/size.y));if(box>0.0f){discard;}out_color=color;}"; // eslint-disable-line

class RectangleHandleMaterial extends RawShaderMaterial {
  constructor(size, radius, color, viewport = new Vector4()) {
    color = new Color(color);
    super({
      vertexShader: vertexShader$1,
      fragmentShader: fragmentShader$1,
      uniforms: {
        size: { value: size },
        radius: { value: radius },
        color: { value: new Vector4(color.r, color.g, color.b, 1) },
        viewport: { value: viewport }
      }
    });
    this.toneMapped = false;
  }
}

class RectangleHandle extends Handle {
  constructor(point, viewport, props = { size: 1, width: 40, height: 18, radius: 9 }) {
    super(point, viewport);
    this.object3d.geometry = new PlaneGeometry();
    this.width = props.width;
    this.height = props.height;
    this.object3d.scale.set(this.width * props.size, this.height * props.size, 1);
    this.object3d.material = new RectangleHandleMaterial(this.object3d.scale, props.radius, props.color || "red", this.getViewportSize());
  }
  set size(size) {
    this.object3d.scale.multiplyScalar(size);
  }
  get size() {
    return this.object3d.scale.x / this.width;
  }
  set radius(radius) {
    this.object3d.material.uniforms.radius.value = radius;
  }
  get radius() {
    return this.object3d.material.uniforms.radius.value;
  }
  setWidth(width) {
    this.object3d.scale.x *= width / this.width;
    this.width = width;
  }
  setHeight(height) {
    this.object3d.scale.y *= height / this.height;
    this.height = height;
  }
  toJson() {
    throw new Error("Method not implemented.");
  }
}

const vector = new Vector3();
class RoundHandleRaycast extends Raycast {
  constructor(entity) {
    super(entity);
    if (!(entity instanceof RoundHandle)) {
      throw new Error("RoundHandle-only component");
    }
  }
  isRaycast(raycaster) {
    const activeViewport = App.Instance().getActiveView().getActiveViewport();
    if (activeViewport && this.entity.object3d.layers.test(activeViewport.camera.layers)) {
      let zoom = 1 / (activeViewport.camera.projectionMatrix.elements[5] * activeViewport.size.w);
      const radius = this.entity.size * zoom;
      vector.set(raycaster.ray.origin.x, raycaster.ray.origin.y, this.entity.object3d.position.z);
      if (vector.sub(this.entity.object3d.position).length() < radius) {
        return [{
          object: this.entity.object3d,
          distance: 0,
          point: new Vector3().set(raycaster.ray.origin.x, raycaster.ray.origin.y, this.entity.object3d.position.z)
        }];
      } else {
        return false;
      }
    }
    return false;
  }
}

class IncreaseRoundHandleSizeHighlighter extends Highlighter {
  constructor(entity) {
    super(entity);
    if (!(entity instanceof RoundHandle)) {
      throw new Error("RoundHandle-only component");
    }
    this.scale = new Vector3();
    this.increaseFactor = 1.3;
  }
  highlight() {
    this.scale.copy(this.entity.object3d.scale);
    this.entity.object3d.scale.multiplyScalar(this.increaseFactor);
  }
  unHighlight() {
    this.entity.object3d.scale.copy(this.scale);
  }
}

const vector3 = new Vector3();
class PBL extends LeadLine {
  constructor(start, end, viewport) {
    var __super = (...args) => {
      super(...args);
    };
    if (start instanceof Vector3) {
      __super(start, end, Line2, "", viewport);
      this.scale = 1;
    } else {
      viewport = end;
      if (!start.textProps) {
        start.textProps = {};
      }
      start.textProps.fontSize = start.textProps.fontSize || 14;
      start.textProps.color = start.textProps.color ?? 16777215;
      start.width = start.width ?? 2;
      __super(start, Line2, viewport);
      this.scale = start.scale;
    }
    const handleProps = { color: this.object3d.material.color.getHex(), size: 14 };
    this.startHandle = new RoundHandle(this.start, viewport, handleProps);
    this.endHandle = new RoundHandle(this.end, viewport, handleProps);
    this.textBackground = new RectangleHandle(this.start.clone().add(this.end).divideScalar(2), viewport, { ...handleProps, size: 1, width: 40, height: 18, radius: 9 });
    this.textBackground.size = 1;
    const horizontalPadding = 12;
    const verticalPadding = 2.2;
    this.text.onSyncComplete.add(() => {
      const textSize = this.text.getSize();
      this.textBackground.setWidth(textSize.width + horizontalPadding);
      this.textBackground.setHeight(textSize.height + verticalPadding);
    });
    this.text.setProps({
      text: this.getMeasure().toFixed(1).toString(),
      anchorX: "center",
      anchorY: "middle"
    });
    const rotation = this.getViewport().camera.rotation.z;
    this.text.object3d.rotation.z = rotation;
    this.textBackground.object3d.rotation.z = rotation;
    this.setRenderOrders();
    this.setComponents();
    this.setBehavior();
    this.makeTransparent();
    this.add([this.startHandle, this.endHandle, this.textBackground]);
    this.update = this.update.bind(this);
    this.update();
  }
  makeTransparent() {
    this.object3d.material.transparent = true;
    this.startHandle.object3d.material.transparent = true;
    this.endHandle.object3d.material.transparent = true;
    this.textBackground.object3d.material.transparent = true;
    this.text.object3d.material.transparent = true;
  }
  setRenderOrders() {
    this.object3d.renderOrder = 10;
    this.startHandle.object3d.renderOrder = 11;
    this.endHandle.object3d.renderOrder = 11;
    this.textBackground.object3d.renderOrder = 12;
    this.text.object3d.renderOrder = 13;
  }
  setComponents() {
    this.setComponent(RaycastChildrenOnly);
    this.startHandle.setComponent(HoverComponent);
    this.startHandle.setComponent(RoundHandleRaycast);
    this.startHandle.setComponent(HoverComponent, new HoverComponent(this.startHandle, new IncreaseRoundHandleSizeHighlighter(this.startHandle)));
    this.endHandle.setComponent(HoverComponent);
    this.endHandle.setComponent(RoundHandleRaycast);
    this.endHandle.setComponent(HoverComponent, new HoverComponent(this.endHandle, new IncreaseRoundHandleSizeHighlighter(this.endHandle)));
  }
  setBehavior() {
    this.startHandle.bind();
    this.endHandle.bind();
    this.startHandle.addEventListener("transform", () => this.update());
    this.endHandle.addEventListener("transform", () => this.update());
  }
  update() {
    super.update();
    this.textBackground.object3d.position.copy(this.start.clone().add(this.end).divideScalar(2));
    this.text.setProps({ text: this.getMeasure().toFixed(1).toString() });
  }
  getMeasure() {
    return vector3.subVectors(this.end, this.start).length() * this.scale;
  }
  showText() {
    this.add([this.text, this.textBackground]);
  }
  hideText() {
    this.delete([this.text, this.textBackground]);
  }
  showEndpoints() {
    this.startHandle.size = this.startHandle.object3d.userData.size || this.startHandle.size;
    this.endHandle.size = this.endHandle.object3d.userData.size || this.endHandle.size;
    this.startHandle.object3d.userData.size = void 0;
    this.endHandle.object3d.userData.size = void 0;
  }
  hideEndpoints() {
    this.startHandle.object3d.userData.size = this.startHandle.object3d.userData.size || this.startHandle.size;
    this.endHandle.object3d.userData.size = this.endHandle.object3d.userData.size || this.endHandle.size;
    this.startHandle.size = this.object3d.material.linewidth;
    this.endHandle.size = this.object3d.material.linewidth;
  }
  getViewport() {
    const [viewport] = this.getComponent(ViewportComponent).viewports;
    return viewport;
  }
}

class PBLSpawner extends ViewportTool {
  constructor(viewport) {
    super(viewport);
    this.updatePBLsView = () => {
      const PBLs = IOXRay.view.getLayer("pbl").getObjects();
      if (Viewer2D.view.viewports.size > 1 && [...Viewer2D.view.viewports.values()].every((viewport) => viewport.width > 0 && viewport.height > 0)) {
        PBLs.forEach((PBL2) => {
          PBL2.hideText();
          PBL2.hideEndpoints();
        });
      } else {
        PBLs.forEach((PBL2) => {
          PBL2.showText();
          PBL2.showEndpoints();
        });
      }
    };
    this.onAddPBLs = this.onAddPBLs.bind(this);
  }
  activate() {
    this.hostObject.addEventListener("addPBL", this.onAddPBLs);
    this.hostObject.addEventListener("resize", this.updatePBLsView);
    this.image = getImageById(this.hostObject.name);
    if (!this.image) {
      this.hostObject.addEventListenerOnce("imageAdded", (e) => {
        this.image = e.image;
      }, 1);
    }
  }
  deactivate() {
    this.hostObject.removeEventListener("addPBL", this.onAddPBLs);
    this.hostObject.removeEventListener("resize", this.updatePBLsView);
    this.image = void 0;
  }
  onAddPBLs(event) {
    if (this.image) {
      this.addPBLs(event.props);
    } else {
      this.hostObject.addEventListenerOnce("imageAdded", () => {
        this.addPBLs(event.props);
      });
    }
  }
  addPBLs(props) {
    const width = this.image.getWidth();
    const height = this.image.getHeight();
    const PBLs = props.map((data) => {
      data.start = imageSpaceToGlobalSpace(data.start, width, height);
      data.end = imageSpaceToGlobalSpace(data.end, width, height);
      return new PBL(data, this.hostObject);
    });
    IOXRay.view.getLayer("pbl")?.add(PBLs);
    this.updatePBLsView();
  }
}

class SelectComponent extends Component {
  constructor(entity, highlighter = new ColorHighlighter(entity)) {
    super(entity);
    this.selected = false;
    this.highlighter = highlighter;
  }
  select() {
    this.selected = true;
    this.highlighter.highlight();
  }
  deselect() {
    this.selected = false;
    this.highlighter.unHighlight();
  }
  isSelected() {
    return this.selected;
  }
}

class Drag extends ViewportTool {
  constructor(viewport) {
    super(viewport);
    this.entities = [];
    this.dragOffset = new Vector3();
    this.dragStart = new Vector2();
    this.dragEnd = new Vector2();
    this.dragDelta = new Vector2();
    this.vector = new Vector3();
    this.entities = [];
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onMouseMoveInitial = this.onMouseMoveInitial.bind(this);
  }
  activate() {
    App.Instance().mouseController.addListener("pointermove", this.onMouseMoveInitial, this.hostObject.getDomElement());
    App.Instance().mouseController.addListener("pointerdown", this.onMouseDown, this.hostObject.getDomElement(), "left", 10);
  }
  deactivate() {
    App.Instance().mouseController.removeListener("pointerdown", this.onMouseDown, this.hostObject.getDomElement(), "left");
    App.Instance().mouseController.removeListener("pointermove", this.onMouseMove, this.hostObject.getDomElement());
    App.Instance().mouseController.removeListener("pointermove", this.onMouseMoveInitial, this.hostObject.getDomElement());
    App.Instance().mouseController.removeListener("pointerup", this.onMouseUp, document, "left");
    App.Instance().mouseController.listeners.get(this.hostObject.getDomElement())?.enableButton("pointerdown", "left");
  }
  get camera() {
    return this.hostObject.camera;
  }
  get cameraX() {
    return this.hostObject.cameraController.xDir;
  }
  get cameraY() {
    return this.hostObject.cameraController.yDir;
  }
  setObjects() {
    const raycasted = App.Instance().raycastController.getObject();
    if (!raycasted) {
      this.entities.length = 0;
      return;
    }
    if (raycasted instanceof Handle && raycasted.getComponent(HoverComponent)) {
      this.entities = [raycasted];
    } else if (raycasted?.getComponent(SelectComponent)) {
      this.entities = this.hostObject.view.selectController.getSelected().map((selectComponent) => selectComponent.entity);
    } else {
      this.entities.length = 0;
    }
  }
  onMouseMoveInitial() {
    this.setObjects();
    if (this.entities.length) {
      this.hostObject.getDomElement().style.cursor = "pointer";
    } else {
      this.hostObject.getDomElement().style.cursor = "default";
    }
  }
  onMouseDown(event) {
    if (this.entities.length) {
      App.Instance().mouseController.listeners.get(this.hostObject.getDomElement())?.disableButton("pointerdown", "left");
      App.Instance().mouseController.removeListener("pointermove", this.onMouseMoveInitial, this.hostObject.getDomElement());
      App.Instance().mouseController.addListener("pointermove", this.onMouseMove, this.hostObject.getDomElement());
      App.Instance().mouseController.addListener("pointerup", this.onMouseUp, document, "left");
      this.dragStart.set(event.clientX, event.clientY);
      App.Instance().dispatchEvent({ type: "transformStart", entities: [...this.entities] });
      this.hostObject.getDomElement().style.cursor = "grabbing";
    }
  }
  onMouseMove(event) {
    this.dragEnd.set(event.clientX, event.clientY);
    this.dragDelta.subVectors(this.dragEnd, this.dragStart);
    this.drag(this.dragDelta.x, this.dragDelta.y);
    this.dragStart.copy(this.dragEnd);
    App.Instance().dispatchEvent({ type: "transform", entities: [...this.entities] });
  }
  onMouseUp() {
    App.Instance().mouseController.listeners.get(this.hostObject.getDomElement())?.enableButton("pointerdown", "left");
    App.Instance().mouseController.removeListener("pointermove", this.onMouseMove, this.hostObject.getDomElement());
    App.Instance().mouseController.removeListener("pointerup", this.onMouseUp, document, "left");
    App.Instance().mouseController.addListener("pointermove", this.onMouseMoveInitial, this.hostObject.getDomElement());
    App.Instance().dispatchEvent({ type: "transformEnd", entities: [...this.entities] });
    this.dragOffset.set(0, 0, 0);
    this.entities.length = 0;
    this.hostObject.getDomElement().style.cursor = "pointer";
  }
  drag(deltaX, deltaY) {
    this.dragOffset.set(0, 0, 0);
    const sizeRatio = this.hostObject.cameraController.orthographicCameraHelper.getSizeRatio();
    this.dragOffset.copy(this.cameraX).multiplyScalar(deltaX * sizeRatio / this.camera.zoom);
    this.dragOffset.add(this.vector.copy(this.cameraY).multiplyScalar(-deltaY * sizeRatio / this.camera.zoom));
    this.entities.forEach((entity) => {
      entity.object3d.position.add(this.dragOffset);
      entity.dispatchEvent({ type: "transform", entity });
    });
  }
}

class Hover extends ViewportTool {
  constructor(viewport) {
    super(viewport);
    this.onHover = this.onHover.bind(this);
    this.onUnhover = this.onUnhover.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onChangeActiveViewport = this.onChangeActiveViewport.bind(this);
  }
  activate() {
    App.Instance().addEventListener("hover", this.onHover);
    App.Instance().addEventListener("unhover", this.onUnhover);
    App.Instance().addEventListener("ActiveViewportWillChange", this.onChangeActiveViewport);
    App.Instance().mouseController.addListener("pointerdown", this.onMouseDown, this.hostObject.getDomElement(), "left", 15);
  }
  deactivate() {
    App.Instance().removeEventListener("hover", this.onHover);
    App.Instance().removeEventListener("unhover", this.onUnhover);
    App.Instance().removeEventListener("ActiveViewportWillChange", this.onChangeActiveViewport);
    App.Instance().mouseController.removeListener("pointerdown", this.onMouseDown, this.hostObject.getDomElement(), "left");
  }
  onChangeActiveViewport(event) {
    if (event.current === this.hostObject) {
      App.Instance().addEventListenerOnce("unhover", (event2) => {
        const hoverComponent = event2.entity.getComponent(HoverComponent);
        if (hoverComponent) {
          hoverComponent.unhover();
          App.Instance().dispatchEvent({ type: "render" });
        }
      });
    }
  }
  onHover(event) {
    if (App.Instance().getActiveView().getActiveViewport() !== this.hostObject || this.isSelected(event.entity))
      return;
    const hoverComponent = event.entity.getComponent(HoverComponent);
    if (hoverComponent) {
      hoverComponent.hover();
      App.Instance().dispatchEvent({ type: "render" });
    }
  }
  onUnhover(event) {
    if (App.Instance().getActiveView().getActiveViewport() !== this.hostObject || this.isSelected(event.entity))
      return;
    const hoverComponent = event.entity.getComponent(HoverComponent);
    if (hoverComponent) {
      hoverComponent.unhover();
      App.Instance().dispatchEvent({ type: "render" });
    }
  }
  onMouseDown() {
    const entity = App.Instance().raycastController.getObject();
    entity && entity.getComponent(Raycast) && entity.getComponent(SelectComponent) && this.onUnhover({ entity });
  }
  isSelected(entity) {
    const selectComponent = entity.getComponent(SelectComponent);
    return selectComponent && this.hostObject.view.selectController.has(selectComponent);
  }
}

const _IOXRayViewportMode = class extends Mode {
};
let IOXRayViewportMode = _IOXRayViewportMode;
IOXRayViewportMode.except = (tools) => new _IOXRayViewportMode({ tools: { toolList: tools, include: false } });
const IOXRayViewportServiceTools = /* @__PURE__ */ new Set([...viewer2DViewportServiceTools, ImagePropsChangeInterceptor, PBLSpawner]);
const IOXRayViewportTools = tuple(
  ...viewer2DViewportTools,
  ImageContrastBrightnessMultiple,
  ImageSharpnessMultiple,
  Drag,
  Hover
);
const IOXRayViewportModes = () => ({
  mainMode: new IOXRayViewportMode([FitCameraToImage, Drag, Hover, Zoom2D, Pan2D]),
  panZoomMode: new IOXRayViewportMode({ tools: { toolList: [Pan2D, Zoom2D], include: true } }),
  contrastBrightnessMode: new IOXRayViewportMode({ tools: { toolList: [ImageContrastBrightness], include: true } }),
  sharpnessMode: new IOXRayViewportMode({ tools: { toolList: [ImageSharpness], include: true } }),
  contrastBrightnessMultipleMode: new IOXRayViewportMode({ tools: { toolList: [ImageContrastBrightnessMultiple], include: true } }),
  sharpnessMultipleMode: new IOXRayViewportMode({ tools: { toolList: [ImageSharpnessMultiple], include: true } })
});

var vertexShader = "#version 300 es\n#define GLSLIFY 1\nuniform vec4 viewport;in vec3 position;in vec2 uv;out vec2 coords;void main(){coords=uv*vec2(viewport.zw);gl_Position=vec4(position.xy,-0.5f,1.0f);}"; // eslint-disable-line

var fragmentShader = "#version 300 es\nprecision highp float;\n#define GLSLIFY 1\nuniform vec4 color;uniform float radius;uniform vec4 viewport;in vec2 coords;out vec4 out_color;float sdRoundedBox(in vec2 p,in vec2 b,in vec4 r){r.xy=(p.x>0.0f)? r.xy : r.zw;r.x=(p.y>0.0f)? r.x : r.y;vec2 q=abs(p)-b+r.x;return min(max(q.x,q.y),0.0f)+length(max(q,0.0f))-r.x;}void main(){vec2 p=(2.0f*coords-viewport.zw)/viewport.w;vec2 b=vec2(viewport.z/viewport.w,1.0f);float box=sdRoundedBox(p,b,vec4(2.0f*radius/viewport.w));if(box<=0.0f){discard;}out_color=color;}"; // eslint-disable-line

class RoundCornerMaterial extends RawShaderMaterial {
  constructor(viewport, radius, color) {
    color = new Color(color);
    super({
      vertexShader,
      fragmentShader,
      uniforms: {
        viewport: { value: viewport },
        radius: { value: radius },
        color: { value: new Vector4(color.r, color.g, color.b, 1) }
      }
    });
    this.toneMapped = false;
  }
}

class RoundCornerViewportEntity extends Entity {
  constructor(viewport, radius, color) {
    super(new Mesh(new PlaneGeometry(2, 2), new RoundCornerMaterial(viewport.size, radius, color)));
    const viewportComponent = this.setComponent(ViewportComponent);
    viewportComponent.addViewport(viewport);
    this.object3d.renderOrder = 1e3;
    this.object3d.frustumCulled = false;
  }
  toJson() {
    throw new Error("Method not implemented.");
  }
}

const ioXRayStartups = [
  () => IOXRay.view.viewports.forEach((viewport) => {
    IOXRay.view.getLayer("roundCorners").add(new RoundCornerViewportEntity(viewport, 8, "black"));
  })
];

class Launcher {
  constructor(config) {
    this.app = App.Instance();
    this.view = this.app.view;
    this.listeners = config.appEventNames;
    this.layerNames = config.layerNames;
    this.viewTools = config.viewTools;
    this.modes = config.modes;
    this.viewportsEventNames = config.viewportsEventNames;
    this.viewportTools = config.viewportTools;
    this.viewportModes = config.viewportModes;
    this.startups = config.startups;
    this.viewportServiceTools = config.viewportServiceTools;
  }
  launch(domRefs, preserveDrawingBuffer ) {
    this.setup(domRefs);
    this.init(domRefs, preserveDrawingBuffer);
  }
  shutdown() {
    this.app.clear();
  }
  init(domRefs, preserveDrawingBuffer = false ) {
    this.app.init({
      container: domRefs.view,
      rendererProps: { canvas: domRefs.canvas, antialias: true, preserveDrawingBuffer }
    });
    this.view.viewports.forEach((viewport) => {
      viewport.init();
      viewport.setDomElement(domRefs.viewports[viewport.name]);
    });
    this.view.activateMode("mainMode");
    this.app.runStartups();
  }
  dispose() {
    this.view.viewports.forEach((viewport) => {
      viewport.dispose();
    });
    this.app.dispose();
  }
  setup(domRefs) {
    this.app.setListeners(this.listeners);
    this.setupView();
    this.setupViewports(domRefs.viewports);
    this.app.startupSystems.push(...this.startups);
  }
  setupView() {
    this.view.toolController.setTools(this.viewTools);
    this.view.toolController.setModes(this.modes);
    this.layerNames.forEach((name) => this.view.addLayer(name));
  }
  setupViewports(viewportRefs) {
    let key;
    for (key in viewportRefs) {
      const viewport = this.view.addViewport(key, viewportRefs[key], {}, this.viewportsEventNames);
      viewport.alpha = 0;
      if (Array.isArray(this.viewportTools)) {
        viewport.toolController.setTools(this.viewportTools);
      } else {
        viewport.toolController.setTools(this.viewportTools(key));
      }
      viewport.toolController.setModes(this.viewportModes(key));
      if (!this.viewportServiceTools) {
        return;
      }
      if (this.viewportServiceTools instanceof Set) {
        viewport.toolController.setServiceTools(this.viewportServiceTools);
      } else {
        viewport.toolController.setServiceTools(this.viewportServiceTools(key));
      }
    }
  }
}

const IOXRayLayerNames = [...viewer2DLayerNames, "pbl"];
const IOXRayConfig = {
  appEventNames: ioXRayEventNames,
  layerNames: IOXRayLayerNames,
  viewTools: IOXRayViewTools,
  modes: IOXRayViewModes,
  viewportsEventNames: ioXRayViewportEventNames,
  viewportTools: IOXRayViewportTools,
  viewportModes: IOXRayViewportModes,
  startups: ioXRayStartups,
  viewportServiceTools: IOXRayViewportServiceTools
};
const IOXRay = App.Instance();
const IOXRayLauncher = new Launcher(IOXRayConfig);

class IOXRayAPI extends ImageViewerAPI {
  constructor(launcher) {
    super(launcher || IOXRayLauncher);
  }
  setImages(data) {
    return Promise.all(setImages(data));
  }
  addMasks(data) {
    data.forEach((item) => {
      const maskProps = item.config.filter((item2) => item2.path.length).map((item2) => ({
        points: item2.path,
        id: item2.maskID,
        color: item2.outlineColor,
        fillColor: item2.color,
        fillOpacity: item2.opacity,
        width: item2.outlineWidth || 3,
        renderOrder: item2.renderOrder
      }));
      if (!maskProps.length) {
        return;
      }
      const viewport = Viewer2D.view.getViewport(item.imageID);
      if (viewport) {
        viewport.dispatchEvent({ type: "addMasks", props: maskProps, groupID: item.groupID });
      }
    });
    IOXRay.dispatchEvent({ type: "render" });
  }
  setPBLs(data) {
    data.forEach((item) => {
      const props = item.PBLs.map((pblData) => ({
        start: pblData.start,
        end: pblData.end,
        scale: item.scale,
        color: pblData.color,
        textProps: pblData.textProps
      }));
      IOXRay.view.getViewport(item.imageID).dispatchEvent({ type: "addPBL", props });
    });
    IOXRay.dispatchEvent({ type: "render" });
  }
  showPBLs() {
    IOXRay.view.getLayer("pbl")?.show();
    IOXRay.dispatchEvent({ type: "render" });
  }
  hidePBLs() {
    IOXRay.view.getLayer("pbl")?.hide();
    IOXRay.dispatchEvent({ type: "render" });
  }
  addEventListener(type, callback, priority) {
    return IOXRay.addEventListener(type, callback, priority);
  }
  removeEventListener(type, callback, force) {
    IOXRay.removeEventListener(type, callback);
  }
}

const PanoUIEventNames = [
  ...viewer2DUIEventNames
];
const PanoEventNames = [
  ...viewer2DEventNames,
  ...PanoUIEventNames
];
const PanoViewportEventNames = [
  ...viewer2DViewportEventNames
];

const PanoViewTools = tuple(...viewer2DViewTools);
const _PanoViewMode = class extends Mode {
};
let PanoViewMode = _PanoViewMode;
PanoViewMode.except = (tools) => new _PanoViewMode({ tools: { toolList: tools, include: false } });
const PanoViewModes = {
  mainMode: new PanoViewMode(),
  contrastBrightnessMode: PanoViewMode.except([HoverMask]),
  sharpnessMode: PanoViewMode.except([HoverMask])
};

const _PanoViewportMode = class extends Mode {
};
let PanoViewportMode = _PanoViewportMode;
PanoViewportMode.except = (tools) => new _PanoViewportMode({ tools: { toolList: tools, include: false } });
const PanoViewportServiceTools = /* @__PURE__ */ new Set([...viewer2DViewportServiceTools]);
const PanoViewportTools = tuple(
  ...viewer2DViewportTools,
  Hover
);
const PanoViewportModes = () => ({
  mainMode: new PanoViewportMode([FitCameraToImage, Pan2D, Zoom2D, Hover]),
  contrastBrightnessMode: new PanoViewportMode({ tools: { toolList: [ImageContrastBrightness], include: true } }),
  sharpnessMode: new PanoViewportMode({ tools: { toolList: [ImageSharpness], include: true } })
});

const PanoStartups = [
  () => Pano.view.viewports.forEach((viewport) => {
    Pano.view.getLayer("roundCorners").add(new RoundCornerViewportEntity(viewport, 8, "black"));
  })
];

const PanoLayerNames = [...viewer2DLayerNames];
const PanoConfig = {
  appEventNames: PanoEventNames,
  layerNames: PanoLayerNames,
  viewTools: PanoViewTools,
  modes: PanoViewModes,
  viewportsEventNames: PanoViewportEventNames,
  viewportTools: PanoViewportTools,
  viewportModes: PanoViewportModes,
  startups: PanoStartups,
  viewportServiceTools: viewer2DViewportServiceTools
};
const Pano = App.Instance();
const PanoLauncher = new Launcher(PanoConfig);

class PanoAPI extends ImageViewerAPI {
  constructor() {
    super(PanoLauncher);
  }
  setViewRef(ref) {
    super.setViewRef(ref);
  }
  setCanvasRef(ref) {
    super.setCanvasRef(ref);
  }
  setViewportRef(ref) {
    super.setViewportRef(ref, "pano");
  }
  getViewportRef() {
    return this.domRefsMap.get("main")?.viewports.get("pano")?.proxy;
  }
  addMasks(data) {
    const maskProps = data.config.filter((item) => item.path.length).map((item) => ({
      points: item.path,
      id: item.maskID,
      color: item.outlineColor,
      fillColor: item.color,
      fillOpacity: item.opacity,
      width: item.outlineWidth || 3,
      renderOrder: item.renderOrder
    }));
    if (!maskProps.length) {
      return;
    }
    const viewport = Viewer2D.view.getViewport("pano");
    if (viewport) {
      viewport.dispatchEvent({ type: "addMasks", props: maskProps, groupID: data.groupID });
    }
    Pano.dispatchEvent({ type: "render" });
  }
  setPano(props, callback) {
    const onChangeViewOptions = (e) => {
      if (e.id === "pano") {
        e.id = props.id;
      }
    };
    Viewer2D.addEventListener("brightnessContrastChanged", onChangeViewOptions, 20);
    Viewer2D.addEventListener("sharpnessChanged", onChangeViewOptions, 20);
    return setImage(props, callback, "pano");
  }
  setBox(box) {
    super.setBox(box, "pano");
  }
  generateFilter(data) {
    super.generateFilter({ ...data, imageID: "pano" });
  }
  applyFilter(filterId) {
    super.applyFilter(filterId, "pano");
  }
  removeFilters() {
    super.removeFilters();
  }
  deleteFilterFromCache(filterId) {
    return super.deleteFilterFromCache(filterId, "pano");
  }
  addConditionBoxes(data) {
    data.forEach((item) => {
      const viewport = Viewer2D.view.getViewport("pano");
      viewport.dispatchEvent({ type: "addConditionBoxes", props: item.boxes });
    });
    Viewer2D.dispatchEvent({ type: "render" });
  }
  addEventListener(type, callback, priority) {
    return Pano.addEventListener(type, callback, priority);
  }
  removeEventListener(type, callback, force) {
    Pano.removeEventListener(type, callback, force);
  }
}

const PanoBitewingsUIEventNames = [
  ...viewer2DUIEventNames,
  "screenBrightnessContrastChanged",
  "screenSharpnessChanged"
];
const PanoBitewingsEventNames = [
  ...viewer2DEventNames,
  ...PanoBitewingsUIEventNames
];
const PanoBitewingsViewportEventNames = [
  ...viewer2DViewportEventNames
];

const PanoBitewingsViewTools = tuple(...viewer2DViewTools);
const _PanoBitewingsViewMode = class extends Mode {
};
let PanoBitewingsViewMode = _PanoBitewingsViewMode;
PanoBitewingsViewMode.except = (tools) => new _PanoBitewingsViewMode({ tools: { toolList: tools, include: false } });
const ioXRayViewModes = {
  mainMode: new PanoBitewingsViewMode(),
  panZoomMode: new PanoBitewingsViewMode(),
  contrastBrightnessMode: PanoBitewingsViewMode.except([HoverMask]),
  sharpnessMode: PanoBitewingsViewMode.except([HoverMask]),
  contrastBrightnessMultipleMode: PanoBitewingsViewMode.except([HoverMask]),
  sharpnessMultipleMode: PanoBitewingsViewMode.except([HoverMask])
};

new Mode([Pan2D, Zoom2D]);
const PanoBitewingsViewportTools = (key) => {
  if (key === "pano") {
    return PanoViewportTools;
  }
  return IOXRayViewportTools;
};
const PanoBitewingsViewportModes = (key) => {
  if (key === "pano") {
    return {
      ...PanoViewportModes(),
      panZoomMode: new PanoViewportMode([Pan2D, Zoom2D]),
      contrastBrightnessMultipleMode: new PanoViewportMode([ImageContrastBrightness]),
      sharpnessMultipleMode: new PanoViewportMode([ImageSharpness])
    };
  }
  return IOXRayViewportModes();
};
const PanoBitewingsViewportServiceTools = (key) => {
  if (key === "pano") {
    return PanoViewportServiceTools;
  }
  return IOXRayViewportServiceTools;
};

const PanoBitewingsStartups = [
  () => PanoBitewings.view.viewports.forEach((viewport) => {
    PanoBitewings.view.getLayer("roundCorners").add(new RoundCornerViewportEntity(viewport, 8, "black"));
  })
];

const PanoBitewingsLayerNames = [...viewer2DLayerNames, "pbl"];
const PanoBitewingsConfig = {
  appEventNames: PanoBitewingsEventNames,
  layerNames: PanoBitewingsLayerNames,
  viewTools: PanoBitewingsViewTools,
  modes: ioXRayViewModes,
  viewportsEventNames: PanoBitewingsViewportEventNames,
  viewportTools: PanoBitewingsViewportTools,
  viewportModes: PanoBitewingsViewportModes,
  startups: PanoBitewingsStartups,
  viewportServiceTools: PanoBitewingsViewportServiceTools
};
const PanoBitewings = App.Instance();
const PanoBitewingsLauncher = new Launcher(PanoBitewingsConfig);

class PanoBitewingsAPI extends ImageViewerAPI {
  constructor() {
    super(PanoBitewingsLauncher);
  }
  setImages(data) {
    this.panoID = data.panoProps.id;
    const result = setImages({ props: data.bitewingProps, callback: data.callback });
    result.push(setImage(data.panoProps, data.callback, "pano"));
    const onChangeViewOptions = (e) => {
      if (e.id === "pano") {
        e.id = this.panoID;
      }
    };
    Viewer2D.addEventListener("brightnessContrastChanged", onChangeViewOptions, 20);
    Viewer2D.addEventListener("sharpnessChanged", onChangeViewOptions, 20);
    return Promise.all(result);
  }
  addMasks(data) {
    data.forEach((item) => {
      const maskProps = item.config.filter((item2) => item2.path.length).map((item2) => ({
        points: item2.path,
        id: item2.maskID,
        color: item2.outlineColor,
        fillColor: item2.color,
        fillOpacity: item2.opacity,
        width: item2.outlineWidth || 3,
        renderOrder: item2.renderOrder
      }));
      if (!maskProps.length) {
        return;
      }
      const imageID = item.imageID === this.panoID ? "pano" : item.imageID;
      const viewport = Viewer2D.view.getViewport(imageID);
      if (viewport) {
        viewport.dispatchEvent({ type: "addMasks", props: maskProps, groupID: item.groupID });
      }
    });
    PanoBitewings.dispatchEvent({ type: "render" });
  }
  generateFilter(data) {
    const { filterID, config, callback, imageID } = data;
    if (imageID == this.panoID) {
      super.generateFilter({ filterID, config, callback, imageID: "pano" });
    } else {
      super.generateFilter(data);
    }
  }
  setPBLs(data) {
    data.forEach((item) => {
      if (item.imageID === this.panoID) {
        return;
      }
      const props = item.PBLs.map((pblData) => ({
        start: pblData.start,
        end: pblData.end,
        scale: item.scale,
        color: pblData.color,
        textProps: pblData.textProps
      }));
      PanoBitewings.view.getViewport(item.imageID).dispatchEvent({ type: "addPBL", props });
    });
  }
  showPBLs() {
    PanoBitewings.view.getLayer("pbl")?.show();
    PanoBitewings.dispatchEvent({ type: "render" });
  }
  hidePBLs() {
    PanoBitewings.view.getLayer("pbl")?.hide();
    PanoBitewings.dispatchEvent({ type: "render" });
  }
  addConditionBoxes(data) {
    data.forEach((item) => {
      const viewport = Viewer2D.view.getViewport(item.imageID === this.panoID ? "pano" : item.imageID);
      viewport.dispatchEvent({ type: "addConditionBoxes", props: item.boxes });
    });
    Viewer2D.dispatchEvent({ type: "render" });
  }
  addEventListener(type, callback, priority) {
    return PanoBitewings.addEventListener(type, callback, priority);
  }
  removeEventListener(type, callback, force) {
    PanoBitewings.removeEventListener(type, callback);
  }
}

const IOXRayRender = new IOXRayAPI();
const PanoRender = new PanoAPI();
const PanoBitewingsRender = new PanoBitewingsAPI();

export { IOXRayRender, PanoBitewingsRender, PanoRender };
