import { FC, RefObject, useEffect, useState } from 'react';
import cn from 'classnames';

import { useAppSelector } from '@/shared/hooks';
import {
  IOXRayRender,
  PanoBitewingsRender,
} from '@/shared/graphics/viewer2D/Viewer2D';
import {
  AxisSlice,
  BBox,
} from '@/shared/api/protocol_gen/model/dto_common_geometry';
import { ReportType } from '@/shared/api/protocol_gen/model/dto_report';
import { Localization } from '@/shared/api/protocol_gen/model/dto_report_localization';
import { AssetType } from '@/shared/api/protocol_gen/model/dto_asset';

import { toothModel } from '@/entities/tooth';
import { reportsModel } from '@/entities/reports';
import {
  IOXRayImagesInterfaceModel,
  IOXrayImageInterface,
  groupIOXRayImagesByPartition,
} from '@/entities/IOXRayImagesMatrix';

import styles from './CroppedImagesView.module.scss';

type CroppedImagesViewProps = {
  className?: string;
  mainViewRef: RefObject<HTMLDivElement>;
};

const getSizeFromBBox = (
  BBox: BBox | undefined,
): { width: number; height: number } => {
  if (!BBox) {
    return { width: 0, height: 0 };
  }

  const width = (BBox.X?.Max as number) - (BBox.X?.Min as number);
  const height = (BBox.Y?.Max as number) - (BBox.Y?.Min as number);

  return { width, height };
};

const getPanoBBoxScale = (
  panoLocalization: Localization,
  contWidth: number,
  contHeight: number,
) => {
  const { width, height } = getSizeFromBBox(panoLocalization?.BBox);

  const scaleByContHeight = contHeight / height;
  const scaleByQuaterContWidth = contWidth / 3 / width;

  const scaledWidth = width * scaleByContHeight;

  const resultScale =
    scaledWidth > contWidth / 3 ? scaleByQuaterContWidth : scaleByContHeight;

  return resultScale;
};

export const CroppedImagesView: FC<CroppedImagesViewProps> = (props) => {
  const { className, mainViewRef } = props;

  const reportID = useAppSelector(
    reportsModel.selectors.selectCurrentReportID,
  ) as string;

  const reportType = useAppSelector(
    reportsModel.selectors.selectCurrentReportType,
  );

  const isIOXRayReport = reportType === ReportType.ReportType_IOXRay_GP;

  const activeToothID = useAppSelector(
    reportsModel.selectors.selectActiveToothID,
  );
  const activeTooth = useAppSelector(
    toothModel.selectors.selectToothByID(activeToothID),
  );

  const IOXRayImagesInterface = useAppSelector(
    IOXRayImagesInterfaceModel.selectors.selectIOXRayImagesInterfaceByReportID(
      reportID || '',
    ),
  );

  const panoImageInterface = useAppSelector(
    IOXRayImagesInterfaceModel.selectors.selectPanoImageInterfaceByReportID(
      reportID,
    ),
  ) as IOXrayImageInterface;

  const groupedIOXRayImages = groupIOXRayImagesByPartition(
    IOXRayImagesInterface as IOXrayImageInterface[],
  );

  const bitewingsAssetIDs = [
    ...groupedIOXRayImages['MiddleLeft'],
    ...groupedIOXRayImages['MiddleRight'],
  ].map((imageItem) => imageItem.asset.ID);

  const localizations = activeTooth?.Localizations || [];

  const { bitewingsLocalizations, simpleLocalizations, panoLocalizations } =
    localizations.reduce(
      (accum, localization) => {
        if (bitewingsAssetIDs.includes(localization.TargetAssetID)) {
          return {
            ...accum,
            bitewingsLocalizations: [
              ...accum.bitewingsLocalizations,
              localization,
            ],
          };
        }

        if (localization.TargetAssetID === panoImageInterface?.asset.ID) {
          return {
            ...accum,
            panoLocalizations: [...accum.panoLocalizations, localization],
          };
        }

        return {
          ...accum,
          simpleLocalizations: [...accum.simpleLocalizations, localization],
        };
      },
      {
        bitewingsLocalizations: [],
        simpleLocalizations: [],
        panoLocalizations: [],
      } as {
        bitewingsLocalizations: Localization[];
        simpleLocalizations: Localization[];
        panoLocalizations: Localization[];
      },
    );

  // TODO: [4|h] need to move to selectors all logic to separate panowing and ioxray localizations
  const localizationsFilteredByReportType = isIOXRayReport
    ? localizations
    : [...panoLocalizations, ...bitewingsLocalizations];

  const Render = isIOXRayReport ? IOXRayRender : PanoBitewingsRender;

  // apply tooth crop
  useEffect(() => {
    const resetCrops = () => {
      localizationsFilteredByReportType?.forEach((localization) => {
        const imageMetaID =
          localization.TargetAssetID === panoImageInterface?.asset.ID
            ? 'pano'
            : IOXRayImagesInterface.find(
                (item) => item.asset.ID === localization.TargetAssetID,
              )?.imageMeta.ID || '';

        Render.lookAtBox(undefined, imageMetaID);
      });
    };

    if (Render.isRunning()) {
      // set crop
      if (activeTooth) {
        localizationsFilteredByReportType?.forEach((localization) => {
          // TODO: refactoring after render api changing, use pano asset id instead of 'pano'
          const imageMetaID =
            localization.TargetAssetID === panoImageInterface?.asset.ID
              ? 'pano'
              : IOXRayImagesInterface.find(
                  (item) => item.asset.ID === localization.TargetAssetID,
                )?.imageMeta.ID || '';

          const BBox = localization.BBox as BBox;
          const X = BBox.X as AxisSlice;
          const Y = BBox.Y as AxisSlice;

          Render.lookAtBox(
            {
              min: { x: X?.Min, y: Y?.Min },
              max: { x: X?.Max, y: Y?.Max },
            },

            imageMetaID,
          );
        });
      } else if (!activeTooth) {
        resetCrops();
      }
    }

    return () => {
      resetCrops();
    };
  }, [
    activeTooth,
    localizations,
    Render,
    IOXRayImagesInterface,
    localizationsFilteredByReportType,
    panoImageInterface?.asset.ID,
  ]);

  const [{ contHeight, contWidth }, setContSize] = useState({
    contWidth: 0,
    contHeight: 0,
  });

  const panoScale = getPanoBBoxScale(
    panoLocalizations[0],
    contWidth,
    contHeight,
  );

  const calculateScale = () => {
    const wrapperWidth = contWidth;
    const wrapperHeight = contHeight;

    if (!wrapperWidth && !wrapperHeight) return 0;

    const originalSimpleImagesMaxSize =
      localizationsFilteredByReportType?.reduce(
        (accum, localization, index) => {
          const { width, height } = getSizeFromBBox(localization.BBox);

          // add panoScale for pano localization
          if (!isIOXRayReport && index === 0) {
            return {
              maxWidth: accum.maxWidth + width * panoScale,
              maxHeight: accum.maxHeight + height * panoScale,
            };
          }

          return {
            maxWidth: accum.maxWidth + width,
            maxHeight: accum.maxHeight > height ? accum.maxHeight : height,
          };
        },
        { maxWidth: 0, maxHeight: 0 } as {
          maxWidth: number;
          maxHeight: number;
        },
      );

    const IOXRaysOriginalWidth = originalSimpleImagesMaxSize.maxWidth;
    const IOXRaysOriginalHeight = originalSimpleImagesMaxSize.maxHeight;

    const IOXRAY_MATRIX_PADDINGS = 0;

    const scaleByWidth =
      (wrapperWidth - IOXRAY_MATRIX_PADDINGS) / IOXRaysOriginalWidth;
    const scaleByHeight =
      (wrapperHeight - IOXRAY_MATRIX_PADDINGS) / IOXRaysOriginalHeight;

    const IOXRaysOriginalAspect = IOXRaysOriginalHeight / IOXRaysOriginalWidth;

    const wrapperAspect = wrapperHeight / wrapperWidth;

    if (IOXRaysOriginalAspect >= wrapperAspect) {
      return scaleByHeight;
    }

    return scaleByWidth;
  };

  const scale = calculateScale();

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { width, height } = entry.contentRect;

        setContSize({ contWidth: width, contHeight: height });
      }
    });

    if (mainViewRef.current) {
      resizeObserver.observe(mainViewRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div className={cn(styles.container, className)}>
      {panoLocalizations?.map((localization) => {
        const { width, height } = getSizeFromBBox(localization.BBox);

        return (
          <div
            key={localization.TargetAssetID}
            className={styles.imageCrop}
            ref={Render.getViewportRef('pano', 'main')}
            style={{
              width: width * panoScale * scale,
              height: height * panoScale * scale,
            }}
          />
        );
      })}
      {isIOXRayReport &&
        simpleLocalizations?.map((localization) => {
          const { width, height } = getSizeFromBBox(localization.BBox);
          const imageItem = IOXRayImagesInterface.find(
            (item) => item.asset.ID === localization.TargetAssetID,
          );

          const imageMetaID =
            imageItem?.asset.Type === AssetType.AssetType_Study_PanoramicXRay
              ? 'pano'
              : imageItem?.imageMeta.ID || '';

          return (
            <div
              key={localization.TargetAssetID}
              className={styles.imageCrop}
              ref={Render.getViewportRef(imageMetaID, 'main')}
              style={{ width: width * scale, height: height * scale }}
            />
          );
        })}
      {bitewingsLocalizations?.map((localization) => {
        const { width, height } = getSizeFromBBox(localization.BBox);

        const imageItem = IOXRayImagesInterface.find(
          (item) => item.asset.ID === localization.TargetAssetID,
        );

        const imageMetaID = imageItem?.imageMeta.ID || '';

        return (
          <div
            key={localization.TargetAssetID}
            className={styles.imageCrop}
            ref={Render.getViewportRef(imageMetaID, 'main')}
            style={{ width: width * scale, height: height * scale }}
          />
        );
      })}
    </div>
  );
};
