import React, { useContext } from 'react';
import cn from 'classnames';

import { useAppSelector } from '@/shared/hooks';
import { ToothStatus } from '@/shared/config';

import { reportsModel } from '@/entities/reports';
import { organizationModel } from '@/entities/organization';
import { getDisplayToothNumber, toothModel } from '@/entities/tooth';

import { ToothItemStripes } from '../ToothItemStripes/ToothItemStripes';
import {
  ToothChartItemClickHandler,
  ToothChartSize,
  ToothItem,
} from '../../config/types';
import { ToothChartContext } from '../ToothChartRoot/ToothChartRoot';
import { ToothChartIcon } from '../ToothChartIcon/ToothChartIcon';

import styles from './ToothChartItem.module.scss';

const sizeStyles: Record<ToothChartSize, string> = {
  default: styles.sizeDefault,
  small: styles.sizeSmall,
};

const statusStyles: Record<ToothItem['status'], string> = {
  healthy: styles.statusHealthy,
  unhealthy: styles.statusUnhealthy,
  missing: styles.statusMissing,
  treated: styles.statusTreated,
  lowProbability: styles.statusLowProbability,
  notFound: styles.statusNotFound,
};

type ToothChartItemProps = ToothItem & {
  className?: string;
  blackAndWhite?: boolean;
  preview?: boolean;
  supernumeraryIndex?: number;
  onClick?: ToothChartItemClickHandler;
};

const InternalToothChartItem: React.ForwardRefRenderFunction<
  HTMLDivElement,
  ToothChartItemProps
> = (props, ref) => {
  const {
    className,
    id,
    status,
    isLowerJaw,
    ISONumber,
    active,
    blackAndWhite = false,
    preview = false,
    childItems = [],
    supernumeraryIndex,
    gridArea,
    onClick,
    ...rest
  } = props;

  const size = useContext(ToothChartContext);
  const activeToothID = useAppSelector(
    reportsModel.selectors.selectActiveToothID,
  );
  const customMode = useAppSelector(
    toothModel.selectors.selectToothChartCustomMode,
  );

  const dentalNotationFormat = useAppSelector(
    organizationModel.selectors.selectDentalNotationFormat,
  );

  const isSuper = childItems.length > 0;

  const focused =
    !preview &&
    (activeToothID === id ||
      childItems.some((item) => item.id === activeToothID));
  const isThirdMolar = ISONumber.toString().includes('8'); // It could be a bug. If the third molars are the only ones with a number that contains an 8

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (onClick && status !== ToothStatus.notFound) {
      event.stopPropagation();
      onClick({ id, active });
    }
  };

  return (
    <div
      ref={ref}
      style={{ gridArea: `t${gridArea}` }}
      className={cn(
        className,
        styles.container,
        sizeStyles[size],
        !customMode && statusStyles[status],
        !active && styles.inactive,
        !active && customMode && styles.customModeInactive,
        focused && styles.focused,
        isLowerJaw && styles.lowerJaw,
        isThirdMolar && styles.thirdMolar,
        blackAndWhite && styles.blackAndWhite,
      )}
      onClick={handleClick}
      {...rest}
    >
      <ToothItemStripes
        toothID={id}
        isLowerJaw={isLowerJaw}
        blackAndWhite={blackAndWhite}
      />

      {isSuper ? (
        <div
          className={cn(
            styles.doubleIconContainer,
            isLowerJaw && styles.lowerJaw,
          )}
        >
          <ToothChartIcon
            className={styles.doubleIcon}
            status={status}
            ISONumber={ISONumber}
            blackAndWhite={blackAndWhite}
          />

          <ToothChartIcon
            className={styles.doubleIcon}
            status={status}
            ISONumber={ISONumber}
            blackAndWhite={blackAndWhite}
          />
        </div>
      ) : (
        <ToothChartIcon
          status={status}
          ISONumber={ISONumber}
          blackAndWhite={blackAndWhite}
        />
      )}
      <span className={styles.number}>
        {getDisplayToothNumber(ISONumber, dentalNotationFormat)}

        {(isSuper || Boolean(supernumeraryIndex)) && '*'}
      </span>
    </div>
  );
};

const InternalToothChartItemWithRef = React.forwardRef<
  HTMLDivElement,
  ToothChartItemProps
>(InternalToothChartItem);

export const ToothChartItem = React.memo(InternalToothChartItemWithRef);
