import { FC, ReactNode, createRef, useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { CORS_POLICY } from '@/shared/config';
import { useAppSelector } from '@/shared/hooks';
import { FeatureFlag } from '@/shared/api/protocol_gen/model/dto_organization';

import { getImageSrc } from '@/entities/assets';
import { FMXNavigationMode, reportsModel } from '@/entities/reports';
import {
  IOXRayImagesInterfaceModel,
  IOXrayImageInterface,
  groupIOXRayImagesByPartition,
} from '@/entities/IOXRayImagesMatrix';
import { toothModel } from '@/entities/tooth';
import { maskFiltersModel as maskFilterTypesModel } from '@/entities/maskFilters'; // TODO:[4|m] Unite two mask filtel models
import { organizationModel } from '@/entities/organization';

import { maskFiltersModel } from '@/features/maskFilters';
import { CroppedImagesView } from '@/features/croppedImagesView';
import { hoveredConditionBBoxesModel } from '@/features/hoveredConditionBBoxes';
import { Landmark, useTeethLandmarks } from '@/features/toothLandmark';

import {
  ImageProps,
  PBLProps,
  PanoBitewingsRender,
} from '../../../../shared/graphics/viewer2D/Viewer2D';
import { PanowingMatrixView } from '../PanowingMatrixView/PanowingMatrixView';

import styles from './PanowingsReportRender.module.scss';

type PanowingsReportRenderProps = {
  className?: string;
  children?: ReactNode;
  previewMode?: boolean;
};

export const PanowingsReportRender: FC<PanowingsReportRenderProps> = (
  props,
) => {
  const { className, children, previewMode } = props;

  const [viewportRefsIsReady, setViewportRefsIsReady] = useState(false);

  const currentReport = useAppSelector(
    reportsModel.selectors.selectCurrentReport,
  );

  const reportID = currentReport?.ID as string;

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const mainViewRef = useRef<HTMLDivElement>(null);
  const panoViewportRef = useRef<HTMLDivElement>(null);

  const reportViewOptions = useAppSelector(
    reportsModel.selectors.selectCurrentReportViewOptions,
  );

  const reportReadyForRender = useAppSelector(
    reportsModel.selectors.selectReportReadyForRender,
  );
  const reportPBLReadyForRender = useAppSelector(
    reportsModel.selectors.selectReportPBLReadyForRender,
  );

  const IOXRayImagesInterface = useAppSelector(
    IOXRayImagesInterfaceModel.selectors.selectIOXRayImagesInterfaceByReportID(
      reportID || '',
    ),
  ) as IOXrayImageInterface[];

  const panoImageInterface = useAppSelector(
    IOXRayImagesInterfaceModel.selectors.selectPanoImageInterfaceByReportID(
      reportID,
    ),
  ) as IOXrayImageInterface;

  const groupedIOXRayImages = groupIOXRayImagesByPartition(
    IOXRayImagesInterface,
  );

  const bitewingsImagesInterface = [
    ...groupedIOXRayImages['MiddleLeft'],
    ...groupedIOXRayImages['MiddleRight'],
  ];

  const navigationMode = useAppSelector(
    reportsModel.selectors.selectNavigationMode,
  );

  // Hovered condition BBoxes
  const hoveredBBoxes = useAppSelector(
    hoveredConditionBBoxesModel.selectors.selectToothChartItems,
  );

  const teethIDs = useAppSelector(
    toothModel.selectors.selectReportROITeethIDs(reportID),
  );

  const { getPBLsForImage } = useTeethLandmarks(teethIDs);

  // Masks
  const masks2DRenderData = useAppSelector(
    maskFiltersModel.selectors.select2DMasksRenderData,
  );

  const hideMasks = useAppSelector(
    organizationModel.selectors.selectIsFeatureActiveByName(
      FeatureFlag.FeatureFlag_Hide_ConditionsMasks,
    ),
  );

  const activeToothID = useAppSelector(
    reportsModel.selectors.selectActiveToothID,
  );

  const showMatrix =
    navigationMode === FMXNavigationMode.MatrixView || !activeToothID;
  const showCroppedImages =
    navigationMode === FMXNavigationMode.ToothChart && activeToothID;
  const showPBL = useAppSelector(
    maskFilterTypesModel.selectors.selectIsLandmarksShown,
  );
  const hidePBLFeatureFlag = useAppSelector(
    organizationModel.selectors.selectIsFeatureActiveByName(
      FeatureFlag.FeatureFlag_Hide_PBLRulesAndMeasurements,
    ),
  );

  // Set refs for bitewings images
  // NOTE: This should be happen before PanoBitewingsRender.run(), set masks, PBL, etc
  useEffect(() => {
    let allRefsExist = false;

    bitewingsImagesInterface.forEach((bitewingItem) => {
      const isExistingRef = PanoBitewingsRender.getViewportRef(
        bitewingItem.imageMeta.ID,
        'main',
      );

      allRefsExist = !!isExistingRef;

      if (!isExistingRef) {
        PanoBitewingsRender.setViewportRef(
          createRef(),
          bitewingItem.imageMeta.ID,
          'main',
        );
      }
    });

    if (!viewportRefsIsReady && allRefsExist) {
      setViewportRefsIsReady(true);
    }
  }, [bitewingsImagesInterface]);

  useEffect(() => {
    PanoBitewingsRender.setViewRef(mainViewRef, 'main');
    PanoBitewingsRender.setCanvasRef(canvasRef);
    PanoBitewingsRender.setViewportRef(panoViewportRef, 'pano', 'main');
  }, []);

  // render initialization
  useEffect(() => {
    const shouldRunRender =
      !!bitewingsImagesInterface &&
      reportReadyForRender &&
      viewportRefsIsReady &&
      panoImageInterface?.imageMeta;

    if (shouldRunRender) {
      const isRenderStartCorrect = PanoBitewingsRender.run(
        reportID,
        previewMode,
      );

      if (!isRenderStartCorrect) {
        return;
      }

      PanoBitewingsRender.setCredentials(CORS_POLICY);

      const imagesData = bitewingsImagesInterface.map(
        ({ asset, imageMeta, originalSize }) => {
          return {
            id: imageMeta.ID,
            url: getImageSrc(asset?.ID, 'original'),
            previewURL: getImageSrc(asset?.ID, 'thumbnail'),
            width: originalSize.width,
            height: originalSize.height,
            kind: 'raster',
            sharpness:
              asset.GeneratedReport?.MedicalImageFeatures?.ViewOptions
                ?.Sharpness,
            brightness:
              asset.GeneratedReport?.MedicalImageFeatures?.ViewOptions
                ?.Brightness,
            contrast:
              asset.GeneratedReport?.MedicalImageFeatures?.ViewOptions
                ?.Contrast,
            angle: imageMeta.OrientationAngle * (Math.PI / 180),
          } as ImageProps;
        },
      );

      PanoBitewingsRender.setImages({
        bitewingProps: imagesData,
        panoProps: {
          id: panoImageInterface?.imageMeta.ID,
          url: getImageSrc(panoImageInterface.asset.ID, 'original'),
          kind: 'raster',
          sharpness: reportViewOptions?.Sharpness,
          brightness: reportViewOptions?.Brightness,
          contrast: reportViewOptions?.Contrast,
        },
      });
    }
  }, [
    bitewingsImagesInterface,
    panoImageInterface,
    reportReadyForRender,
    previewMode,
    viewportRefsIsReady,
  ]);

  // NOTE: PBL should be set up just one time after render is done.
  useEffect(() => {
    if (reportPBLReadyForRender && viewportRefsIsReady) {
      if (!previewMode) {
        const PBLsData = bitewingsImagesInterface
          .map((data) => {
            const initialPBLList = getPBLsForImage(data.asset.ID);

            if (initialPBLList.length === 0) {
              return undefined;
            }

            const getPBL = (landmark: Landmark) => ({
              start: {
                x: landmark.lowPoint?.ModelPosition?.X || 0,
                y: landmark.lowPoint?.ModelPosition?.Y || 0,
              },
              end: {
                x: landmark.upPoint?.ModelPosition?.X || 0,
                y: landmark.upPoint?.ModelPosition?.Y || 0,
              },
              color: landmark.color,
              textProps: {
                color: landmark.color === '#D4D4D4' ? 0 : 0xffffff,
              },
            });

            return {
              imageID: data.imageMeta.ID,
              PBLs: initialPBLList.map((pbl) => {
                return getPBL(pbl);
              }),
              scale: data.imageMeta.Scale?.X || 1,
            };
          })
          .filter((data) => data) as PBLProps[];

        if (PBLsData) {
          PanoBitewingsRender.setPBLs(PBLsData);
        }
      }
    }
  }, [reportPBLReadyForRender, viewportRefsIsReady]);

  // Toggle PBL
  // TODO: Is it possible to move it on perio toggle function to avoid useEffect?
  useEffect(() => {
    if (reportReadyForRender && PanoBitewingsRender.isRunning()) {
      if (!hideMasks && !hidePBLFeatureFlag && showPBL) {
        PanoBitewingsRender.showPBLs();
      } else {
        PanoBitewingsRender.hidePBLs();
      }
    }
  }, [reportReadyForRender, showPBL, hideMasks, hidePBLFeatureFlag]);

  // Render masks
  useEffect(() => {
    if (
      PanoBitewingsRender.isRunning() &&
      reportReadyForRender &&
      viewportRefsIsReady &&
      !previewMode
    ) {
      PanoBitewingsRender.deleteMasks();
      if (!hideMasks && masks2DRenderData.length > 0) {
        PanoBitewingsRender.addMasks(masks2DRenderData);
      }
    }
  }, [masks2DRenderData, hideMasks, viewportRefsIsReady]);

  // BBoxes render
  useEffect(() => {
    if (PanoBitewingsRender.isRunning()) {
      if (hoveredBBoxes) {
        PanoBitewingsRender.deleteConditionBoxes();

        PanoBitewingsRender.addConditionBoxes(hoveredBBoxes);
      } else {
        PanoBitewingsRender.deleteConditionBoxes();
      }
    }
  }, [hoveredBBoxes, reportReadyForRender]);

  useEffect(
    () => () => {
      if (PanoBitewingsRender.isRunning()) {
        PanoBitewingsRender.suspend();
      }
    },
    [],
  );

  return (
    <div className={cn(styles.container, className)}>
      <canvas
        ref={canvasRef}
        className={cn(styles.canvas, previewMode && styles.previewCanvas)}
      />
      <div
        className={cn(
          styles.mainViewWrapper,
          previewMode && styles.previewMainViewWrapper,
        )}
        ref={mainViewRef}
      >
        {showMatrix && <PanowingMatrixView previewMode={previewMode} />}
        {showCroppedImages && <CroppedImagesView mainViewRef={mainViewRef} />}
        {children}
      </div>
    </div>
  );
};
