import { useIntl } from 'react-intl';
import cn from 'classnames';
import { useMemo, useRef } from 'react';
import { first, isEmpty } from 'lodash';

import {
  Study,
  StudyStatus,
  StudyType,
} from '@/shared/api/protocol_gen/model/dto_study';
import { useAppSelector, useMedia } from '@/shared/hooks';
import { ReportType } from '@/shared/api/protocol_gen/model/dto_report';

import { getImageSrc } from '@/entities/assets';
import { i18n } from '@/entities/study';
import { reportsModel } from '@/entities/reports';

import { UploadingStatusFrame } from '../UploadingStatusFrame/UploadingStatusFrame';
import { studyCardTitle } from '../../config';

import styles from './StudyPreview.module.scss';

type StudyPreviewProps = {
  study: Study;
};

const ALLOWED_REPORT_TYPES = [
  ReportType.ReportType_CBCT_GP,
  ReportType.ReportType_IOXRay_GP,
  ReportType.ReportType_Pano_GP,
  ReportType.ReportType_Pano_Bitewings,
];

export const StudyPreview = (props: StudyPreviewProps) => {
  const { study } = props;

  const imagePreviewRef = useRef<HTMLDivElement>(null);

  const { formatMessage, formatDate } = useIntl();

  const { isPhone } = useMedia();

  const reports = useAppSelector(
    reportsModel.selectors.selectByStudyID(study.ID),
  );

  const firstStudyReport = first(
    study.Type === StudyType.StudyType_IntraoralXRay
      ? reports.filter(
          (report) => report.Type !== ReportType.ReportType_Pano_Bitewings,
        )
      : reports.filter((report) => ALLOWED_REPORT_TYPES.includes(report.Type)),
  );

  const previewImageSrc = getImageSrc(
    firstStudyReport?.DisplayAssetID || study.DisplayAssetID,
    'preview',
  );

  const studyType = study.Type;

  const showUploadingStatusFrame =
    study.Status !== StudyStatus.StudyStatus_Finished;

  const previewType = useMemo(() => {
    if (showUploadingStatusFrame) {
      return 'uploadingStatusFrame';
    }

    if (
      studyType === StudyType.StudyType_PanoramicXRay &&
      !isEmpty(firstStudyReport?.DataPanoBitewings)
    ) {
      return 'panowingsPreview';
    }

    if (studyType === StudyType.StudyType_IntraoralXRay) {
      return 'IOXRayPreview';
    }

    return 'simplePreview';
  }, [studyType, firstStudyReport, showUploadingStatusFrame]);

  const imgTextColorStyle = showUploadingStatusFrame
    ? styles.imgTextBlack
    : styles.imgTextWhite;

  const isIOXRayPreview = previewType === 'IOXRayPreview';

  return (
    <div
      ref={imagePreviewRef}
      className={cn(
        styles.imgContainer,
        isIOXRayPreview ? styles.imgContainerIOXRay : '',
        previewType === 'panowingsPreview' && styles.panowingsContainer,
      )}
    >
      {previewType === 'uploadingStatusFrame' && (
        <UploadingStatusFrame study={study} />
      )}

      {previewType !== 'uploadingStatusFrame' && (
        <img
          className={cn(
            styles.img,
            previewType === 'IOXRayPreview' && styles.fmx,
            previewType === 'panowingsPreview' && styles.panowings,
          )}
          src={previewImageSrc}
          alt={formatMessage(i18n.studyTypes[study.Type])}
        />
      )}

      {studyType === StudyType.StudyType_IntraoralXRay ? (
        <>
          <div className={styles.layer}>
            <div className={styles.info}>
              <h5
                className={cn(
                  styles.reportType,
                  isPhone ? 'h6' : 'h5',
                  imgTextColorStyle,
                )}
              >
                {formatMessage(studyCardTitle[studyType])}
              </h5>

              {study?.Created?.At && (
                <span
                  className={cn(
                    styles.date,
                    isPhone ? 'p4' : 'p3',
                    imgTextColorStyle,
                  )}
                >
                  {formatDate(study.Created.At, {
                    dateStyle: 'medium',
                  })}
                </span>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className={cn(styles.imgText)}>
          <h5 className={cn('h5', imgTextColorStyle)}>
            {formatMessage(studyCardTitle[studyType])}
          </h5>

          {study?.Created?.At && (
            <span className={cn('p3', imgTextColorStyle)}>
              {formatDate(study.Created.At, {
                dateStyle: 'medium',
              })}
            </span>
          )}
        </div>
      )}
    </div>
  );
};
