import { FC, useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { PanoBitewingsRender } from '@/shared/graphics/viewer2D/Viewer2D';

import { FMXNavigationMode, reportsModel } from '@/entities/reports';
import {
  IOXRayImagesInterfaceModel,
  IOXrayImageInterface,
  groupIOXRayImagesByPartition,
} from '@/entities/IOXRayImagesMatrix';

import styles from './PanowingMatrixView.module.scss';

type PanowingMatrixViewProps = {
  className?: string;
  previewMode?: boolean;
};

export const PanowingMatrixView: FC<PanowingMatrixViewProps> = (props) => {
  const { className, previewMode } = props;

  const dispatch = useAppDispatch();

  const bitewingsWrapperRef = useRef<HTMLDivElement>(null);

  const currentReport = useAppSelector(
    reportsModel.selectors.selectCurrentReport,
  );

  const reportID = currentReport?.ID as string;

  const IOXRayImagesInterface = useAppSelector(
    IOXRayImagesInterfaceModel.selectors.selectIOXRayImagesInterfaceByReportID(
      reportID,
    ),
  ) as IOXrayImageInterface[];

  const panoImageInterface = useAppSelector(
    IOXRayImagesInterfaceModel.selectors.selectPanoImageInterfaceByReportID(
      reportID,
    ),
  ) as IOXrayImageInterface;

  const groupedIOXRayImages = groupIOXRayImagesByPartition(
    IOXRayImagesInterface,
  );

  const bitewingsImagesInterface = [
    ...groupedIOXRayImages['MiddleLeft'],
    ...groupedIOXRayImages['MiddleRight'],
  ];

  const focusedImageMetaID = useAppSelector(
    reportsModel.selectors.selectFocusedImageMetaID,
  );

  const activeControl = useAppSelector(
    reportsModel.selectors.selectToolbarActiveControl,
  );

  const navigationMode = useAppSelector(
    reportsModel.selectors.selectNavigationMode,
  );

  const isImagesClickable =
    !previewMode &&
    activeControl === 'view' &&
    navigationMode === FMXNavigationMode.MatrixView &&
    !focusedImageMetaID;

  const onImageClickHandle = (assetID: string) => {
    if (isImagesClickable) {
      dispatch(reportsModel.actions.setFocusedMetaImageID(assetID));
    }
  };

  const [{ contHeight }, setContSize] = useState({
    contWidth: 0,
    contHeight: 0,
  });

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { width, height } = entry.contentRect;

        setContSize({ contWidth: width, contHeight: height });
      }
    });

    if (bitewingsWrapperRef.current) {
      resizeObserver.observe(bitewingsWrapperRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [focusedImageMetaID]); // Need to refresh bitewings container ref after focused view switched off

  return focusedImageMetaID ? (
    <div
      key={focusedImageMetaID}
      className={cn(styles.focusedImageViewport, className)}
      ref={
        focusedImageMetaID === panoImageInterface.imageMeta?.ID
          ? PanoBitewingsRender.getViewportRef('pano', 'main')
          : PanoBitewingsRender.getViewportRef(focusedImageMetaID, 'main')
      }
    />
  ) : (
    <>
      <div
        ref={PanoBitewingsRender.getViewportRef('pano', 'main')}
        className={cn(
          styles.pano,
          isImagesClickable ? styles.imageClickable : '',
        )}
        onClick={() => {
          onImageClickHandle(panoImageInterface?.imageMeta?.ID);
        }}
      />
      <div className={styles.bitewingsWrapper} ref={bitewingsWrapperRef}>
        {bitewingsImagesInterface?.map(({ imageMeta, originalSize }) => {
          const ref = PanoBitewingsRender.getViewportRef(imageMeta.ID, 'main');

          const isRotated = imageMeta.OrientationAngle > 0;

          return (
            <div
              onClick={() => {
                onImageClickHandle(imageMeta?.ID);
              }}
              key={imageMeta.ID}
              ref={ref}
              className={cn(
                styles.bitewingImage,
                isImagesClickable ? styles.imageClickable : '',
              )}
              style={{
                height: '100%',
                width: isRotated
                  ? (originalSize.height / originalSize.width) * contHeight
                  : (originalSize.width / originalSize.height) * contHeight,
              }}
            />
          );
        })}
      </div>
    </>
  );
};
