import { FC, useEffect, useLayoutEffect, useRef } from 'react';
import cn from 'classnames';

import { CORS_POLICY } from '@/shared/config';
import { useAppSelector } from '@/shared/hooks';
import { AxisSlice } from '@/shared/api/protocol_gen/model/dto_common_geometry';
import { FeatureFlag } from '@/shared/api/protocol_gen/model/dto_organization';

import { getImageSrc } from '@/entities/assets';
import { reportsModel } from '@/entities/reports';
import { toothModel } from '@/entities/tooth';
import { organizationModel } from '@/entities/organization';

import { MaskFilters, maskFiltersModel } from '@/features/maskFilters';
import { ConditionMaskTooltip } from '@/features/conditionMaskTooltip';
import { hoveredConditionBBoxesModel } from '@/features/hoveredConditionBBoxes';

import { PanoRender } from '../../../../shared/graphics/viewer2D/Viewer2D';
import { Toolbar } from '../Toolbar/Toolbar';
import { PanoImageSlider } from '../PanoImageSlider/PanoImageSlider';

import styles from './PanoReportRender.module.scss';

type PanoReportRenderProps = {
  className?: string;
  reportID: string;
};

export const PanoReportRender: FC<PanoReportRenderProps> = (props) => {
  const { className, reportID } = props;

  const mainViewRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const panoRef = useRef<HTMLDivElement>(null);

  const readyForRender = useAppSelector(
    reportsModel.selectors.selectReportReadyForRender,
  );
  const panoAssetID = useAppSelector(
    reportsModel.selectors.selectCurrentReportPanoAssetID,
  );
  const reportViewOptions = useAppSelector(
    reportsModel.selectors.selectCurrentReportViewOptions,
  );

  // Hovered condition BBoxes
  const hoveredBBoxes = useAppSelector(
    hoveredConditionBBoxesModel.selectors.selectToothChartItems,
  )?.map((bbox) =>
    bbox.imageID === panoAssetID ? { ...bbox, imageID: 'pano' } : bbox,
  );

  // Masks
  const masks2DRenderData = useAppSelector(
    maskFiltersModel.selectors.select2DMasksRenderData,
  );

  const hideMasks = useAppSelector(
    organizationModel.selectors.selectIsFeatureActiveByName(
      FeatureFlag.FeatureFlag_Hide_ConditionsMasks,
    ),
  );

  // Cropped by tooth
  const activeToothID = useAppSelector(
    reportsModel.selectors.selectActiveToothID,
  );
  const activeTooth = useAppSelector(
    toothModel.selectors.selectToothByID(activeToothID),
  );

  useLayoutEffect(() => {
    // PanoRender.addView('main'); Now main view exist by default
    PanoRender.setViewRef(mainViewRef);
    PanoRender.setCanvasRef(canvasRef);
    PanoRender.setViewportRef(panoRef);
  }, []);

  useEffect(() => {
    const shouldRunRender = !!reportID && !!panoAssetID && readyForRender;

    if (shouldRunRender) {
      const isRenderStartCorrect = PanoRender.run(reportID);
      if (!isRenderStartCorrect) {
        return;
      }

      PanoRender.setCredentials(CORS_POLICY);

      PanoRender.setPano({
        id: panoAssetID,
        url: getImageSrc(panoAssetID, 'original'),
        kind: 'raster',
        sharpness: reportViewOptions?.Sharpness,
        brightness: reportViewOptions?.Brightness,
        contrast: reportViewOptions?.Contrast,
      });
    }
  }, [reportID, panoAssetID, readyForRender]);

  // BBoxes render
  useEffect(() => {
    if (PanoRender.isRunning()) {
      if (hoveredBBoxes) {
        PanoRender.deleteConditionBoxes();

        PanoRender.addConditionBoxes(hoveredBBoxes);
      } else {
        PanoRender.deleteConditionBoxes();
      }
    }
  }, [hoveredBBoxes]);

  // Render masks
  useEffect(() => {
    if (PanoRender.isRunning() && readyForRender) {
      PanoRender.deleteMasks();
      if (!hideMasks && masks2DRenderData.length > 0) {
        PanoRender.addMasks({
          groupID: masks2DRenderData[0].groupID,
          config: masks2DRenderData[0].config,
        });
      }
    }
  }, [masks2DRenderData, readyForRender, hideMasks]);

  // apply tooth crop
  useEffect(() => {
    if (PanoRender.isRunning()) {
      const BBox = activeTooth?.Localizations[0].BBox;

      // set crop
      if (activeTooth && BBox) {
        const X = BBox.X as AxisSlice;
        const Y = BBox.Y as AxisSlice;

        PanoRender.setBox({
          min: { x: X?.Min, y: Y?.Min },
          max: { x: X?.Max, y: Y?.Max },
        });
      } else if (!activeTooth) {
        PanoRender.setBox(undefined);
      }
    }
  }, [activeTooth]);

  useEffect(
    () => () => {
      PanoRender.suspend();
    },
    [],
  );

  return (
    <div className={cn(styles.container, className)}>
      <MaskFilters className={styles.maskFilters} />
      <ConditionMaskTooltip />
      <Toolbar handleScrollToothCardsToTop={() => {}} />
      <PanoImageSlider reportID={reportID} />
      <canvas ref={canvasRef} className={styles.canvas} />
      <div className={styles.panoWrapper} ref={mainViewRef}>
        <div
          key={activeToothID || 'main'}
          ref={PanoRender.getViewportRef()}
          className={styles.pano}
        />
      </div>
    </div>
  );
};
